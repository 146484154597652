import { z } from 'zod';

export const caseSubjectTypeSchema = z.enum(['person', 'company', 'dataset']);

export type CaseSubjectType = z.infer<typeof caseSubjectTypeSchema>;

export const caseTypeSchema = z.object({
  ID: z.number(),
  name: z.string(),
  isSystem: z.boolean(),
  subjectType: caseSubjectTypeSchema,
  datasetID: z.number().nullable(),
});

export type CaseType = z.infer<typeof caseTypeSchema>;
