import { ReactElement } from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import { FieldValue } from '../types';

export const DefaultFieldComponent = ({
  id,
  settings,
  type,
}: FieldValue): ReactElement => {
  const { register, formState: { errors } } = useFormContext();
  const ourError = get(errors, id)?.type;

  return (
    <FormControl isInvalid={!!ourError}>
      <FormLabel fontWeight="bold">{settings.name}:</FormLabel>
      <Input type={type} {...register(id, { required: settings.required })} />
    </FormControl>
  );
};
