module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":58,"column":12}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form action=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"form_url")), depth0))
    + "\" method=\"post\" name=\"dataset-edit\" id=\"dataset-edit\">\n	<input type=\"hidden\" name=\"action\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"form_action")), depth0))
    + "\">\n	<div class=\"card\">\n		<div class=\"card-header stick-top\">\n			<span class=\"fa fa-pencil-alt\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias3,"label.edit",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":61}}}))
    + "\n		</div>\n		<div class=\"card-body\">\n			<div class=\"form-group row\">\n				<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"name\">\n					<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"label.name",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":12,"column":30},"end":{"line":12,"column":49}}}))
    + "</span><span class=\"label-after\">: </span>\n				</label>\n				<div class=\"col-12 col-md-9\">\n					<input name=\"name\" autocomplete=\"off\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"dataset") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" class=\"form-control\" type=\"text\">\n				</div>\n			</div>\n			<div class=\"form-group row\">\n				<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"displayNameTemplate\">\n					<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.display_name_template",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":20,"column":30},"end":{"line":20,"column":65}}}))
    + "</span><span class=\"label-after\">: </span>\n				</label>\n				<div class=\"col-12 col-md-9\">\n					<input name=\"displayNameTemplate\" autocomplete=\"off\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"dataset") : stack1)) != null ? lookupProperty(stack1,"displayNameTemplate") : stack1), depth0))
    + "\" class=\"form-control\" type=\"text\">\n				</div>\n				<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"displayNameTemplate\">\n					<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.available_variables",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":26,"column":30},"end":{"line":26,"column":63}}}))
    + "</span><span class=\"label-after\">: </span>\n				</label>\n				<div class=\"col-12 col-md-9 py-2\">\n					"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"result"))) && lookupProperty(stack1,"dataset"))) && lookupProperty(stack1,"metadata"))) && lookupProperty(stack1,"columns")),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":5},"end":{"line":29,"column":148}}})) != null ? stack1 : "")
    + "\n				</div>\n			</div>\n			<div class=\"dataset-form-editor-container\">\n				<div class=\"text-center mt-3\">\n					<div class=\"spinner-grow\"></div>\n				</div>\n			</div>\n		</div>\n		<div class=\"card-footer stick-bottom d-flex\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":39,"column":3},"end":{"line":47,"column":10}}})) != null ? stack1 : "")
    + "			<div class=\"save-button\">\n				<span class=\"save-status\" hidden style=\"padding: 7px 15px;\" data-form=\"dataset-edit\">\n				</span>\n				<button type=\"submit\" class=\"btn btn-success\" form=\"dataset-edit\">\n					<span class=\"fa fa-check\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":52,"column":44},"end":{"line":52,"column":65}}}))
    + "\n				</button>\n			</div>\n		</div>\n	</div>\n</form>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"badge badge-secondary\">{"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "}</span>"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":109},"end":{"line":29,"column":139}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return ", ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<a href=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"list_url")), depth0))
    + "\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n				<span class=\"fa fa-times\"></span>&nbsp;"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":41,"column":43},"end":{"line":41,"column":66}}}))
    + "\n			</a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<a href=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"list_url")), depth0))
    + "\" class=\"btn btn-link form-cancel mr-auto\">\n				<span class=\"fa fa-arrow-left\"></span>&nbsp;"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.back",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":45,"column":48},"end":{"line":45,"column":69}}}))
    + "\n			</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":59,"column":11}}})) != null ? stack1 : "");
},"useData":true})