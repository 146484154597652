<template>
	<div class="text-message-content" ref="textContent" v-html="processedText"></div>
</template>

<script>
import linkifyString from 'linkify-string';

export default {
  props: {
    text: String,
  },
  computed: {
    processedText() {
      return linkifyString(this.text);
    },
  },
};
</script>

<style scoped>
.text-message-content {
	white-space: pre-wrap;
}
</style>
