import { useMemo } from 'react';
import { FaBriefcase, FaSms, FaTrash } from 'react-icons/fa';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ActionGroup } from '@bq/components/Table/TableBulkActionMenu';
import {
  SimpleFormSalesCase,
  SimplePostSalesCase,
  useCasesBulkFormModal,
} from 'app/Modules/Sales/Assets';
import { createOneCase } from 'app/Modules/Sales/Assets/components/api/Case/createOneCase';
import { SubjectType } from 'app/Modules/Sales/Assets/components/Settings/CaseTypes/types';
import { BulkAction } from 'BootQuery/Assets/components/Table';
import { Api } from 'BootQuery/Assets/js/api';
import i18n from 'BootQuery/Assets/js/i18n';

import { PersonApiData } from '../types';
import { parseSmsSelection } from '../utils';

export const deleteItem = (refetch: () => void): BulkAction<PersonApiData> => ({
  name: i18n.t('global:delete'),
  icon: FaTrash({}),
  needsConfirm: true,
  isAllowed: (selection) => selection.length > 0,
  handler: async (selection) => {
    await Api.delete('/api/phonebook/people', {
      params: {
        filters: {
          'ID:inArray': selection.map((item) => item.ID),
        },
      },
    });
  },
  onActionEnd: () => {
    refetch();
  },
});

export interface SMSContact {
  ID: number;
  name: string;
  phoneNumber: string;
}
export const sendSms = (
  navigate: NavigateFunction
): BulkAction<PersonApiData, SMSContact> => ({
  name: i18n.t('global:bulk_actions:send_sms'),
  icon: FaSms({}),
  needsConfirm: false,
  isAllowed: (selection) => selection.length > 0,
  handler: async (selection) => {
    navigate('/sms/conversations/all/editor/on', {
      state: { action: selection },
    });
  },
  parseSelection(selection) {
    return parseSmsSelection(selection).map(({ phoneNumber, contact }) => ({
      phoneNumber,
      ID: contact.ID,
      name: `${contact.firstName ?? ''} ${contact.lastName ?? ''}`.trim(),
    }));
  },
  disabledTooltip: i18n.t('global:disabled_action_tooltips.no_sms_contact'),
  displayText: true,
});

const parseToPostData = (
  data: SimpleFormSalesCase,
  item: PersonApiData
): SimplePostSalesCase => ({
  name: `${data.name} ${item.firstName} ${item.lastName}`,
  interestLevelID: data.interestLevel.ID,
  stateID: data.state.ID,
  priorityID: data.priority.ID,
  tags: data.tags,
  assignedToUserID: data.assignedToUser?.ID ?? null,
  subject: { type: 'person', personID: item.ID, name: '' },
});

export const createCase = (
  callCreateCaseModal: (
    callback: (data: SimpleFormSalesCase) => void,
    subjectType?: SubjectType
  ) => void
): BulkAction<PersonApiData> => ({
  name: i18n.t('Phonebook:create_case'),
  icon: FaBriefcase({}),
  needsConfirm: false,
  handler: async (selection) => {
    callCreateCaseModal(async (data) => {
      const promiseArray = Promise.all(
        selection.map((item) => {
          return createOneCase(data.campaign.ID, parseToPostData(data, item));
        })
      );
      await promiseArray;
    }, 'person');
  },
  isAllowed: () => true,
  displayText: true,
  onActionEnd: () => {
    /** An empty callback to clearSelected */
  },
});

export const usePersonBulkActions = (
  refetch: () => void
): ActionGroup<PersonApiData>[] => {
  const isSmsEnabled = useMemo(
    () => 'Sms' in window.Bootstrap.modules,
    []
  );
  const isSalesEnabled = useMemo(
    () => 'Sales' in window.Bootstrap.modules,
    []
  );

  const navigate = useNavigate();
  const callCasesBulkForm = useCasesBulkFormModal();

  return useMemo(
    () => [
      {
        actions: [
          ...(isSmsEnabled ? [sendSms(navigate)] : []),
          ...(isSalesEnabled ? [createCase(callCasesBulkForm)] : []),
        ],
        styleProps: { colorScheme: 'brand' },
      },
      {
        actions: [deleteItem(refetch)],
        styleProps: {
          colorScheme: 'red',
          variant: 'outline',
          style: {
            marginLeft: 'auto',
          },
        },
      },
    ],
    [callCasesBulkForm, isSalesEnabled, isSmsEnabled, navigate, refetch]
  );
};
