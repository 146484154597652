import { ReactElement } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { FieldError, useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import { FieldValue } from '../../types';
import { TextFieldSettings } from './types';

export const TextField = ({
  id,
  settings,
}: FieldValue<TextFieldSettings>): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const ourError = get(errors, id) as FieldError | undefined;

  return (
    <FormControl isInvalid={!!ourError}>
      <FormLabel fontWeight="bold">{settings.name}:</FormLabel>
      <Input
        type="text"
        {...register(id, {
          required: settings.required,
          minLength: settings.minLength ?? undefined,
          maxLength: settings.maxLength ?? undefined,
        })}
      />
      {ourError && (
        <FormErrorMessage>{ourError.message || ourError.type}</FormErrorMessage>
      )}
    </FormControl>
  );
};
