<template>
    <textarea
        ref="textarea"
        :rows="rows"
        class="form-control"
        @keydown="onKey"
        v-model="content"
    ></textarea>
</template>

<script>
import autosize from 'autosize';

export default {
  props: {
    value: String,
    rows: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(content) {
        this.$emit('input', content);
      },
    },
  },
  methods: {
    onKey(ev) {
      // Submit on return, but let the newline be inserted on shift+return
      if (ev.keyCode === 13 && !ev.shiftKey) {
        ev.preventDefault();
        this.$emit('submit', this.content);
      }
    },
  },
  mounted() {
    autosize(this.$refs.textarea);
  },
  updated() {
    autosize.update(this.$refs.textarea);
  },
};
</script>

<style lang="scss" scoped>
textarea {
	max-height: 160px;
}
</style>
