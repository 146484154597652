import { v4 as uuid4 } from 'uuid';
import {
  merge,
  sortBy,
  findIndex,
  forEach,
  mapValues,
  difference,
  cloneDeep,
} from 'lodash-es';
import {
  getTemplate,
  getFormData,
} from 'BootQuery/Assets/js/BootQuery';
import tr from 'BootQuery/Assets/js/translate';
import { handlebarsRender } from 'app/assets/js/util';
import { Api } from 'BootQuery/Assets/js/api';
import Field from './field';

export default class Select extends Field {
  constructor(optionsOrType) {
    super(optionsOrType);
    if (!this.options.selects) {
      this.options.selects = {};
    }
    if (!this.options.order) {
      this.options.order = [];
    }

    this.fetchItems();
  }

  renderFieldSettings() {
    const el = $('<div>Loading...</div>');

    getTemplate('formEditorSelectSettings').then((template) => {
      const rendered = $.render(template, this.options);
      rendered.findElement('input, textarea, select')
        .ev('change.formBuilder', (e) => {
          const settings = merge({}, this.options, getFormData(rendered));
          this.emit('settingsChange', settings);
        });
      rendered.findElement('.edit-items-btn')
        .ev('click.formButton', (e) => {
          e.preventDefault();
          this.showListEditor($(e.currentTarget));
        });
      el.replaceWith(rendered);
    });

    return el;
  }

  async showListEditor(button) {
    let modal = $.render(await getTemplate('modal'), {});
    modal.find('.modal-header').text(tr('label.edit_items'));
    modal.find('.modal-dialog')
      .addClass('modal-lg')
      .attr('style', '');
    modal.find('.modal-body').addClass('p-0');

    const modalButtons = $.render(await getTemplate('formEditorModalButtons'), {});
    modalButtons.findElement('button[type=submit]')
      .ev('click.formBuilder', (e) => {
        e.preventDefault();

        this.applyItemChanges();
        modal.modal('hide');
      });
    modal.find('.modal-footer').append(modalButtons);
    modal.attr('id', 'select-list-edit-modal');

    const order = this.options.order || [];
    let selects = Object
      .entries(this.options.selects)
      .map(([key, select]) => ({ ...select, key }));
    if (order.length) {
      selects = sortBy(selects, (opt) => order.indexOf(opt.key));
    }
    selects = selects.map((select, idx) => {
      select.items = select.items.map((item) => {
        if (item.parentItemID && idx > 0) {
          const parent = selects[idx - 1].items.find(
            (parentItem) => parentItem.ID === item.parentItemID
          );
          item.parentKey = parent ? parent.key : null;
        } else {
          item.parentKey = item.parentKey || null;
        }

        return item;
      });

      return select;
    });

    this.orderedSelects = selects;
    this.editor = $.render(await getTemplate('formEditorSelectItems'), {
      selects,
    });
    if (selects.length) {
      this.focusSelectItem(selects[0].key);
    }
    this.bindSelectPane(this.editor);

    this.editor.find('.add-select-btn')
      .ev('click.formEditor', (e) => {
        e.preventDefault();
        this.addSelect();
      });

    modal.findElement('.modal-body').html(this.editor);
    modal = modal.appendTo('body');
    $('#select-list-edit-modal').modal('show');
    modal.on('hidden.bs.modal', (e) => {
      modal.modal('dispose');
      $('#select-list-edit-modal').remove();
    });
  }

  async fetchItems() {
    const promises = Object.entries(this.options.selects).reduce((fetches, [selectKey, select]) => {
      if (select.listID) {
        const req = Api.get(
          `/api/customLists/${select.listID}/items`,
          { params: { limit: 300 } }
        ).then(({ data }) => {
          this.options.selects[selectKey].items = data;
        });
        fetches.push(req);
      }
      return fetches;
    }, []);
    await Promise.all(promises);
  }

  async addSelect() {
    const newSelect = {
      key: uuid4(),
      label: 'New dropdown',
      new: true,
      items: [],
    };
    this.orderedSelects.push(newSelect);

    const template = await getTemplate('formEditorSelectItemsCol');
    const selectEl = $(handlebarsRender(template, newSelect));
    this.bindSelectPane(selectEl);
    this.editor.findElement('.card-group').append(selectEl);
  }

  bindSelectPane(el) {
    this.bindItemRow(el.findElement('.list-group-item'));

    el.findElement('.select-list-add-btn')
      .ev('click.formEditor', async (e) => {
        e.preventDefault();
        const selectName = $(e.currentTarget)
          .closest('[data-select]')
          .data('select');
        this.addItem(selectName);
      });
  }

  bindItemRow(itemRow) {
    itemRow.find('.select-list-delete-btn')
      .ev('click.formEditor', (e) => {
        e.preventDefault();
        e.stopPropagation();

        const itemName = $(e.currentTarget)
          .closest('.list-group-item')
          .data('selectItem');
        const selectName = $(e.currentTarget)
          .closest('[data-select]')
          .data('select');
        this.deleteItem(selectName, itemName);
      });

    itemRow.findElement('.list-group-item')
      .ev('click.formEditor', (e) => {
        e.preventDefault();
        const key = $(e.currentTarget).data('selectItem');
        const selectName = $(e.currentTarget)
          .closest('[data-select]')
          .data('select');
        this.focusSelectItem(selectName, key);
      });
  }

  deleteItem(selectName, itemName) {
    const select = this.selectColByName(selectName);
    const row = select.find(`.list-group-item[data-select-item="${itemName}"]`);

    if (row.find('input[name$="[new]"]').val() === 'true') {
      row.remove();
    } else {
      row.prop('hidden', true);
      row.removeClass('d-flex'); // It has "!important" and breaks hidden
      row.find('input[name$="[deleted]"]').val('true');
      row.attr('data-deleted', 'true');
    }
    this.focusSelectItem(selectName);
  }

  async addItem(selectName) {
    const select = this.selectColByName(selectName);
    let parentKey = null;
    const prevSelect = this.prevSelect(selectName);
    const nextSelect = this.nextSelect(selectName);
    if (prevSelect) {
      const prevSelectEl = this.selectColByName(prevSelect.key);
      parentKey = prevSelectEl.find('.list-group-item.active').data('selectItem');
    }

    const item = $.render(await getTemplate('formEditorSelectItem'), {
      id: uuid4(),
      parentKey,
      name: 'New item',
      isNew: true,
      noSubitems: !nextSelect,
    });
    this.bindItemRow(item);

    select.findElement('.list-group').append(item);
    this.focusSelectItem(selectName, item.id);
  }

  focusSelectItem(selectName, itemKey) {
    const select = this.selectColByName(selectName);

    let item = null;
    if (itemKey) {
      item = select.find(`.list-group-item[data-select-item="${itemKey}"]`);
    } else {
      item = select
        .find('.list-group-item[data-select-item]')
        .not('[hidden], [data-deleted="true"]')
        .first();
      itemKey = item.data('selectItem');
    }

    select.find('.list-group-item').removeClass('active');
    item.addClass('active');

    const nextSelect = this.nextSelect(selectName);
    if (nextSelect) {
      const nextSelectEl = this.selectColByName(nextSelect.key);
      const nextSelectItems = nextSelectEl.find('.list-group-item');

      nextSelectItems.removeClass('d-flex').prop('hidden', true);
      nextSelectItems
        .filter(`[data-parent-key="${itemKey}"]`)
        .not('[data-deleted="true"]')
        .addClass('d-flex')
        .prop('hidden', false);

      this.focusSelectItem(nextSelect.key);
    }
  }

  selectColByName(selectName) {
    return this.editor.findElement(`.card[data-select="${selectName}"]`);
  }

  nextSelect(currentSelectKey) {
    const currentIndex = findIndex(
      this.orderedSelects,
      (select) => select.key === currentSelectKey,
    );
    if (currentIndex !== -1 && this.orderedSelects.length > currentIndex + 1) {
      return this.orderedSelects[currentIndex + 1];
    }
    return null;
  }

  prevSelect(currentSelectKey) {
    const currentIndex = findIndex(
      this.orderedSelects,
      (select) => select.key === currentSelectKey,
    );
    if (currentIndex !== -1 && currentIndex > 0) {
      return this.orderedSelects[currentIndex - 1];
    }
    return null;
  }

  applyItemChanges() {
    const itemsArr = Array(this.orderedSelects.length).fill({});
    forEach(this.orderedSelects, (select, level) => {
      if (!this.options.selects[select.key]) {
        this.options.selects[select.key] = select;
        this.options.order.push(select.key);
      }

      const colEl = this.selectColByName(select.key);
      let newLabel = colEl.find('.card-header > .label-text').text();
      if (typeof newLabel === 'string') {
        newLabel = newLabel.trim();
      }
      if (newLabel) {
        this.options.selects[select.key].label = newLabel;
      }

      const colItems = mapValues(getFormData(colEl), (item) => {
        item.deleted = item.deleted === 'true';
        item.new = item.new === 'true';
        item.name = item.name.trim();
        return item;
      });
      itemsArr[level] = colItems;
    });

    this.orderedSelects.forEach((orderedSelect, idx) => {
      const select = this.options.selects[orderedSelect.key];
      const modifiedItems = itemsArr[idx];
      const currentItems = select.items;

      const existingKeys = Object.keys(modifiedItems);
      const currentKeys = currentItems.map((item) => item.key);

      const newKeys = difference(existingKeys, currentKeys);
      newKeys.forEach((newKey) => {
        const newItem = modifiedItems[newKey];
        currentItems.push({
          key: newKey,
          name: newItem.name,
          ID: `$new-${newKey}`,
          extraData: null,
          parentKey: newItem.parentKey,
          new: true,
          isNew: true,
        });
      });
      currentItems.forEach((item) => {
        const modifiedItem = modifiedItems[item.key];
        if (item.name !== modifiedItem.name || modifiedItem.deleted) {
          item.name = modifiedItem.name;
          item.deleted = modifiedItem.deleted;
          item.updated = true;
        }
      });
    });

    return this.options;
  }

  getDefinition() {
    const options = cloneDeep(this.options);
    options.selects = mapValues(options.selects, (select) => {
      // Already in the right form
      if (!select.items && select.itemChanges) {
        return select;
      }

      select.itemChanges = select.items
        .filter((item) => {
          if (item.deleted && item.new) {
            return false;
          }
          return true;
        })
        .reduce((changes, item) => {
          if (item.deleted) {
            changes.deleted.push({ ID: item.ID, key: item.key });
          }
          if (item.updated && !item.deleted) {
            changes.updated.push(item);
          }
          if (item.new && !item.deleted) {
            changes.new.push(item);
          }
          return changes;
        }, {
          new: [],
          updated: [],
          deleted: [],
        });
      delete select.items;
      return select;
    });

    return options;
  }
}
