import { ReactElement, useCallback } from 'react';
import { Box, FormControl, FormLabel, HStack } from '@chakra-ui/react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DeleteButton } from '@bq/components/DeleteButton';
import { NumberInputFull } from '@bq/components/NumberInputFull';
import { useTranslateSymbols } from 'app/Modules/Products/Assets/components/Settings/TranslateUnits/use-translate-symbols';

import { useAutoItemValues } from './hooks/use-auto-item-values';
import { ProductSelect } from './ProductSelect';
import { Product } from './types';

interface Props {
  idx: number;
  fieldName: string;
  onDelete: (idx: number) => void;
}

export const ProductItem = ({
  idx,
  fieldName,
  onDelete,
}: Props): ReactElement => {
  const { t } = useTranslation('Products');

  useAutoItemValues(fieldName);

  const unitSymbol = useUnitSymbol(fieldName);
  const unitStr = unitSymbol ? ` (${unitSymbol})` : '';

  const handleDelete = useCallback(() => {
    onDelete(idx);
  }, [onDelete, idx]);

  return (
    <HStack alignItems="end">
      <FormControl>
        <FormLabel>{t('Products:product')}:</FormLabel>
        <ProductField fieldName={fieldName} />
      </FormControl>
      <FormControl>
        <FormLabel>
          {t('Products:quantity')}
          {unitStr}:
        </FormLabel>
        <QuantityField fieldName={fieldName} />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Products:value')}:</FormLabel>
        <ValueField fieldName={fieldName} />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Products:gross_weight_kg')}:</FormLabel>
        <MassField fullFieldName={`${fieldName}.grossWeightKg`} />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Products:net_weight_kg')}:</FormLabel>
        <MassField fullFieldName={`${fieldName}.netWeightKg`} />
      </FormControl>
      <Box pl="4">
        <DeleteButton onClick={handleDelete} />
      </Box>
    </HStack>
  );
};

interface FieldProps {
  fieldName: string;
}

const ProductField = ({ fieldName }: FieldProps): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: `${fieldName}.product`,
    rules: { required: true },
  });

  return (
    <ProductSelect
      isInvalid={fieldState.invalid}
      value={value}
      onChange={onChange}
    />
  );
};

function useUnitSymbol(fieldName: string): string {
  const translate = useTranslateSymbols();
  const { control } = useFormContext();

  const product: Product = useWatch({
    control,
    name: `${fieldName}.product`,
  });

  if (!product?.defaultAmountUnit) {
    return '';
  }

  return translate(product?.defaultAmountUnit?.symbol);
}

const QuantityField = ({ fieldName }: FieldProps): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: `${fieldName}.quantity`,
    rules: { required: true },
  });

  return (
    <NumberInputFull
      isInvalid={fieldState.invalid}
      value={value}
      onChange={onChange}
      min={0}
      step={1}
    />
  );
};

const ValueField = ({ fieldName }: FieldProps): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: `${fieldName}.value`,
    rules: { required: true },
  });

  return (
    <NumberInputFull
      isInvalid={fieldState.invalid}
      value={value}
      onChange={onChange}
      min={0}
      precision={2}
      step={1}
    />
  );
};

interface MassFieldProps {
  fullFieldName: string;
}

const MassField = ({ fullFieldName }: MassFieldProps): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: fullFieldName,
  });

  return (
    <NumberInputFull
      isInvalid={fieldState.invalid}
      value={value}
      onChange={onChange}
      min={0}
      step={1}
    />
  );
};
