import { ReactElement } from 'react';
import {
  Flex, Link, Text, Tooltip,
} from '@chakra-ui/react';
import { formatNumber } from 'app/assets/js/tsutil';
import { FaExclamation, FaUsers } from 'react-icons/fa';
import { Conference } from '../../types/call';
import { useContactForNumber } from './hooks';
import { dialerStore } from './store/pbx';
import { CallDisplayProps } from './types';

export const RemotePartyDisplay = (
  callInfo: CallDisplayProps
): ReactElement => {
  const pbxCalls = dialerStore((store) => store.calls);

  let phoneNumber: string | null;
  let displayName: string | null;
  let conference: Conference | null = null;
  let error = null;

  if (callInfo.type === 'softphone') {
    phoneNumber = callInfo.call.phoneNumber;
    displayName = callInfo.call.displayName;
    const pbxCall = pbxCalls.find(
      (pbxCall) => pbxCall.call.sourcePhonePoint.phonePoint.sipCallId ===
        callInfo.call.sipCallId
    );
    if (pbxCall?.call.currentDestination?.destination.type === 'conference') {
      conference = pbxCall.call.currentDestination.destination.point;
    }
    error = callInfo.call.error;
  } else {
    const { connection, call } = callInfo.call;

    if (call.currentDestination?.destination.type === 'conference') {
      conference = call.currentDestination?.destination.point;
    }

    if (connection === 'destination' || connection === 'ringing' || !connection) {
      phoneNumber = call.sourceInfo.phoneNumber;
    } else {
      phoneNumber = call.destinationInfo.phoneNumber;
    }
    displayName = null;
  }

  const { contact } = useContactForNumber(phoneNumber);
  const numberDisplay = phoneNumber ? formatNumber(phoneNumber) : 'Unknown';

  if (error) {
    return (
      <Flex
        noOfLines={1}
        color="red.400"
        justifyContent="center"
        alignItems="center"
      >
        <FaExclamation />
        &nbsp;
        {error.reasonPhrase}
      </Flex>
    );
  }

  if (conference) {
    return (
      <Tooltip label="Conference call">
        <Flex noOfLines={1} justifyContent="center" alignItems="center">
          <FaUsers />
          &nbsp;
          {conference.name}
        </Flex>
      </Tooltip>
    );
  }

  if (contact) {
    const { icon, link, displayName } = contact;

    return (
      <Flex justifyContent="center">
        {icon && link && (
          <>
            <Link href={link}>
              <span className={icon}></span>
            </Link>
            &nbsp;
          </>
        )}
        <Tooltip hasArrow label={numberDisplay}>
          <Text as="span">{displayName}</Text>
        </Tooltip>
      </Flex>
    );
  }

  if (displayName) {
    return (
      <Tooltip hasArrow label={numberDisplay}>
        <Text as="span" noOfLines={1}>
          {displayName}
        </Text>
      </Tooltip>
    );
  }

  return (
    <Text as="span" noOfLines={1}>
      {phoneNumber ?? 'Unknown'}
    </Text>
  );
};
