import { useCallback, useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { z } from 'zod';

import { IconButton } from '@bq/components/IconButton';
import { Select, SelectOption } from '@bq/components/SelectWrappers';

import { getManyCampaign } from '../../api/Campaign/getManyCampaign';
import { Campaign, campaignSchema } from '../../schemas';
import { SubjectType } from '../../Settings/CaseTypes/types';
import { useCampaignModalForm } from '../Form/use-campaign-modal-form';

const campaignSchemaExtraData = campaignSchema.pick({
  caseType: true,
  createdAt: true,
  createdByUser: true,
});

type CampaignSelectOptionExtraData = z.infer<typeof campaignSchemaExtraData>;

type CampaignSelectOption = SelectOption<number, CampaignSelectOptionExtraData>;

interface CampaignSelectProps {
  value: Campaign;
  onChange: (campaign: Campaign | null) => void;
  isInvalid?: boolean;
  subjectType?: SubjectType;
}
export const CampaignSelect = ({
  onChange,
  value,
  isInvalid,
  subjectType,
}: CampaignSelectProps) => {
  const { t } = useTranslation('Sales');
  const { createCampaignModal } = useCampaignModalForm();
  const selectToCampaign = useCallback(
    (data: CampaignSelectOption | null): Campaign | null => {
      if (!data) {
        return null;
      }

      return {
        ID: data.value,
        name: data.label,
        caseType: data.caseType,
        createdAt: data.createdAt,
        createdByUser: data.createdByUser,
      };
    },
    []
  );
  const campaignToSelect = useCallback(
    (data: Campaign | null): CampaignSelectOption | null => {
      if (!data) {
        return null;
      }

      return {
        value: data.ID,
        label: data.name,
        caseType: data.caseType,
        createdAt: data.createdAt,
        createdByUser: data.createdByUser,
      };
    },
    []
  );

  const selectValue = useMemo(
    () => campaignToSelect(value),
    [campaignToSelect, value]
  );

  return (
    <HStack>
      <Box flex="1 1 auto">
        <Select<number, CampaignSelectOptionExtraData>
          isInvalid={isInvalid}
          options={async (search) => {
            const { data } = await getManyCampaign({
              filters: {
                $search: search,
                ...(subjectType ? { 'caseType.subjectType': subjectType } : {}),
              },
            });

            return data.map((item) => ({
              value: item.ID,
              label: item.name,
              caseType: item.caseType,
              createdAt: item.createdAt,
              createdByUser: item.createdByUser,
            }));
          }}
          value={selectValue}
          onChange={(val) => {
            onChange(selectToCampaign(val));
          }}
          size={{ sm: 'lg', md: 'md', lg: 'sm' }}
        />
      </Box>
      <IconButton
        label={t('Sales:campaign.add_campaign')}
        icon={<FaPlus />}
        onClick={() => {
          createCampaignModal((data) => {
            console.log(data);
            onChange(data);
          });
        }}
      />
    </HStack>
  );
};
