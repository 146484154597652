import { ReactElement } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  useColorModeValue,
} from '@chakra-ui/react';

import { ProductItems } from '@bq/components/FormFields/Product';

import { BaseSettings, FieldValue } from '../../types';

type Props = FieldValue<BaseSettings>;

export const ProductItemsField = ({ id, settings }: Props): ReactElement => {
  const bg = useColorModeValue('brand.lightGray', '#494949');

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{settings.name}:</FormLabel>
      <Box px="4" py="2" background={bg} rounded="md">
        <ProductItems fieldName={id} />
      </Box>
    </FormControl>
  );
};
