import { CellProps } from 'BootQuery/Assets/components/Table';
import { get } from 'lodash-es';
import { ReactElement } from 'react';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import { FieldValue } from '../../types';

type Props = CellProps;

export function makeCheckboxCell(field: FieldValue) {
  const CheckboxCell = ({ row }: Props): ReactElement => {
    const value = get(row, `data.${field.id}`) as boolean | undefined;

    return value ? (
      <FaRegCheckSquare style={svgStyle} />
    ) : (
      <FaRegSquare style={svgStyle} />
    );
  };
  CheckboxCell.displayName = `CheckboxCellCustom-${field.id}`;

  return CheckboxCell;
}

const svgStyle = { display: 'inline' };
