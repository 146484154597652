<template>
	<div class="card mb-3">
		<div class="card-body">
			<MailEditor
				ref="editor"
				v-bind="editorData"
				:disabled="isSaving"
				:showCancelSaveButtons="false"
				@update:canSubmit="canSubmit = $event"
			/>
		</div>
		<div class="card-footer mail-editor-footer">
			<button
				type="button"
				class="btn btn-success float-right"
				:disabled="!canSubmit || isSaving"
				@click.prevent="submit"
			>
				<span class="fa fa-paper-plane"></span>
				{{ tr('button.send') }}
			</button>
			<button
				type="button"
				class="btn btn-link text-danger float-right"
				:disabled="isSaving"
				@click.prevent="cancel"
			>{{ tr('button.cancel') }}</button>
		</div>
	</div>
</template>

<script>
import MailEditor from 'app/Modules/Mails/Assets/components/MailEditor.vue';

export default {
  components: {
    MailEditor,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      isSaving: false,
      canSubmit: false,
    };
  },
  methods: {
    submit() {
      const data = this.$refs.editor.getData();
      this.isSaving = true;
      this.module.submitMailEditor(this.editorID, data);
    },
    cancel() {
      this.module.destroyMailEditor(this.editorID);
    },
  },
  computed: {
    editorData() {
      return this.data.editorData;
    },
    editorID() {
      return this.data.editorID;
    },
    module() {
      return window.BootQuery.getModuleInstance('Campaigns');
    },
  },
};
</script>

<style lang="scss" scoped>
.mail-editor-footer {
	position: sticky;
	bottom: 0;
	background: var(--light);
	z-index: 50;
}
</style>
