module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":290,"column":12}}})) != null ? stack1 : "")
    + " ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"doNotCallWarning"),depth0,{"name":"doNotCallWarning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<form method=\"post\" action=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"form_url")), depth0))
    + "\" id=\"campaigns-contact-form\" data-validation-error=\"dataVerified\">\n	<input type=\"hidden\" name=\"action\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"form_action")), depth0))
    + "\">\n	<input type=\"hidden\" name=\"id\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"contactID") : stack1), depth0))
    + "\">\n	<input type=\"hidden\" name=\"campaignID\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"campaignID") : stack1), depth0))
    + "\">\n	<div class=\"card contact-card\">\n		<div class=\"card-header d-flex\">\n			<span class=\"mr-auto\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"is_campaign")),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "			</span>\n\n			<span class=\"pt-1\" id=\"campaigns-dialer-status\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"is_campaign")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "			</span>\n\n            <span class=\"ml-auto\">\n                <span class=\"save-status\" hidden style=\"padding: 7px 15px;\" data-form=\"campaigns-contact-form\">\n                </span>\n                <button class=\"btn btn-sm btn-success\" type=\"submit\" form=\"campaigns-contact-form\">\n                    <span class=\"fa fa-check\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":33,"column":59},"end":{"line":33,"column":80}}}))
    + "\n                </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"is_campaign")),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":49,"column":11}}})) != null ? stack1 : "")
    + "            </span>\n		</div>\n		<div class=\"card-block\">\n			<div class=\"row no-gutters\">\n				<div class=\"col-12 col-xl-3 bg-light py-2 border-right contact-first-pane\">\n					<div class=\"col-12 text-nowrap text-truncate\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"result"))) && lookupProperty(stack1,"campaignName")), depth0))
    + "\">\n						<strong>"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.campaign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":56,"column":14},"end":{"line":56,"column":36}}}))
    + ":</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"result"))) && lookupProperty(stack1,"campaignName")), depth0))
    + "\n					</div>\n					<hr class=\"mt-2 mb-0\">\n					<div data-form=\"basic\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"basicFormEditable")),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":28},"end":{"line":59,"column":112}}})) != null ? stack1 : "")
    + ">\n						<div class=\"campaign-basic-readonly\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"basicFormEditable")),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":42},"end":{"line":60,"column":97}}})) != null ? stack1 : "")
    + ">\n							"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"forms"))) && lookupProperty(stack1,"basic")),{"name":"form","hash":{"plain":true,"readonly":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "							<hr>\n							<div class=\"col-8 mx-auto text-center\">\n								<button class=\"btn btn-primary btn-sm edit-basic-data-btn\">\n									<span class=\"fas fa-edit\"></span>\n									"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"button.edit_basic_data",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":66,"column":9},"end":{"line":66,"column":40}}}))
    + "\n								</button>\n							</div>\n						</div>\n						<div class=\"campaign-basic-editable\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"basicFormEditable")),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":42},"end":{"line":70,"column":105}}})) != null ? stack1 : "")
    + ">\n							"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"forms"))) && lookupProperty(stack1,"basic")),{"name":"form","hash":{"plain":false,"readonly":false},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"basicFormEditable")),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":7},"end":{"line":80,"column":18}}})) != null ? stack1 : "")
    + "						</div>\n					</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"is_campaign")),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":5},"end":{"line":97,"column":12}}})) != null ? stack1 : "")
    + "					<div class=\"form-group col-12 mt-3\">\n						<div class=\"row\">\n							<label class=\"col-auto\" for=\"callAgain\">Ponovno zvati: </label>\n							<div class=\"col\">\n								<div class=\"custom-control custom-checkbox\">\n									<input type=\"hidden\" name=\"callAgain\" value=\"false\">\n									<input type=\"checkbox\" class=\"custom-control-input\" id=\"callAgain\" name=\"callAgain\" value=\"true\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"callAgainAt") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":106},"end":{"line":104,"column":146}}})) != null ? stack1 : "")
    + ">\n									<label class=\"custom-control-label\" for=\"callAgain\"></label>\n								</div>\n							</div>\n						</div>\n						<div id=\"callAgainAtContainer\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"callAgainAt") : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":37},"end":{"line":109,"column":84}}})) != null ? stack1 : "")
    + ">\n							<label for=\"callAgainAt\">Vrijeme ponovnog poziva: </label>\n							<input type=\"text\" id=\"callAgainAt\" name=\"callAgainAt\"class=\"form-control form-control-sm datetimepicker\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"callAgainAt") : stack1), depth0))
    + "\">\n						</div>\n					</div>\n				</div>\n				<div class=\"col-12 col-xl-9 contact-second-pane\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"allowedToCall") : stack1),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":116,"column":5},"end":{"line":284,"column":16}}})) != null ? stack1 : "")
    + "                </div>\n			</div>\n		</div>\n	</div>\n</form>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<a href=\"/campaigns/logout/?campaign="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"campaignID") : stack1), depth0))
    + "&agent="
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"userID")), depth0))
    + "\" class=\"btn btn-sm btn-danger\">\n					<span class=\"fa fa-sign-out-alt\"></span>\n					"
    + alias2(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.logout",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":13,"column":5},"end":{"line":13,"column":27}}}))
    + "\n				</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<span class=\"fas fa-phone\"></span>\n					"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,"label.call_contact",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":21,"column":5},"end":{"line":21,"column":32}}}))
    + "\n					<strong class=\"text-warning\" id=\"pause-notice\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"pause") : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":52},"end":{"line":22,"column":93}}})) != null ? stack1 : "")
    + ">Pauza</strong>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<span class=\"fas fa-edit\"></span>\n					"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.edit_contact",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":25,"column":5},"end":{"line":25,"column":32}}}))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<button type=\"button\" class=\"btn btn-yellow btn-sm"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"state_active") : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":55},"end":{"line":36,"column":104}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"pause") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":106},"end":{"line":36,"column":141}}})) != null ? stack1 : "")
    + " id=\"campaign-pause\">\n						<span class=\"fa fa-pause\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.pause",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":37,"column":40},"end":{"line":37,"column":61}}}))
    + "\n					</button>\n					<button data-campaign=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"campaignID") : stack1), depth0))
    + "\" data-agent=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"userID")), depth0))
    + "\" class=\"btn btn-sm btn-warning\" type=\"button\" id=\"campaign-skip-contact\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"shouldCall") : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":176},"end":{"line":39,"column":227}}})) != null ? stack1 : "")
    + ">\n						<span class=\"fa fa-forward\"></span>\n						"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.skip",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":41,"column":6},"end":{"line":41,"column":26}}}))
    + "\n					</button>\n					<button data-campaign=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"campaignID") : stack1), depth0))
    + "\" data-agent=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"userID")), depth0))
    + "\" class=\"btn btn-sm btn-primary\" id=\"campaign-next-contact\" type=\"button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"shouldCall") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":176},"end":{"line":43,"column":219}}})) != null ? stack1 : "")
    + ">\n						<span class=\"fa fa-arrow-right\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.next_contact",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":44,"column":51},"end":{"line":44,"column":80}}}))
    + "\n					</button>\n					<button data-campaign=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"campaignID") : stack1), depth0))
    + "\" data-agent=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"userID")), depth0))
    + "\" data-contact=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"contactID") : stack1), depth0))
    + "\" class=\"btn btn-sm btn-primary\" id=\"campaign-make-call\" type=\"button\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"shouldCall") : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":214},"end":{"line":46,"column":265}}})) != null ? stack1 : "")
    + ">\n						<span class=\"fa fa-phone\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.make_call",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":47,"column":45},"end":{"line":47,"column":71}}}))
    + "\n					</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"13":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"15":function(container,depth0,helpers,partials,data) {
    return "class=\"campaign-basic-form-editable\"";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<hr>\n							<div class=\"col-8 mx-auto text-center\">\n								<button class=\"btn btn-danger btn-sm cancel-edit-basic-data-btn\">\n									<span class=\"fas fa-times\"></span>\n									"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":77,"column":9},"end":{"line":77,"column":31}}}))
    + "\n								</button>\n							</div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"col-12 mt-3 current-agent-switch\">\n						<strong>"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,"form.agent",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":85,"column":14},"end":{"line":85,"column":33}}}))
    + ": </strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"agent") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":86,"column":6},"end":{"line":90,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"isManager") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":6},"end":{"line":95,"column":13}}})) != null ? stack1 : "")
    + "					</div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"agent") : stack1)) != null ? lookupProperty(stack1,"agentName") : stack1), depth0))
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "							-\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						&nbsp;<a href=\"/campaigns/changeContactAgent?contactID="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"contactID") : stack1), depth0))
    + "\" class=\"campaigns-change-contact-agent-button noparse\">\n							<span class=\"fas fa-edit\"></span>\n						</a>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "checked";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"row px-3 py-3\">\n							<div class=\"col\">\n								"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"doNotCallContact"),depth0,{"name":"doNotCallContact","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "							</div>\n						</div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<ul class=\"nav nav-tabs\">\n							<li class=\"nav-item\">\n								<a class=\"nav-link active\" data-toggle=\"tab\" href=\"#scenario\">\n									<span class=\"tab-icon\">\n										<span class=\"fa fa-file-alt\"></span>\n									</span>\n									<span class=\"tab-text\">\n										"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,"tab.scenario",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":130,"column":10},"end":{"line":130,"column":31}}}))
    + "\n									</span>\n								</a>\n							</li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"is_campaign")),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":7},"end":{"line":186,"column":14}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"tables") : depth0)) != null ? lookupProperty(stack1,"contactCampaigns") : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":188,"column":7},"end":{"line":199,"column":14}}})) != null ? stack1 : "")
    + "						</ul>\n						<div class=\"tab-content\">\n							<div class=\"tab-pane fade show active\" id=\"scenario\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"pendingAppointment") : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":203,"column":8},"end":{"line":207,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"forms"))) && lookupProperty(stack1,"scenario"))) && lookupProperty(stack1,"fields")),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":208,"column":8},"end":{"line":216,"column":15}}})) != null ? stack1 : "")
    + "							</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"is_campaign")),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":218,"column":7},"end":{"line":267,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"tables") : depth0)) != null ? lookupProperty(stack1,"contactCampaigns") : stack1),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":268,"column":7},"end":{"line":282,"column":14}}})) != null ? stack1 : "")
    + "						</div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<li class=\"nav-item\">\n								<a class=\"nav-link\" data-toggle=\"tab\" href=\"#sales\">\n									<span class=\"tab-icon\">\n										<span class=\"fas fa-shopping-cart\"></span>\n									</span>\n									<span class=\"tab-text\">\n										"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"tab.sales",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":141,"column":10},"end":{"line":141,"column":28}}}))
    + "\n										<span class=\"badge badge-secondary\">\n											"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tables") : depth0)) != null ? lookupProperty(stack1,"sales") : stack1)) != null ? lookupProperty(stack1,"resultdata") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "\n										</span>\n									</span>\n								</a>\n							</li>\n							<li class=\"nav-item\">\n								<a class=\"nav-link\" data-toggle=\"tab\" href=\"#appointments\">\n									<span class=\"tab-icon\">\n										<span class=\"fas fa-business-time\"></span>\n									</span>\n									<span class=\"tab-text\">\n										"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"tab.appointments",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":154,"column":10},"end":{"line":154,"column":35}}}))
    + "\n										<span class=\"badge badge-secondary\">\n											"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tables") : depth0)) != null ? lookupProperty(stack1,"appointments") : stack1)) != null ? lookupProperty(stack1,"resultdata") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "\n										</span>\n									</span>\n								</a>\n							</li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"showEvents")),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":7},"end":{"line":175,"column":14}}})) != null ? stack1 : "")
    + "							<li class=\"nav-item\">\n								<a class=\"nav-link\" data-toggle=\"tab\" href=\"#reminders\">\n									<span class=\"tab-icon\">\n										<span class=\"fas fa-bell\"></span>\n									</span>\n									<span class=\"tab-text\">\n										"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"tab.reminders",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":182,"column":10},"end":{"line":182,"column":32}}}))
    + " <span class=\"badge badge-secondary\">"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tables") : depth0)) != null ? lookupProperty(stack1,"reminders") : stack1)) != null ? lookupProperty(stack1,"resultdata") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</span>\n									</span>\n								</a>\n							</li>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<li class=\"nav-item\">\n								<a class=\"nav-link\" data-toggle=\"tab\" href=\"#campaign-contact-events\">\n									<span class=\"tab-icon\">\n										<span class=\"fas fa-list\"></span>\n									</span>\n									<span class=\"tab-text\">\n										"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"tab.events",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":168,"column":10},"end":{"line":168,"column":29}}}))
    + "\n										<span class=\"badge badge-secondary campaign-contact-events-count\">\n											<span class=\"fas fa-spinner fa-spin\"></span>\n										</span>\n									</span>\n								</a>\n							</li>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<li class=\"nav-item\">\n								<a class=\"nav-link\" data-toggle=\"tab\" href=\"#contactCampaigns\">\n									<span class=\"tab-icon\">\n										<span class=\"fas fa-bell\"></span>\n									</span>\n									<span class=\"tab-text\">\n										"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"tab.contact_campaigns",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":195,"column":10},"end":{"line":195,"column":40}}}))
    + " <span class=\"badge badge-secondary\">"
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tables") : depth0)) != null ? lookupProperty(stack1,"contactCampaigns") : stack1)) != null ? lookupProperty(stack1,"resultdata") : stack1)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "</span>\n									</span>\n								</a>\n							</li>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<div class=\"p-3\">\n										"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"pendingAppointment"),depth0,{"name":"pendingAppointment","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "									</div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<div data-form=\"scenario\" class=\"py-3\">\n										"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"forms"))) && lookupProperty(stack1,"scenario")),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "									</div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<div class=\"py-3 px-3\">\n										<strong>"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_scenario_fields",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":214,"column":18},"end":{"line":214,"column":51}}}))
    + "</strong>\n									</div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<div class=\"tab-pane\" id=\"sales\">\n								<div class=\"card m-3\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,lookupProperty(helpers,"lookup").call(alias1,(depth0 != null ? lookupProperty(depth0,"tables") : depth0),"sales",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":221,"column":17},"end":{"line":221,"column":40}}}),{"name":"with","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":221,"column":9},"end":{"line":229,"column":18}}})) != null ? stack1 : "")
    + "								</div>\n							</div>\n							<div class=\"tab-pane\" id=\"appointments\">\n								<div class=\"card m-3\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,lookupProperty(helpers,"lookup").call(alias1,(depth0 != null ? lookupProperty(depth0,"tables") : depth0),"appointments",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":234,"column":17},"end":{"line":234,"column":47}}}),{"name":"with","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":234,"column":9},"end":{"line":242,"column":18}}})) != null ? stack1 : "")
    + "								</div>\n							</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"showEvents")),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":245,"column":7},"end":{"line":253,"column":14}}})) != null ? stack1 : "")
    + "							<div class=\"tab-pane\" id=\"reminders\">\n								<div class=\"card m-3\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,lookupProperty(helpers,"lookup").call(alias1,(depth0 != null ? lookupProperty(depth0,"tables") : depth0),"reminders",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":256,"column":17},"end":{"line":256,"column":44}}}),{"name":"with","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":256,"column":9},"end":{"line":264,"column":18}}})) != null ? stack1 : "")
    + "								</div>\n							</div>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<div class=\"card-body\">\n											"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"sectionHead"),depth0,{"name":"sectionHead","hash":{"targetSelector":"#sales"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										</div>\n										"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"table"),depth0,{"name":"table","hash":{"targetSelector":"#sales","small":true,"noBottomMargin":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										<div class=\"card-footer\">\n											"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"pagination"),depth0,{"name":"pagination","hash":{"targetSelector":"#sales"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										</div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<div class=\"card-body\">\n											"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"sectionHead"),depth0,{"name":"sectionHead","hash":{"targetSelector":"#appointments"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										</div>\n										"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"table"),depth0,{"name":"table","hash":{"targetSelector":"#appointments","small":true,"noBottomMargin":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										<div class=\"card-footer\">\n											"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"pagination"),depth0,{"name":"pagination","hash":{"targetSelector":"#appointments"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										</div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    return "							<div class=\"tab-pane px-3 py-3\" id=\"campaign-contact-events\">\n								<div class=\"events-container\">\n									<div class=\"d-flex justify-content-center\">\n										<span class=\"spinner-grow spinner-grow-lg\"></span>\n									</div>\n								</div>\n							</div>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<div class=\"card-body\">\n											"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"sectionHead"),depth0,{"name":"sectionHead","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										</div>\n										"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"table"),depth0,{"name":"table","hash":{"small":true,"noBottomMargin":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										<div class=\"card-footer\">\n											"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"pagination"),depth0,{"name":"pagination","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										</div>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<div class=\"tab-pane\" id=\"contactCampaigns\">\n								<div class=\"card m-3\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,lookupProperty(helpers,"lookup").call(alias1,(depth0 != null ? lookupProperty(depth0,"tables") : depth0),"contactCampaigns",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":271,"column":17},"end":{"line":271,"column":51}}}),{"name":"with","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":271,"column":9},"end":{"line":279,"column":18}}})) != null ? stack1 : "")
    + "								</div>\n							</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":290,"column":24}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true})