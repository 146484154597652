import { useQuery } from '@tanstack/react-query';
import { getNumberTypes } from './api';
import { NumberType } from './types';

export function useNumberTypes(): NumberType[] | null {
  const { data: numberTypes } = useQuery(
    ['phonebookNumberTypes'],
    getNumberTypes
  );

  return numberTypes ?? null;
}
