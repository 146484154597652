module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<span class=\"fa fa-plus\"></span>\n					"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.add_sale_form",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":8,"column":5},"end":{"line":8,"column":33}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<span class=\"fa fa-edit\"></span>\n					"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.edit_sale_form",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":11,"column":5},"end":{"line":11,"column":34}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n	<div class=\"modal-dialog modal-lg\" role=\"document\" style=\"max-width: none; width: 90%;\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<h5 class=\"modal-title\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNew") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":5},"end":{"line":12,"column":12}}})) != null ? stack1 : "")
    + "				</h5>\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n					<span aria-hidden=\"true\">&times;</span>\n				</button>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"form-group row\">\n					<label class=\"col-12 col-form-label col-md-1 col-form-label control-label text-left text-md-right\" for=\"sale-form-name\">\n						"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.name",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":21,"column":25}}}))
    + ":\n					</label>\n					<div class=\"col-12 col-md-11\">\n						<input name=\"name\" id=\"sale-form-name\" value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" class=\"form-control\" type=\"text\" required>\n					</div>\n				</div>\n                <div id=\"sale-form-editor\"></div>\n			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-link text-red\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.cancel",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":30,"column":77},"end":{"line":30,"column":99}}}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-success save-btn\">\n					<span class=\"fa fa-check\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":32,"column":39},"end":{"line":32,"column":59}}}))
    + "\n				</button>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true})