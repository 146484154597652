module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(alias1,"icon",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"content").call(alias1,"title",{"name":"content","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":7,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"content").call(alias1,"body",{"name":"content","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":12,"column":16}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"fas fa-business-time\"></span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"Campaigns:notifications.appointment_reminder",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":61}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <strong>\n			"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,"Campaigns:notifications.appointment_at_x",{"name":"tr","hash":{"formattedTime":lookupProperty(helpers,"format_date").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"appointmentDate") : stack1),lookupProperty(helpers,"tr").call(alias1,"format.time",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":10,"column":99},"end":{"line":10,"column":117}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":10,"column":65},"end":{"line":10,"column":118}}})},"data":data,"loc":{"start":{"line":10,"column":3},"end":{"line":10,"column":120}}}))
    + "\n		</strong>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"Notifications.notificationLayout",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":11}}})) != null ? stack1 : "");
},"useData":true})