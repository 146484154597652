export default class FieldFactory {
  constructor(availableFields) {
    this.availableFields = availableFields;
  }

  constructField(optionsOrType) {
    let options = optionsOrType;
    if (typeof optionsOrType === 'string') {
      options = {
        type: optionsOrType,
        new: true,
      };
    }

    const TypeConstructor = this.availableFields[options.type] || this.availableFields.field;

    return new TypeConstructor(options, this);
  }
}
