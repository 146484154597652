import { useMemo } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { BaseSettings, FieldValue } from '@bq/components/FormEditor';
import { useFormDefinitionMultiple } from '@bq/components/FormEditor/use-form-definition-multi';
import { IOverviewEditorItem } from '@bq/components/Overviews';
import { Api } from 'BootQuery/Assets/js/api';

import { getReportMethods, getTicketPriorities, getTicketStates } from '../Api';
import { getAutoselectCompanyAsClient } from '../Api/autoselect-company-as-client';
import { useOverviews } from '../Api/overviews';
import {
  TicketGroupFull,
  TicketingPermissions,
  TicketPriority,
  TicketReportMethod,
  TicketState,
  TicketType,
} from '../types';

interface UseLoadTicketingResult {
  permissions: UseQueryResult<TicketingPermissions>;
  types: UseQueryResult<TicketType[]>;
  groups: UseQueryResult<TicketGroupFull[]>;
  ownGroupIds: UseQueryResult<number[]>;
  priorities: UseQueryResult<TicketPriority[]>;
  reportMethods: UseQueryResult<TicketReportMethod[]>;
  autoSelectCompanyAsClient: UseQueryResult<boolean>;
  states: UseQueryResult<TicketState[]>;
  overviews: UseQueryResult<IOverviewEditorItem[]>;
  customFields: FieldValue<BaseSettings>[] | null;
  customFormDefinitions: {
    [name: string]: FieldValue<BaseSettings>[];
  } | null;
}

export const useLoadTicketing = (): UseLoadTicketingResult => {
  const permissions = useQuery(
    ['ticketingPermissions'],
    async () => {
      const { data } = await Api.get<TicketingPermissions>(
        '/api/ticketing/permissions'
      );

      return data;
    },
    { refetchOnMount: false }
  );
  const types = useQuery(
    ['ticketingTypes'],
    async () => {
      const { data } = await Api.get<TicketType[]>('/api/ticketing/types', {
        params: {
          fields: [
            'ID',
            'name',
            'slug',
            'defaultTab',
            'defaultTicketGroupID',
            ['states.state', ['ID', 'name', 'color']],
            'canHaveDependencies',
            'canHaveWorks',
          ],
        },
      });

      return data;
    },
    { refetchOnMount: false }
  );
  const groups = useQuery(
    ['ticketingGroups'],
    async () => {
      const { data } = await Api.get<TicketGroupFull[]>(
        '/api/ticketing/groups',
        {
          params: { sort: 'ID.asc' },
        }
      );

      return data;
    },
    { refetchOnMount: false }
  );
  const ownGroupIds = useQuery(
    ['ticketingOwnGroups'],
    async () => {
      const { data } = await Api.get<number[]>('/api/ticketing/ownGroups');

      return data;
    },
    { refetchOnMount: false }
  );
  const priorities = useQuery(
    ['ticketPriorities'],
    async () => {
      const data = await getTicketPriorities({ sort: 'ID.asc' });

      return data;
    },
    { refetchOnMount: false }
  );
  const reportMethods = useQuery(
    ['reportMethods'],
    async () => {
      const data = await getReportMethods({ sort: 'ID.asc' });

      return data;
    },
    { refetchOnMount: false }
  );
  const autoSelectCompanyAsClient = useQuery(
    ['autoSelectCompanyAsClient'],
    async () => {
      const data = await getAutoselectCompanyAsClient();

      return data;
    },
    { refetchOnMount: false }
  );

  const states = useQuery(
    ['ticketStates'],
    async () => {
      const data = await getTicketStates({ limit: 'null', sort: 'ID:asc' });

      return data;
    },
    { refetchOnMount: false }
  );

  const customFormDefinitions = useFormDefinitionMultiple(
    types.data?.map((item) => `TicketTypes.${item.ID}`) ?? [],
    'use',
    { enabled: !!types.data }
  );

  const allCustomFields = useMemo(() => {
    return customFormDefinitions
      ? Object.values(customFormDefinitions).flat()
      : null;
  }, [customFormDefinitions]);

  const overviews = useOverviews(allCustomFields);

  return {
    permissions,
    types,
    groups,
    priorities,
    reportMethods,
    autoSelectCompanyAsClient,
    ownGroupIds,
    states,
    overviews,
    customFields: allCustomFields,
    customFormDefinitions,
  };
};
