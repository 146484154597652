<template>
    <div class="bg-light border-top card-block">
        <div class="py-1 px-2">
            <small class="text-muted">
                {{ tr('Mails:label.attachments') }}
            </small>
        </div>
        <div class="py-1 px-2" v-if="visibleAttachments.length">
            <div class="row">
                <div
                    class="col-auto attachment-wrapper"
                    v-for="(attachment, index) in visibleAttachments"
                    :key="index"
                >
                    <Attachment
                        :link="attachmentLink(attachment)"
                        :downloadLink="`${attachmentLink(attachment)}?download=true`"
                        :filename="attachment.filename"
                        :size="attachment.size"
                        :type="attachment.mime"
                    />
                </div>
            </div>
        </div>
        <div class="py-1 px-2" v-if="inlineAttachments.length">
            <button
                type="button"
                class="btn btn-link py-0 px-1"
                @click.prevent="showInline = !showInline"
            >
                <template v-if="showInline">
                    <span class="fas fa-eye-slash"></span>
                    {{ tr('Mails:button.hide_inline_attachments') }}
                    ({{inlineAttachments.length}})
                </template>
                <template v-else>
                    <span class="fas fa-eye"></span>
                    {{ tr('Mails:button.show_inline_attachments') }}
                    ({{inlineAttachments.length}})
                </template>
            </button>
        </div>
    </div>
</template>

<script>
import Attachment from './Attachment.vue';

export default {
  components: {
    Attachment,
  },
  props: {
    messageID: String,
    attachments: Array,
  },
  data() {
    return {
      showInline: false,
    };
  },
  computed: {
    inlineAttachments() {
      return this.attachments.filter((attachment) => attachment.isInline);
    },
    actualAttachments() {
      return this.attachments.filter((attachment) => !attachment.isInline);
    },
    visibleAttachments() {
      if (this.showInline) {
        return this.attachments;
      }
      return this.actualAttachments;
    },
  },
  methods: {
    attachmentLink(attachment) {
      return `/api/mails/messages/${this.messageID}/attachments/${attachment.ID}`;
    },
  },
};
</script>

<style scoped>
.attachment-wrapper {
	max-width: 300px;
}
</style>
