module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-end align-items-center\">\n	<div class=\"mx-1 text-nowrap\">\n		<strong title=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.called",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":38}}}))
    + "\" class=\"border-bottom border-primary\" data-toggle=\"tooltip\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"called") : depth0), depth0))
    + "</strong>\n		/\n		<strong title=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.skipped",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":17},"end":{"line":5,"column":39}}}))
    + "\" class=\"border-bottom border-warning\" data-toggle=\"tooltip\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"skipped") : depth0), depth0))
    + "</strong>\n		&nbsp;\n		<strong title=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.called",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":17},"end":{"line":7,"column":38}}}))
    + " + "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.skipped",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":63}}}))
    + "\" class=\"border-bottom\" data-toggle=\"tooltip\">("
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"used") : depth0), depth0))
    + ")</strong>\n	</div>\n	<div class=\"mx-1\">\n		<div class=\"progress\" style=\"width: 160px;\">\n			<div class=\"progress-bar\" title=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.called",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":57}}}))
    + " ("
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"called") : depth0), depth0))
    + ")\" style=\"width: "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"calledPercent") : depth0), depth0))
    + "%;\" data-toggle=\"tooltip\">&nbsp;</div>\n			<div class=\"progress-bar bg-warning\" title=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.skipped",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":12,"column":47},"end":{"line":12,"column":69}}}))
    + " ("
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"skipped") : depth0), depth0))
    + ")\" style=\"width: "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"skippedPercent") : depth0), depth0))
    + "%;\" data-toggle=\"tooltip\">&nbsp;</div>\n		</div>\n	</div>\n	<div class=\"mx-1\" style=\"width: 45px;\">\n		<strong title=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.total",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":16,"column":17},"end":{"line":16,"column":37}}}))
    + "\" data-toggle=\"tooltip\">\n			"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"count") : depth0), depth0))
    + "\n		</strong>\n	</div>\n</div>";
},"useData":true})