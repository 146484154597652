import i18n from 'i18next';
import { merge } from 'lodash-es';
import { initReactI18next } from 'react-i18next';

import { waitLoad } from '../wait-load';
import { rawLocale, translations } from './locales';

waitLoad().then(() => {
  const language = window.Bootstrap?.bootquery?.lang ?? 'hr';
  const overrides = window.Bootstrap?.bootquery?.localeOverrides ?? {};

  i18n.use(initReactI18next).init({
    lng: language,
    // Don't use fallback language during dev, use in prod.
    fallbackLng: rawLocale ? 'dev' : ['hr', 'en'],
    returnEmptyString: false,
    debug: true,
    defaultNS: 'global',
    fallbackNS: 'global',
    ns: ['global'],
    compatibilityJSON: 'v3',
    interpolation: {
      escapeValue: false, // Not needed for react
    },
  });

  document.addEventListener('clickvoxLocaleChange', (ev) => {
    const localeEvent = ev as CustomEvent<{ lang: string; locale: string }>;
    i18n.changeLanguage(localeEvent.detail.lang);
  });

  translations.forEach(({ module, locale, value }) => {
    const withOverrides = merge(value, overrides[locale]?.[module] ?? {});
    i18n.addResourceBundle(locale, module, withOverrides);
  });
});

export default i18n;
export { rawLocale };
