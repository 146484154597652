<template>
  <div class="card mb-3">
		<div class="card-header px-3 py-2">
            <div class="card-title">
                <strong>
                    <span class="fas fa-sms"></span>
                    <template v-if="sms.direction">
                        {{ tr('Campaigns:label.outgoing_sms') }}
						<span class="fas fa-arrow-right"></span>
                    </template>
                    <template v-else>
                        {{ tr('Campaigns:label.incoming_sms') }}
						<span class="fas fa-arrow-left"></span>
                    </template>
                </strong>
                <span class="text-muted float-right d-flex">
                    <span class="text-muted">
                        {{ sms.date | formatDate(tr('format.datetime')) }}
                    </span>
                </span>
            </div>
            <div class="card-subtitle mb-2 d-flex">
				<template v-if="sms.direction">
					<SmsPartyDisplay
						:phoneNumber="sms.account.phoneNumber"
						:displayName="sms.account.name"
					/>
					&nbsp;&nbsp;<span class="fa fa-long-arrow-alt-right"></span>&nbsp;&nbsp;
					<SmsPartyDisplay
						:phoneNumber="sms.remoteParty.phoneNumber"
						:displayName="sms.remoteParty.alphaSenderName"
					/>
				</template>
				<template v-else>
					<SmsPartyDisplay
						:phoneNumber="sms.remoteParty.phoneNumber"
						:displayName="sms.remoteParty.alphaSenderName"
					/>
					&nbsp;&nbsp;<span class="fa fa-long-arrow-alt-right"></span>&nbsp;&nbsp;
					<SmsPartyDisplay
						:phoneNumber="sms.account.phoneNumber"
						:displayName="sms.account.name"
					/>
				</template>
            </div>
		</div>
		<div class="card-body">{{sms.content}}</div>
		<div class="card-footer px-3 py-2 d-flex">
			<button type="button" class="btn btn-primary ml-auto" @click="reply">
				<span class="fas fa-reply"></span>
				Odgovori
			</button>
		</div>
	</div>
</template>

<script>
import SmsPartyDisplay from './SmsPartyDisplay.vue';

export default {
  components: {
    SmsPartyDisplay,
  },
  props: {
    data: Object,
  },
  methods: {
    reply() {
      this.module.replySms(this.sms);
    },
  },
  computed: {
    sms() {
      return this.data;
    },
    module() {
      return window.BootQuery.getModuleInstance('Campaigns');
    },
  },
};
</script>
