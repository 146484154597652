import { v4 as uuid4 } from 'uuid';
import { EventEmitter } from 'events';
import { popoverForTrigger, handlebarsRender } from 'app/assets/js/util';
import { defaults, merge } from 'lodash-es';
import { getTemplate, getFormData } from 'BootQuery/Assets/js/BootQuery';
import tr from 'BootQuery/Assets/js/translate';

export default class Field extends EventEmitter {
  constructor(optionsOrType) {
    super();

    this.options = optionsOrType;
    if (typeof optionsOrType === 'string') {
      this.options = {
        type: optionsOrType,
        new: true,
      };
    }
    defaults(this.options, {
      label: tr('form.new_field'),
      [`is_${this.options.type}`]: true,
    });
    if (!this.options.id) {
      this.options.id = uuid4();
    }
    this.id = this.options.id;
    this.on('settingsChange', this.onSettingsChanged.bind(this));
  }

  async render() {
    const renderOptions = merge({}, this.options, {
      readonly: true,
    });
    this.field = $(
      handlebarsRender(await getTemplate('editableFormFieldWrapper'), this.options),
    );
    this.field.find('.editable-field-wrapper').append(
      $.render(await getTemplate('form'), {
        ...renderOptions,
        disabled: true,
      }),
    );

    this.field
      .find('.label-text')
      .prop('contenteditable', true)
      .prop('spellcheck', false);

    this.bindEvents();

    return this.field;
  }

  renderFieldSettings() {
    const el = $('<div>Loading...</div>');

    getTemplate('formEditorFieldSettings').then((template) => {
      const rendered = $($.parseHTML(handlebarsRender(template, this.options)));
      rendered
        .find('input, textarea, select')
        .off('change.formBuilder')
        .on('change.formBuilder', (e) => {
          const settings = merge({}, this.options, getFormData(rendered));
          this.emit('settingsChange', settings);
        });
      el.replaceWith(rendered);
    });

    return el;
  }

  onSettingsChanged(settings) {
    settings = this.processSettings(settings);
    if (this.options.label !== settings.label) {
      this.field.find('.label-text').text(settings.label);
    }

    this.options = settings;
  }

  processSettings(settings) {
    if (typeof settings.mandatory !== 'boolean') {
      settings.mandatory = settings.mandatory === 'true';
    }
    if (typeof settings.readonly !== 'boolean') {
      settings.readonly = settings.readonly === 'true';
    }
    return settings;
  }

  async bindEvents() {
    this.field
      .find('.editable-field-delete-btn')
      .off('click.formEditor')
      .on('click.formEditor', (e) => {
        this.options.deleted = true;
        this.field.remove();
      });

    // I hate these popovers
    this.field
      .find('.editable-field-edit-btn')
      .popover({
        trigger: 'manual',
        html: true,
        container: 'body',
        content: () => this.renderFieldSettings(),
      })
      .off('click.formEditor')
      .on('click.formEditor', (e) => {
        e.preventDefault();
        $(e.currentTarget).popover('toggle');
      })
      .on('inserted.bs.popover', (e) => {
        popoverForTrigger(e.currentTarget).addClass('popover-opening');
      })
      .on('show.bs.popover', (e) => {
        popoverForTrigger(e.currentTarget).css({
          maxWidth: '400px',
        });
      })
      .on('shown.bs.popover', (e) => {
        popoverForTrigger(e.currentTarget).removeClass('popover-opening');
      });

    this.field
      .find('.label-text')
      .contenteditableChangeEvent()
      .off('change.formBuilder')
      .on('change.formBuilder', (e) => {
        const settings = merge({}, this.options, {
          label: $(e.currentTarget).text(),
        });
        this.emit('settingsChange', settings);
      });
  }

  getDefinition() {
    return this.options;
  }
}
