import { ReactElement, useMemo } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ExportModalProvider } from '@bq/components/Export';
import { FieldValue } from '@bq/components/FormEditor';
import { defaultDisplayModeOptions } from '@bq/components/TableMenu';
import { Card, CardBody } from 'BootQuery/Assets/components/Card';
import { ListWrapper } from 'BootQuery/Assets/components/ListWrapper';
import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';
import { Column } from 'BootQuery/Assets/components/Table';
import { useQueryFilters } from 'BootQuery/Assets/js/use-filters';
import { useQueryPage } from 'BootQuery/Assets/js/use-page';

import { generateExport } from '../api';
import { CallListPermissions } from '../call-list-permissions';
import { CallListContactModalProvider } from '../CallListContactModalProvider';
import { CallListHeading } from '../CallListHeading';
import { useFilterTypes } from '../filter-types';
import { formatCall } from '../format-call';
import { PageWithPlayer } from '../RecordingPlayer';
import { CallListCall } from '../types';
import { useCalls } from '../use-calls';
import { useDisplayMode } from '../use-display-mode';
import { useSelectedOverview } from '../use-selected-overview';
import { CardList } from './CardList';
import { useColumnVisibility, useLimit, useRefreshMethod } from './util';

interface Props {
  overviews: IOverviewEditorItem[];
  permissions: CallListPermissions;
  customFields: FieldValue[];
}

export const CallCards = ({
  overviews,
  permissions,
  customFields,
}: Props): ReactElement => {
  const [page, setPage] = useQueryPage();
  const filterTypes = useFilterTypes(customFields);
  const [filters, setFilters] = useQueryFilters(filterTypes);
  const overview = useSelectedOverview(overviews);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [visibleColumns, setVisibleColumns] = useColumnVisibility();
  const [limit, setLimit] = useLimit();
  const [refreshMethod, setRefreshMethod] = useRefreshMethod();

  // Never remove the useTranslation to force Telephony translations to load
  const { t } = useTranslation('Telephony');

  const columns: Column<CallListCall>[] = [];

  const overviewFilters = useMemo(() => overview?.filters ?? [], [overview]);
  const allFilters = useMemo(
    () => [...overviewFilters, ...filters],
    [filters, overviewFilters]
  );

  const { calls, status, refetch, newRowIds, filterExpr } = useCalls({
    page,
    limit,
    filters: allFilters,
    filterTypes,
    refreshMethod,
  });

  let rows: CallListCall[] = [];
  const rowCount = calls?.meta.count ?? null;
  if (status === 'success' && calls) {
    rows = calls.data.map(formatCall);
  }

  return (
    <PageWithPlayer>
      <CallListContactModalProvider>
        <ExportModalProvider
          onExport={generateExport}
          rowCount={rowCount ?? 0}
          filterExpression={filterExpr ?? []}
        >
          <ListWrapper spacing="5">
            <Box mx={5} my={9}>
              <CallListHeading
                count={rowCount ?? 0}
                limit={limit}
                filterProps={{
                  filterTypes,
                  filters,
                  setFilters,
                }}
                menuProps={{
                  displayMode: {
                    options: defaultDisplayModeOptions,
                    value: displayMode ?? 'card',
                    onChange: setDisplayMode,
                  },
                  visibleColumns: {
                    columns,
                    value: visibleColumns,
                    onChange: setVisibleColumns,
                  },
                  limit: { value: limit, onChange: setLimit },
                  refreshMethod: {
                    value: refreshMethod,
                    onChange: setRefreshMethod,
                  },
                }}
                page={page}
                setPage={setPage}
                permissions={permissions}
                refetch={refetch}
                newRows={newRowIds.length}
                isLoading={status === 'loading'}
                withExport
              />
            </Box>

            {status !== 'loading' && !rows.length ? (
              <Card>
                <CardBody py="16">
                  <Heading color="gray.600" mb={0} textAlign="center">
                    {t('global:no_results')}
                  </Heading>
                </CardBody>
              </Card>
            ) : (
              <CardList calls={rows} />
            )}
          </ListWrapper>
        </ExportModalProvider>
      </CallListContactModalProvider>
    </PageWithPlayer>
  );
};
