import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { filterExpression } from './filter-expression';
import { filterTypesWithGroup } from './filters/GroupFilter';
import { FilterExpression, FilterTypes, FilterValue } from './types';

export function useFilterExpression(
  filterTypes: FilterTypes,
  filterValues: FilterValue[],
  searchString = '',
  queryOptions: Omit<UseQueryOptions<FilterExpression[]>, 'queryKey' | 'queryFn'> = {}
): UseQueryResult<FilterExpression[]> {
  return useQuery(
    ['filterExpression', filterValues, filterTypes, searchString],
    () => filterExpression(
      filterTypesWithGroup(filterTypes),
      [{ filter: '$group', operator: '$and', value: filterValues }],
      searchString,
    ),
    queryOptions
  );
}
