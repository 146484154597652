import { ReactElement, useCallback, ChangeEvent } from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import ReactSimpleTimefield from 'react-simple-timefield';

import { FieldValue } from '../../types';

export const TimeField = ({ id, settings }: FieldValue): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid },
  } = useController({
    name: id,
    control,
    rules: { required: settings.required },
    defaultValue: '',
  });

  const handleChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    onChange(ev.target.value);
  }, [onChange]);

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel fontWeight="bold">{settings.name}:</FormLabel>
      <ReactSimpleTimefield
        value={value ?? undefined}
        onChange={handleChange}
        input={
          <Input value={value ?? undefined} onBlur={onBlur} />
        }
      />
    </FormControl>
  );
};
