<template>
  <div class="quill-container">
    <div ref="quill"></div>
  </div>
</template>

<script>
import { isEqual } from 'lodash-es';
import Quill from './index';
import { quillOptions } from './quill-options';

export default {
  props: {
    content: Object,
    html: String,
    text: String,
    disabled: Boolean,
    options: {
      type: Object,
      default: () => quillOptions,
    },
  },
  data() {
    return {
      quill: null,
      contentJSON: this.content,
    };
  },
  mounted() {
    this.quill = new Quill(this.$refs.quill, this.options);
    if (this.contentJSON) {
      this.quill.setContents(this.contentJSON);
      this.$emit('update:html', this.getHtml());
      this.$emit('update:text', this.getText());
    } else if (this.html) {
      this.quill.pasteHTML(this.html);
      this.$emit('update:content', this.quill.getContents());
      this.$emit('update:text', this.getText());
    } else if (this.text) {
      this.quill.insertText(0, this.text);
      this.$emit('update:content', this.quill.getContents());
      this.$emit('update:html', this.getHtml());
    }

    this.quill.on('text-change', (delta) => {
      this.contentJSON = this.quill.getContents();
      this.$emit('update:content', this.contentJSON);
      this.$emit('update:html', this.getHtml());
      this.$emit('update:text', this.getText());
    });

    this.quill.enable(!this.disabled);
  },
  methods: {
    getHtml() {
      return this.$refs.quill.firstChild.innerHTML;
    },
    getText() {
      return this.$refs.quill.firstChild.innerText;
    },
    getJson() {
      return this.quill.getContents();
    },
    focus() {
      this.quill.focus();
    },
  },
  watch: {
    content(newContent) {
      if (!isEqual(this.contentJSON, newContent)) {
        this.contentJSON = newContent;
        this.quill.setContents(this.contentJSON);
      }
    },
    disabled(isDisabled) {
      this.quill.enable(!isDisabled);
    },
  },
};
</script>

<style scoped>
.quill-container {
  background: #fff;
  color: #282828;
}
</style>
