import { ReactElement, memo } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Column, CellValue } from 'BootQuery/Assets/components/Table';

interface Props<RT> {
  columns: Column<RT>[];
  data: RT;
  fontSize?: string;
}

function CardCustomColumnsInner<RT>({
  columns,
  data,
  fontSize = 'xs',
}: Props<RT>): ReactElement {
  return (
    <>
      {columns.map((column) => (
        <Flex
          key={column.key}
          w="full"
          justifyContent="start"
          fontWeight="light"
          fontSize={fontSize}
        >
          {column.title && <Text as="span">{column.title}:&nbsp;</Text>}
          <Text
            as="strong"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            <CellValue rowData={data} rowIdx={1} column={column} />
          </Text>
        </Flex>
      ))}
    </>
  );
}

export const CardCustomColumns = memo(
  CardCustomColumnsInner
) as typeof CardCustomColumnsInner;
