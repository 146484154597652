import { FormControl, FormLabel } from '@chakra-ui/react';
import { SingleDateInput } from 'BootQuery/Assets/components/DateInput';
import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FieldValue } from '../../types';

export const DateField = ({ id, settings }: FieldValue): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid },
  } = useController({
    name: id,
    control,
    rules: { required: settings.required },
    defaultValue: '',
  });

  if (typeof value === 'string' && value !== '') {
    throw new Error([
      `Got string instead of Date for date field ${id}`,
      'Did you forget to call loadValues(...) when loading data?',
    ].join('. '));
  }

  const inputClass = invalid
    ? 'datepicker-input datepicker-input-invalid'
    : 'datepicker-input';

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{settings.name}:</FormLabel>
      <SingleDateInput
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputClass={inputClass}
        isClearable={!settings.required}
      />
    </FormControl>
  );
};
