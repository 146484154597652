import { ReactElement, useCallback } from 'react';
import { Box } from '@chakra-ui/react';

import { ListHeading } from '@bq/components/ListHeading';
import {
  useHeadingProps,
  useListingSettings,
} from '@bq/components/ListingSettings';
import { NoDataMessage } from '@bq/components/NoDataMessage';
import { Table, TableCountFooter } from '@bq/components/Table';

import { useColumns } from './columns';
import { DatasetListItem } from './schema';
import { useDatasetList } from './use-dataset-list';

export const DatasetTable = (): ReactElement => {
  const listingSettings = useListingSettings<DatasetListItem>({
    listingName: 'Datasets.DatasetsList',
    viewName: 'table',
    filterTypes: {},
    columns: useColumns(),
    otherFilters: [],
    defaults: {},
  });
  const { limit, density, sort, setSort, columnsToShow } = listingSettings;

  const createRowLink = useCallback((row: DatasetListItem) => {
    return `/datasets/dataset/${row.ID}`;
  }, []);

  const { data, isLoading } = useDatasetList(listingSettings);

  const totalCount = data?.meta?.count ?? 0;

  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count: totalCount,
    displayModeOptions: [],
  });

  return (
    <>
      <Box my={9} px={5}>
        <ListHeading {...headingProps} />
      </Box>
      {!isLoading && !data?.data.length ? (
        <NoDataMessage />
      ) : (
        <Table<DatasetListItem>
          sort={{ value: sort, onChange: setSort }}
          columns={columnsToShow}
          isLoading={isLoading}
          placeholderRows={limit}
          rows={data?.data ?? []}
          createRowLink={createRowLink}
          size={density}
          footer={<TableCountFooter count={totalCount} />}
        />
      )}
    </>
  );
};
