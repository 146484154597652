<template>
  <div class="card mb-3">
		<div class="card-body">
            <div class="card-title">
                <strong>
                    <span class="fa fa-phone"></span>
                    <template v-if="call.direction">
                        {{ tr('Campaigns:label.outgoing_call') }}
                    </template>
                    <template v-else>
                        {{ tr('Campaigns:label.incoming_call') }}
                    </template>

                    <span v-if="call.answered" class="badge badge-success">
                        {{ tr('Campaigns:label.answered_call') }}
                    </span>
                    <span v-else class="badge badge-danger">
                        {{ tr('Campaigns:label.missed_call') }}
                    </span>
                </strong>
                <span class="text-muted float-right d-flex">
                    <span class="text-muted">
                        {{ call.callStart | formatDate(tr('format.datetime')) }}
                    </span>
                </span>
            </div>
            <div class="card-subtitle mb-2 d-flex">
                <div class="col">
                    <CallParticipantDisplay :phoneNumberE164="call.callerNumber.phoneNumberE164" />
                    &nbsp;&nbsp;<span class="fa fa-long-arrow-alt-right"></span>&nbsp;&nbsp;
                    <CallParticipantDisplay
                        v-if="call.calleeNumber"
                        :phoneNumberE164="call.calleeNumber.phoneNumberE164"
                        :queue="call.queue"
                    />
                </div>
                <div class="col-auto">
                    <a
                        href="#"
                        class="calls-recording-audio"
                        data-recording="audio"
                        :data-recording-id="call.audioRecordingID"
                        :disabled="!call.audioRecordingID"
                    >
                        <span
                            class="recording-icon text-secondary"
                            :class="audioRecordingIconClass"
                        ></span>
                    </a>

                    <a
                        href="#"
                        class="calls-recording-video"
                        data-recording="video"
                        :data-recording-id="call.videoRecordingID"
                        :disabled="!call.videoRecordingID"
                    >
                        <span
                            class="recording-icon text-secondary"
                            :class="videoRecordingIconClass"
                        ></span>
                    </a>
                </div>
            </div>
		</div>
        <div class="card-footer px-3 py-2 d-flex">
            <button type="button" class="btn btn-primary ml-auto" @click="makeCall">
				<span class="fas fa-phone"></span>
				Nazovi
			</button>
        </div>
	</div>
</template>

<script>
import CallParticipantDisplay from './CallParticipantDisplay.vue';

export default {
  components: {
    CallParticipantDisplay,
  },
  props: {
    data: Object,
  },
  methods: {
    makeCall() {
      let numberToCall = this.call.direction ? this.call.calleeNumber : this.call.calleeNumber;
      numberToCall = numberToCall ? numberToCall.phoneNumberE164 : null;
      if (!numberToCall) {
        console.warn('Unable to get number to call');
        return;
      }

      this.module.manualCall(numberToCall);
    },
  },
  computed: {
    call() {
      return this.data;
    },
    audioRecordingIconClass() {
      return this.call.audioRecordingID ? 'fas fa-microphone' : 'fas fa-microphone-slash no-recording';
    },
    videoRecordingIconClass() {
      return this.call.videoRecordingID ? 'fas fa-video' : 'fas fa-video-slash no-recording';
    },
    module() {
      return window.BootQuery.getModuleInstance('Campaigns');
    },
  },
};
</script>

<style lang="scss" scoped>
.recording-icon {
    padding-left: 5px;
    min-width: 25px;
    display: inline-block;
    text-align: center;
}

.recording-icon.no-recording {
     opacity: 0.5;
}
</style>
