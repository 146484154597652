import { FormControl, FormLabel } from '@chakra-ui/react';
import { TagInput } from 'BootQuery/Assets/components/TagInput';
import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FieldValue } from '../../types';
import { TagFieldSettings } from './types';

type Props = FieldValue<TagFieldSettings>;

export const TagField = ({ id, settings }: Props): ReactElement => {
  const { control } = useFormContext();
  const { field } = useController({ control, name: id });

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{settings.name}:</FormLabel>
      <TagInput value={field.value} onChange={field.onChange} options={settings.tags} />
    </FormControl>
  );
};
