module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":61,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"card\" id=\"wizard-container\">\n		<div class=\"card-header\">\n			<span class=\"fa fa-download\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.import_dataset",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":69}}}))
    + "\n		</div>\n		<div class=\"card-body\">\n			"
    + alias2(lookupProperty(helpers,"log").call(alias1,depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":8,"column":3},"end":{"line":8,"column":15}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"result") : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":3},"end":{"line":53,"column":12}}})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"card-footer d-flex\">\n			<button class=\"btn btn-primary ml-auto\" type=\"submit\" form=\"import-form\">\n				"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.next",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":57,"column":4},"end":{"line":57,"column":24}}}))
    + "&nbsp;<span class=\"fa fa-arrow-right\"></span>\n			</button>\n		</div>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<form action=\"/campaigns/import\" method=\"POST\" id=\"import-form\">\n				<input type=\"hidden\" name=\"step\" value=\"2\">\n				<input type=\"hidden\" name=\"dataset_name\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dataset_name") : depth0), depth0))
    + "\">\n				<input type=\"hidden\" name=\"file\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"file") : depth0), depth0))
    + "\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"settings") : depth0)) != null ? lookupProperty(stack1,"columns") : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"settings") : depth0)) != null ? lookupProperty(stack1,"Csv") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "\n				<div class=\"form-row\">\n					<div class=\"form-group col\">\n						<div class=\"custom-control custom-checkbox custom-control-inline\">\n							<input type=\"checkbox\" name=\"settings[header]\" id=\"settings[header]\" class=\"custom-control-input\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"settings") : depth0)) != null ? lookupProperty(stack1,"header") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":105},"end":{"line":43,"column":142}}})) != null ? stack1 : "")
    + ">\n							<label class=\"custom-control-label\" for=\"settings[header]\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"import.first_row_header",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":44,"column":66},"end":{"line":44,"column":98}}}))
    + "</label>\n						</div>\n					</div>\n				</div>\n\n				<div class=\"card table-responsive\">\n					"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"editTable"),depth0,{"name":"editTable","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "				</div>\n			</form>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<input type=\"hidden\" name=\"settings[columns]["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][name]\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"form-row\">\n						<div class=\"form-group col-md-2\">\n							<label for=\"settings[encoding]\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"import.encoding",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":22,"column":39},"end":{"line":22,"column":63}}}))
    + "</label>\n							<select class=\"pickle\" id=\"settings[encoding]\" name=\"settings[encoding]\" data-searchable=\"false\">\n								<option value=\"UTF-8\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"settings") : depth0)) != null ? lookupProperty(stack1,"UTF8") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":30},"end":{"line":24,"column":66}}})) != null ? stack1 : "")
    + ">UTF8</option>\n								<option value=\"CP1250\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"settings") : depth0)) != null ? lookupProperty(stack1,"CP1250") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":31},"end":{"line":25,"column":69}}})) != null ? stack1 : "")
    + ">CP1250</option>\n							</select>\n						</div>\n						<div class=\"form-group col-md-2\">\n							<label for=\"settings[delimiter]\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"import.separator",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":29,"column":40},"end":{"line":29,"column":65}}}))
    + "</label>\n							<input type=\"text\" class=\"form-control\" id=\"settings[delimiter]\" name=\"settings[delimiter]\" placeholder=\";\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"settings") : depth0)) != null ? lookupProperty(stack1,"delimiter") : stack1), depth0))
    + "\" autocomplete=\"off\">\n						</div>\n\n						<div class=\"form-group col-md-2\">\n							<label for=\"settings[enclosure]\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"import.enclosure",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":34,"column":40},"end":{"line":34,"column":65}}}))
    + "</label>\n							<input type=\"text\" class=\"form-control\" id=\"settings[enclosure]\" name=\"settings[enclosure]\" placeholder='\"' value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"settings") : depth0)) != null ? lookupProperty(stack1,"enclosure") : stack1), depth0))
    + "\" autocomplete=\"off\">\n						</div>\n					</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "selected";
},"9":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":62,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true})