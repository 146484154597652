import mitt from 'mitt';
import { v4 as uuid } from 'uuid';
import { create as zustand } from 'zustand';

import reusePromise from 'BootQuery/Assets/js/reuse-promise';

interface Tab {
  id: string;
  lastActive: number;
  voteScore?: number | null;
  focused: boolean;
  [key: string]: unknown;
}

type Tabs = Record<string, Tab>;

function tabsFromLocalStorage(): Tabs {
  return Object.entries(localStorage).reduce((tabs, [key, val]) => {
    const tabMatch = key.match(/^tab-(.+)$/);
    if (tabMatch && val) {
      const tabId = tabMatch[1];
      const tabData = JSON.parse(val);

      return { ...tabs, [tabId]: tabData };
    }

    return tabs;
  }, {});
}

type TabStoreEvents = {
  setMasterTabId: string | null;
  setTab: { id: string; data: Tab };
  removeTab: string;
};

const storeEvents = mitt<TabStoreEvents>();

export interface TabsStateStore {
  tabId: string;
  tabs: Record<string, Tab>;
  lastActivityAt: Date;
  masterTabId: string | null;

  init: () => Promise<void>;
  initMasterTab: () => Promise<void>;
  setOwnTab: (tabData: Tab) => void;
  getOwnTab: () => Tab;
}

export const tabs = zustand<TabsStateStore>((set, get, { subscribe }) => {
  const getOwnTab = (): Tab => {
    const { tabs, tabId } = get();
    const ownTab = tabs[tabId];
    if (!ownTab) {
      console.error('Own tab not found: ', { tabId, tabs });
      throw new Error('Own tab not found');
    }

    return tabs[tabId];
  };
  const setOwnTab = (tabData: Tab) => {
    const { tabId } = get();
    setTab(tabId, tabData);
    localStorage.setItem(`tab-${tabId}`, JSON.stringify(tabData));
  };
  const setMasterTabId = (masterTabId: string | null) => {
    console.log('Set mastery tab: ', masterTabId);
    set({ masterTabId });
    storeEvents.emit('setMasterTabId', masterTabId);
  };
  const setTabs = (tabs: Tabs) => {
    set({ tabs });
  };
  const setTab = (id: string, tab: Tab) => {
    set({
      tabs: {
        ...get().tabs,
        [id]: tab,
      },
    });
    storeEvents.emit('setTab', { id, data: tab });
  };
  const removeTab = (tabId: string) => {
    const tabs = { ...get().tabs };
    delete tabs[tabId];

    set({ tabs });
    storeEvents.emit('removeTab', tabId);
  };

  const handleStorageEvent = (ev: StorageEvent) => {
    const { key, newValue: val } = ev;
    if (!key) {
      return;
    }

    if (key === 'masterTabId') {
      if (val) {
        setMasterTabId(val);
      } else {
        electNewMaster();
      }
    }

    const tabMatch = key.match(/^tab-(.+)$/);
    if (tabMatch) {
      const tabId = tabMatch[1];
      const tabData = val ? JSON.parse(val) : null;
      if (tabData) {
        setTab(tabId, tabData);
      } else {
        removeTab(tabId);
      }
    }
  };

  const updateFocused = () => {
    setOwnTab({ ...getOwnTab(), focused: document.hasFocus() });
  };

  const tabsKeepalive = () => {
    const now = new Date().getTime();

    setOwnTab({ ...getOwnTab(), lastActive: now });

    // Prune dead tabs
    Object.entries(get().tabs).forEach(([tabId, tab]) => {
      if (!tab.id) {
        localStorage.removeItem(`tab-${tabId}`);
        removeTab(tabId);

        return;
      }
      const { lastActive } = tab;
      if (lastActive && now - lastActive > 5 * 1000) {
        localStorage.removeItem(`tab-${tab.id}`);
        removeTab(tab.id);
      }
    });

    // If master died without telling us, prune it now
    const { tabs, masterTabId } = get();
    if (masterTabId && !tabs[masterTabId]) {
      localStorage.removeItem('masterTabId');
      electNewMaster();
    }
  };

  const electNewMaster = async () => {
    localStorage.setItem('masterTabLock', JSON.stringify(true));
    const ourRand = Math.random();
    const ourHiddenScore = document.hidden ? 0 : 1;
    const ourFocusScore = document.hasFocus() ? 0 : 1;
    const ourScore = ourRand + ourHiddenScore * 2 + ourFocusScore * 1;

    let voterCount = Object.keys(get().tabs).length;
    const unsub = () => {
      storeEvents.off('setMasterTabId');
      storeEvents.off('setTab');
      storeEvents.off('removeTab');
    };
    let timeout: number | null = null;

    const countVotes = () => {
      unsub();

      const candidates = Object.entries(get().tabs)
        .map(([tabId, tabData]) => ({
          id: tabId,
          score: tabData.voteScore ?? 100,
        }))
        .sort((tabA, tabB) => Math.sign(tabA.score - tabB.score));
      console.log('Candidates: ', candidates);

      const [winner] = candidates;

      setOwnTab({ ...getOwnTab(), voteScore: null });
      localStorage.setItem('masterTabId', winner.id);
      setMasterTabId(winner.id);
      localStorage.removeItem('masterTabLock');
    };

    const votes = new Set();

    const onProgress = () => {
      if (votes.size >= voterCount) {
        if (timeout) {
          clearTimeout(timeout);
        }
        countVotes();
      }
    };
    storeEvents.on('setMasterTabId', () => {
      unsub();
      if (timeout) {
        window.clearTimeout(timeout);
      }
      onProgress();
    });
    storeEvents.on('setTab', ({ id, data }) => {
      console.log('Set tab: ', id, data);
      if (data.voteScore) {
        votes.add(id);
      }
      onProgress();
    });
    storeEvents.on('removeTab', (tabId) => {
      // One less candidate during election, don't wait for their votes
      voterCount -= 1;
      onProgress();
    });

    timeout = window.setTimeout(() => {
      timeout = null;
      countVotes();
    }, 5000);

    setOwnTab({ ...getOwnTab(), voteScore: ourScore });
  };

  let initialised = false;

  const store: TabsStateStore = {
    setOwnTab,
    getOwnTab,
    tabId: uuid(),
    tabs: {},
    lastActivityAt: new Date(),
    masterTabId: null,

    init: reusePromise(async () => {
      console.log('Into init!');
      if (initialised) {
        return;
      }

      setTabs(tabsFromLocalStorage());

      const now = new Date().getTime();
      get().setOwnTab({
        id: get().tabId,
        lastActive: now,
        focused: document.hasFocus(),
      });

      window.addEventListener('focus', updateFocused);
      window.addEventListener('blur', updateFocused);

      window.addEventListener('storage', handleStorageEvent);

      window.addEventListener(
        'unload',
        () => {
          const { tabId } = get();
          const masterTabId = localStorage.getItem('masterTabId');
          if (tabId === masterTabId) {
            localStorage.removeItem('masterTabId');
          }

          localStorage.removeItem(`tab-${tabId}`);
        },
        false
      );

      await get().initMasterTab();

      setInterval(tabsKeepalive, 2000);
      initialised = true;
      console.log('Did the initie');
    }),
    initMasterTab: async () => {
      if (localStorage.getItem('isElectingMaster')) {
        setMasterTabId(null);
      } else {
        let masterTabId = localStorage.getItem('masterTabId');
        if (!masterTabId) {
          masterTabId = get().tabId;
          localStorage.setItem('masterTabId', masterTabId);
        }

        setMasterTabId(masterTabId);
      }
    },
  };

  return store;
});

export function getFocusedTab(state: TabsStateStore): Tab | null {
  return Object.values(state.tabs).find((tab) => tab.focused) ?? null;
}
