import { useMemo } from 'react';

import {
  customFormColumns,
  FieldValue,
  getFieldTypes,
} from '@bq/components/FormEditor';
import { Column, makeSelectionCell } from '@bq/components/Table';
import i18n from 'BootQuery/Assets/js/i18n';

import { EmailsCell } from '../EmailsCell';
import { PhoneNumbersCell } from '../PhoneNumbersCell';
import { CompanyApiData } from '../types';
import { CompanyCardFeatures } from './types';

interface CompanyColumn extends Column<CompanyApiData> {
  forCard?: boolean;
}

export function columns(): CompanyColumn[] {
  return [
    makeSelectionCell(),
    {
      key: 'name',
      title: i18n.t('Phonebook:name'),
    },
    {
      key: 'phoneNumbers',
      title: i18n.t('Phonebook:phone_number'),
      Cell: PhoneNumbersCell,
      forCard: true,
    },
    {
      key: 'emails',
      title: i18n.t('Phonebook:email_address'),
      Cell: EmailsCell,
      forCard: true,
    },
    {
      key: 'city',
      title: i18n.t('Phonebook:city'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.city?.name ?? '-',
    },
    {
      key: 'postalCode',
      title: i18n.t('Phonebook:postal_code'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.postalCode?.code ?? '-',
    },
    {
      key: 'country',
      title: i18n.t('Phonebook:country'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.country?.name ?? '-',
    },
    {
      key: 'street',
      title: i18n.t('Phonebook:street'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.street?.name ?? '-',
    },
    {
      key: 'streetNumber',
      title: i18n.t('Phonebook:street_number'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.streetNumber ?? '-',
    },
    {
      key: 'nationalNumber',
      title: i18n.t('Phonebook:national_number'),
      defaultVisible: false,
      getValue: ({ row }) => row.nationalNumber?.nationalNumber ?? '-',
    },
  ];
}

export function useTableColumns(
  customFields: FieldValue[] = []
): Column<CompanyApiData>[] {
  return useMemo(() => {
    const customColumns = customFormColumns(
      customFields,
      getFieldTypes()
    ) as unknown as Column<CompanyApiData>[];

    return [...columns(), ...customColumns];
  }, [customFields]);
}

export function useCardColumns(
  customFields: FieldValue[] = []
): Column<CompanyApiData>[] {
  return useMemo(() => {
    const cardColumns = columns().filter((col) => col.forCard);
    const customColumns = customFormColumns(customFields, getFieldTypes());

    return [...cardColumns, ...(customColumns as unknown as CompanyColumn[])];
  }, [customFields]);
}

interface SplitColumnFeatures {
  features: Partial<CompanyCardFeatures>;
  columns: Column<CompanyApiData>[];
}

const notColumns = ['phoneNumbers', 'emails', '$selected', 'name'];
export function useSplitColumnsFeatures(
  columns: Column<CompanyApiData>[]
): SplitColumnFeatures {
  return useMemo(
    () => ({
      columns: columns.filter((col) => !notColumns.includes(col.key)),
      features: {
        phoneNumbers: columns.some((col) => col.key === 'phoneNumbers'),
        emails: columns.some((col) => col.key === 'emails'),
        select: columns.some((col) => col.key === '$selected'),
        name: columns.some((col) => col.key === 'name'),
      },
    }),
    [columns]
  );
}

export const companyCardFeatures = {
  $selected: 'select',
  emails: 'emails',
  phoneNumbers: 'phoneNumbers',
  name: 'name',
};
