<template>
	<div class="attachment bg-white p-1 px-2 rounded border">
		<component :is="link ? 'a' : 'div'" v-bind="linkProps" class="attachment-inner">
			<div class="attachment-icon pr-2">
				<span class="fas fa-file"></span>
			</div>
			<div class="attachment-info">
				<div class="info-filename" :title="filename">
					<strong>{{ filename }}</strong>
				</div>
				<div class="info-size text-muted">
					<small v-if="size">{{ size | formatFileSize }}</small>
					<small v-else>
						<!-- Here to keep height -->
						&nbsp;
					</small>
				</div>
			</div>
		</component>
		<button v-if="removable" type="button" class="attachment-remove" @click="$emit('remove')">
			<span class="fas fa-times-circle"></span>
		</button>
		<div
			v-if="uploading"
			class="attachment-upload-progress"
			:class="uploadDone ? 'bg-success' : 'bg-primary'"
			:style="{ width: `${uploadProgress}%` }"></div>

		<div v-if="downloadLink || link" class="attachment-actions">
			<a
				:href="downloadLink || link"
				:class="{'text-success': uploading}"
				download>
				<span class="fas fa-download"></span>
			</a>
		</div>
	</div>
</template>

<script>
import {
  formatFileSize,
} from 'app/assets/js/util';

export default {
  props: {
    filename: String,
    size: Number,
    link: String,
    downloadLink: String,
    removable: Boolean,
    uploading: Boolean,
    uploadProgress: Number,
    uploadDone: Boolean,
  },
  filters: {
    formatFileSize,
  },
  computed: {
    linkProps() {
      if (this.link) {
        const linkClass = ['open-link'];
        if (this.uploading) {
          linkClass.push('text-success');
        }
        return {
          class: linkClass,
          href: this.link,
          target: '_blank',
        };
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment {
	flex: 0 1 auto;
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;
}

.attachment-inner {
	display: flex;
	overflow: hidden;
}

.attachment-actions {
	padding-left: 0.5rem;
}

.attachment-icon {
	flex: 0 1 auto;
	display: inline-flex;
	align-items: center;

	>span {
		font-size: 1.6em;
	}
}

.attachment-info {
	flex: 1 1 auto;
	line-height: 1.1;
	overflow: hidden;

	>.info-filename {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.attachment-actions {
	flex: 0 1 auto;

	>* {
		vertical-align: middle;
	}
}

.attachment-upload-progress {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 4px;
	transition: width 0.1s ease-out;
}

.attachment-remove {
	position: absolute;
	top: -6px;
	right: -6px;
	background: none;
	border: none;
	color: #dc3545;
	padding: 0;
	cursor: pointer;
	opacity: 0.5;
}

.attachment:hover .attachment-remove {
	opacity: 0.9;
}

.attachment-remove:hover {
	color: #c82333;
	opacity: 1;
}
</style>
