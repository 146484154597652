import { ReactElement } from 'react';
import { CellProps } from 'BootQuery/Assets/components/Table';
import { ApiNumber } from './types';
import { NumberList } from './NumberList';

interface NumberCell {
  phoneNumbers: ApiNumber[];
}

export const PhoneNumbersCell = <T extends NumberCell>({
  row,
}: CellProps<T>): ReactElement => {
  if (row.phoneNumbers.length === 0) {
    return <>-</>;
  }

  return <NumberList phoneNumbers={row.phoneNumbers} />;
};
