module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group\">\n    <label>"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.label",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":3,"column":11},"end":{"line":3,"column":31}}}))
    + ": </label>\n    <input type=\"text\" name=\"label\" class=\"form-control\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\">\n</div>\n<div class=\"form-group\">\n    <input type=\"hidden\" name=\"mandatory\" value=\"false\">\n    <div class=\"custom-control custom-checkbox\">\n        <input type=\"checkbox\" class=\"custom-control-input\" id=\"mandatory-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" name=\"mandatory\" value=\"true\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mandatory") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":112},"end":{"line":9,"column":143}}})) != null ? stack1 : "")
    + ">\n        <label class=\"custom-control-label\" for=\"mandatory-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.mandatory",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":10,"column":67},"end":{"line":10,"column":91}}}))
    + "</label>\n    </div>\n\n    <input type=\"hidden\" name=\"readonly\" value=\"false\">\n    <div class=\"custom-control custom-checkbox\">\n        <input type=\"checkbox\" class=\"custom-control-input\" id=\"readonly-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" name=\"readonly\" value=\"true\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readonly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":110},"end":{"line":15,"column":140}}})) != null ? stack1 : "")
    + ">\n        <label class=\"custom-control-label\" for=\"readonly-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.readonly",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":16,"column":66},"end":{"line":16,"column":89}}}))
    + "</label>\n    </div>\n\n    <input type=\"hidden\" name=\"visible_to_agents\" value=\"false\">\n    <div class=\"custom-control custom-checkbox\">\n        <input type=\"checkbox\" class=\"custom-control-input\" id=\"visible_to_agents-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" value=\"true\" name=\"visible_to_agents\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"visible_to_agents") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":128},"end":{"line":21,"column":167}}})) != null ? stack1 : "")
    + ">\n        <label class=\"custom-control-label\" for=\"visible_to_agents-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.visible_to_agent",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":22,"column":75},"end":{"line":22,"column":105}}}))
    + "</label>\n    </div>\n</div>\n\n<div class=\"form-group\">\n    <label for=\"\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.field_type",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":27,"column":18},"end":{"line":27,"column":42}}}))
    + ":</label>\n    <div class=\"custom-control custom-radio\">\n        <input type=\"radio\" id=\"data-type-basic-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" name=\"data_type\" class=\"custom-control-input\" value=\"basic\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_basic") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":116},"end":{"line":29,"column":146}}})) != null ? stack1 : "")
    + ">\n        <label class=\"custom-control-label\" for=\"data-type-basic-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.basic_field",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":30,"column":73},"end":{"line":30,"column":98}}}))
    + "</label>\n    </div>\n    <div class=\"custom-control custom-radio\">\n        <input type=\"radio\" id=\"data-type-editable-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" name=\"data_type\" class=\"custom-control-input\" value=\"scenario\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_scenario") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":122},"end":{"line":33,"column":155}}})) != null ? stack1 : "")
    + ">\n        <label class=\"custom-control-label\" for=\"data-type-editable-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.editable_field",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":34,"column":76},"end":{"line":34,"column":104}}}))
    + "</label>\n    </div>\n</div>\n\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"block").call(depth0 != null ? depth0 : (container.nullContext || {}),"fieldSettings",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":39,"column":10}}})) != null ? stack1 : "");
},"useData":true})