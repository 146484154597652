module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/campaigns/contact/?id="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"contactID") : depth0), depth0))
    + "\" class=\"campaign-contact-link mr-3\">\n    <span class=\"fa fa-id-card text-info\"></span>\n</a>";
},"useData":true})