<template>
	<Attachment
		:size="size"
		:filename="filename"
		:type="type"
		:uploading="true"
		:uploadProgress="progress"
		:uploadDone="done"
		:removable="true"
		:link="fileLink"
		:downloadLink="downloadLink"
		@remove="remove"
	></Attachment>
</template>

<script>
import { formatFileSize } from 'app/assets/js/util';
import { FileUpload } from 'BootQuery/Assets/js/file-upload';
import { basename } from 'app/assets/js/tsutil';
import Attachment from './Attachment.vue';

export default {
  props: {
    filename: String,
    type: String,
    size: Number,
    file: {},
  },
  components: {
    Attachment,
  },
  data() {
    return {
      done: !this.file,
      progress: this.file ? 0 : 100,
      uploadedInfo: null,
      uploader: null,
    };
  },
  created() {
    if (this.file && !this.done) {
      this.startUpload(this.file);
    }
  },
  filters: {
    formatFileSize,
  },
  methods: {
    startUpload(file) {
      this.uploader = new FileUpload(file);
      this.uploader.events.on('progress', (progress) => {
        this.progress = progress;
      });
      this.uploader.start().then((uploadId) => {
        this.done = true;
        this.uploadedInfo = {
          uploadId,
          name: basename(file.name),
        };
        this.$emit('done', this.uploadedInfo);
      });
    },
    remove() {
      if (this.uploader) {
        this.uploader.cancel();
        this.uploader = null;
      }
      this.$emit('remove');
    },
  },
  computed: {
    fileLink() {
      if (this.done && this.uploadedInfo) {
        const { uploadId, name } = this.uploadedInfo;
        return `/api/uploads/${uploadId}?name=${name}`;
      }
      return null;
    },
    downloadLink() {
      if (this.fileLink) {
        return `${this.fileLink}&download=true`;
      }
      return null;
    },
  },
};
</script>
