import { ReactElement } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { RichText } from '@bq/components/RichText';

import { FieldValue } from '../../types';
import { RichtextFieldSettings } from './types';

type Props = FieldValue<RichtextFieldSettings>;

export const RichtextField = ({ id, settings }: Props): ReactElement => {
  const { control } = useFormContext();
  const { field } = useController({ control, name: id });

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{settings.name}:</FormLabel>
      <RichText content={field.value} onChange={field.onChange} />
    </FormControl>
  );
};
