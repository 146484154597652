import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { ModalBody, ModalFooter } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { FilterTypes } from '../../FilterBar/types';
import { FormActions } from '../../FormActions';
import { lazyImport } from '../../lazy-import';
import { IOverviewEditorItem, OverviewGetter, OverviewList } from '../types';
import { addId, removeId } from './util';

const OverviewEditor = lazyImport(
  () => import('./OverviewEditor'),
  'OverviewEditor'
);

export interface OverviewEditorModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  filterTypes: FilterTypes;
  value: IOverviewEditorItem[] | OverviewGetter;
  onChange: (value: IOverviewEditorItem[]) => void;
  withGroupVisibility?: boolean;
}

export interface EditorContentHandle {
  cancel: () => void;
  submit: () => void;
}

const EditorContentComponent: ForwardRefRenderFunction<
  EditorContentHandle,
  OverviewEditorModalContentProps
> = (
  { onClose, filterTypes, value: inValue, onChange, withGroupVisibility },
  ref
) => {
  const getOverviews = useCallback(async () => {
    if (typeof inValue === 'function') {
      return inValue();
    }

    return inValue;
  }, [inValue]);
  const { data: value } = useQuery(
    ['overviewEditor', getOverviews],
    getOverviews,
    { suspense: true, keepPreviousData: false }
  );
  if (!value) {
    throw new Error('Expected values to get loaded through suspense');
  }

  // Store copy of overview in state variable, only update original copy on submit
  const [overviews, setOverviews] = useState<OverviewList>(value.map(addId));
  useEffect(() => {
    setOverviews(value.map(addId));
  }, [value]);

  const cancel = () => {
    onClose();
    setOverviews(value.map(addId)); // Reset filter values on cancel
  };
  const submit = () => {
    onChange(overviews.map(removeId)); // Submit changes on confirm
    onClose();
  };

  useImperativeHandle(ref, () => ({ submit, cancel }));

  return (
    <>
      <ModalBody px={0}>
        <OverviewEditor
          value={overviews}
          onChange={setOverviews}
          filterTypes={filterTypes}
          withGroupVisibility={withGroupVisibility}
        />
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        <FormActions onCancel={cancel} onSubmit={submit} />
      </ModalFooter>
    </>
  );
};
const OverviewEditorModalContent = forwardRef(EditorContentComponent);
OverviewEditorModalContent.displayName = 'OverviewEditorModalContent';

export { OverviewEditorModalContent };
