import { useMemo } from 'react';
import tinycolor from 'tinycolor2';

import { SortValue } from '../components/Table/types';
import { User } from './globalTypes';

export const parseSort = (sort: SortValue[]) => {
  return sort.map((item) => `${item.sortBy}:${item.direction}`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseID = (value: any): number => {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return value;
  }
  const parseToID = parseInt(value, 10);
  if (Number.isNaN(parseToID)) {
    return -1;
  }

  return parseToID;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseToNumber = (value: any): number | null => {
  const parseToNumber = parseInt(value, 10);
  if (Number.isNaN(parseToNumber)) {
    return null;
  }

  return parseToNumber;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseToFloat = (value: any): number | null => {
  const parseToNumber = parseFloat(value);
  if (Number.isNaN(parseToNumber)) {
    return null;
  }

  return parseToNumber;
};

export const listingHasNextPage = (
  pageParam: number,
  limit: number,
  count: number | null
) => {
  return pageParam * limit <= (count ?? 0);
};

export const compareIDs = (ID1: string | number, ID2: string | number) => {
  const pID1 = parseToNumber(ID1);
  const pID2 = parseToNumber(ID2);

  return pID1 !== null && pID2 !== null && pID1 === pID2;
};

export const QUERY_SELECTORS = [
  'a[href]',
  'input:not([type="hidden"]):not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'iframe',
  'audio[controls]',
  'video[controls]',
  'details[open]',
  '[tabindex]:not([tabindex="-1"])',
].join(', ');

export const findFirstFocusableChild = (
  elem: HTMLElement | null
): HTMLElement | null => {
  if (elem) {
    return elem.querySelector<HTMLElement>(QUERY_SELECTORS);
  }

  return null;
};

export const getUserName = (user: User) => {
  return user.person?.fullName
    ? `${user.person.fullName} (${user.username})`
    : user.username;
};

export const useReadableColor = (color?: string) => {
  const black = useMemo(() => tinycolor('#000000'), []);
  const white = useMemo(() => tinycolor('#FFFFFF'), []);
  const inputColor = useMemo(() => tinycolor(color), [color]);

  return useMemo(() => {
    const blackReadability = tinycolor.readability(black, inputColor);
    const whiteReadability = tinycolor.readability(white, inputColor);

    return blackReadability > whiteReadability ? 'black' : 'white';
  }, [black, white, inputColor]);
};
