import { useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';

import { ContactItem, ContactSelect } from '@bq/components/FormFields';

import { CaseSubject, CaseType } from '../../../../schemas';
import { useSubjectFieldAllowedTypes } from './use-subject-field-allowed-types';

interface Props {
  type?: CaseType;
  isDisabled?: boolean;
  value?: CaseSubject | null;
  onChange: (val: CaseSubject | null) => void;
  error?: FieldError;
}

export const SubjectFormField = ({
  onChange,
  type,
  error,
  value,
  isDisabled,
}: Props) => {
  const allowedTypes = useSubjectFieldAllowedTypes(type);

  const contactItemToVal = useCallback(
    (value: ContactItem | null): CaseSubject => {
      if (!value) {
        throw Error('Value is missing');
      }
      if ('$new' in value) {
        throw Error('Cant create new value');
      }
      if (value.type === 'person') {
        return { type: 'person', name: value.name, personID: value.ID };
      }
      if (value.type === 'company') {
        return { type: 'company', name: value.name, companyID: value.ID };
      }
      // TODO: DATASET
      throw Error('Unknown value type');
    },
    []
  );
  const valueToContactItem = useCallback(
    (value: CaseSubject | undefined | null): ContactItem | null => {
      if (!value) {
        return null;
      }
      if (value.type === 'company') {
        return { ID: value.companyID, name: value.name, type: 'company' };
      }
      if (value.type === 'person') {
        return { ID: value.personID, name: value.name, type: 'person' };
      }
      // TODO: DATASET
      throw Error('Unknown value type');
    },
    []
  );
  const val = useMemo(
    () => valueToContactItem(value),
    [value, valueToContactItem]
  );

  return (
    <ContactSelect
      hasDetails
      isDisabled={!type || isDisabled}
      size={{ sm: 'lg', md: 'lg', lg: 'sm' }}
      isMulti={false}
      onChange={(val) => {
        onChange(contactItemToVal(val));
      }}
      isInvalid={!!error?.message}
      value={val}
      allowedTypes={allowedTypes}
      createMode="none"
    />
  );
};
