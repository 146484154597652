module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dataset-import-progress-overlay\" data-import-uuid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"importUUID") : depth0), depth0))
    + "\">\n    <div>\n        <h1>"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"import.import_in_progress",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":46}}}))
    + "</h1>\n        <h2>\n            "
    + alias2(lookupProperty(helpers,"tr").call(alias3,"import.rows_imported",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":41}}}))
    + ": <span class=\"import-progress-counter\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rows") : depth0), depth0))
    + "</span>\n        </h2>\n    </div>\n</div>";
},"useData":true})