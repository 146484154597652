<template>
	<div class="html-message-content" ref="htmlContent" v-html="processedHtml"></div>
</template>

<script>
import linkifyHtml from 'linkify-html';

function constructButtonEl() {
  return document.createRange().createContextualFragment(`
        <button type="button" class="btn btn-outline-primary btn-sm" style="line-height: 1;">
            <span class="fa fa-ellipsis-h"></span>
        </button>
    `);
}

export default {
  props: {
    html: String,
  },
  computed: {
    processedHtml() {
      return linkifyHtml(this.html);
    },
  },
  mounted() {
    const rootEl = this.$refs.htmlContent;
    const nodes = rootEl.querySelectorAll('.clickvox-quote-wrapper');
    nodes.forEach((el) => {
      const btnEl = constructButtonEl();
      btnEl.firstElementChild.addEventListener('click', (ev) => {
        ev.preventDefault();
        $(el).collapse('toggle');
      });
      el.parentNode.insertBefore(btnEl, el);
      el.classList.add('collapse');
      $(el).collapse('hide');
    });
  },
};
</script>

<style lang="scss">
.html-message-content {
	overflow: hidden;

	img {
		max-width: 100%;
	}
}
</style>
