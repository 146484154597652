import { z } from 'zod';

export const reminderSchema = z.object({
  ID: z.number(),
  title: z.string(),
  timestamp: z.string().datetime(),
  description: z.string(),
  done: z.boolean(),
});

export type Reminder = z.infer<typeof reminderSchema>;

export const reminderFormSchema = reminderSchema
  .omit({ ID: true })
  .extend({ ID: z.union([z.string(), z.number()]) });

export type FormReminder = z.infer<typeof reminderFormSchema>;

export const remindersCrud = z.object({
  add: z.array(reminderFormSchema),
  upd: z.record(reminderSchema.shape.ID, reminderFormSchema),
  del: z.array(reminderSchema.shape.ID),
});

export const remindersCrudWithDefaults = remindersCrud.extend({
  defaults: z.array(reminderSchema),
});
