import { useQuery } from '@tanstack/react-query';
import { DefinitionType, getCustomFormDefinition } from './api';
import { FieldValue, QueryOptions } from './types';

export function useFormDefinition(
  name: string,
  mode: DefinitionType = 'use',
  options: QueryOptions = {}
): FieldValue[] | null {
  const { data: definition } = useQuery(
    [`useCustomFormDefinition:${name}:${mode}`],
    () => getCustomFormDefinition(name, mode),
    options
  );

  return definition?.data ?? null;
}
