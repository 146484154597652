import { ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { MobileOverviewWrapper } from '@bq/components/TreeOverviews/MobileOverviewsWrapper';
import { useGetQueryParams } from '@bq/components/use-get-query-params';
import { PhonebookFormsProvider } from 'app/Modules/Phonebook/Assets/components/ModalForms';
import { useFormDefinition } from 'BootQuery/Assets/components/FormEditor';
import { ListingGrid } from 'BootQuery/Assets/components/ListingGrid/ListingGrid';
import { ListingGridItem } from 'BootQuery/Assets/components/ListingGrid/ListingGridItem';
import { LoaderScale } from 'BootQuery/Assets/components/Loader';
import { Sidebar } from 'BootQuery/Assets/components/Sidebar';
import { TreeOverviews } from 'BootQuery/Assets/components/TreeOverviews/TreeOverviews';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { useOverviews } from '../Api/overviews';
import { callListPermissions } from './call-list-permissions';
import { CallListContent } from './CallListContent';
import { CallListSettingsProvider } from './CallListSettingsContext';
import { useLoadCallListSettings } from './use-load-call-list-settings';
import { useSelectedOverview } from './use-selected-overview';

export const CallList = (): ReactElement => {
  const customFields = useFormDefinition('Telephony.callForm');

  const { data: overviews } = useOverviews({
    customFields,
    enabled: !!customFields,
  });
  const settingsLoaded = useLoadUserSettings('Telephony.CallList', {});
  const { data: callListSettings } = useLoadCallListSettings();

  const { data: permissions } = useQuery(
    ['callListPermissions'],
    callListPermissions,
    { refetchOnWindowFocus: false }
  );
  const selectedOverview = useSelectedOverview(overviews ?? []);
  const getQueryParams = useGetQueryParams();

  if (
    !customFields ||
    !permissions ||
    !overviews ||
    !settingsLoaded ||
    !callListSettings
  ) {
    return (
      <Flex justifyContent="center" py="3">
        <LoaderScale height={32} />
      </Flex>
    );
  }

  return (
    <PhonebookFormsProvider>
      <ListingGrid>
        <ListingGridItem minWidth={250}>
          <Sidebar>
            {overviews && (
              <MobileOverviewWrapper>
                <TreeOverviews
                  getQueryParams={getQueryParams}
                  selectedOverview={selectedOverview?.slug}
                  overviews={overviews}
                  path="telephony/calls"
                />
              </MobileOverviewWrapper>
            )}
          </Sidebar>
        </ListingGridItem>

        <ListingGridItem initialWidth="6fr">
          <Box>
            <CallListSettingsProvider {...callListSettings}>
              <CallListContent
                permissions={permissions}
                overviews={overviews}
                customFields={customFields}
              />
            </CallListSettingsProvider>
          </Box>
        </ListingGridItem>
      </ListingGrid>
    </PhonebookFormsProvider>
  );
};
