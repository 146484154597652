<template>
	<span>
		<strong v-if="addresses">{{ label }}: </strong>
		<template v-for="(address, index) in visibleAddresses">
			<a
				:key="address.address"
				:href="`mailto:${address.address}`"
			>{{ address.address }}</a><template v-if="index < visibleAddresses.length - 1">, </template>
		</template>
		<button
			type="button"
			class="btn btn-sm btn-secondary text-nowrap py-0 px-1 ml-1"
			@click="expanded = true"
			v-if="hasManyMails && !expanded"
		>
			{{ tr('Mails:button.show_all_addresses') }} (+{{addresses.length - 8}})
			<span class="fas fa-eye"></span>
		</button>
		<button
			type="button"
			class="btn btn-sm btn-secondary text-nowrap py-0 px-1 ml-1"
			@click="expanded = false"
			v-if="hasManyMails && expanded"
		>
			{{ tr('Mails:button.hide_all_addresses') }}
			<span class="fas fa-eye-slash"></span>
		</button>
	</span>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    };
  },
  props: {
    addresses: Array,
    label: String,
  },
  computed: {
    hasManyMails() {
      return this.addresses.length > 8;
    },
    visibleAddresses() {
      if (this.hasManyMails && !this.expanded) {
        return this.addresses.slice(0, 8);
      }

      return this.addresses;
    },
  },
};
</script>
