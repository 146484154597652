import { z } from 'zod';

import { caseSubjectTypeSchema } from './CaseType';

const baseCaseSubjectSchema = z.object({
  type: caseSubjectTypeSchema,
  name: z.string(),
});

export const personCaseSubjectSchema = baseCaseSubjectSchema.extend({
  type: z.literal('person'),
  personID: z.number(),
});

export type PersonCaseSubject = z.infer<typeof personCaseSubjectSchema>;

export const companyCaseSubjectSchema = baseCaseSubjectSchema.extend({
  type: z.literal('company'),
  companyID: z.number(),
});

export type CompanyCaseSubject = z.infer<typeof companyCaseSubjectSchema>;

export const datasetCaseSubjectSchema = baseCaseSubjectSchema.extend({
  type: z.literal('dataset'),
  datasetDataID: z.number(),
});

export type DatasetCaseSubject = z.infer<typeof datasetCaseSubjectSchema>;

export const caseSubjectSchema = z.union([
  personCaseSubjectSchema,
  companyCaseSubjectSchema,
  datasetCaseSubjectSchema,
]);

export type CaseSubject = z.infer<typeof caseSubjectSchema>;
