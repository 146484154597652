<template>
  <div class="event-list">
       <div v-if="loading" class="d-flex justify-content-center">
           <span class="spinner-grow spinner-grow-lg"></span>
       </div>
       <template v-else>
           <component
                v-for="event in eventList"
                :key="`${event.type}_${event.id}`"
                :is="event.component"
                :data="event.data"
            />
       </template>
  </div>
</template>

<script>
import CallEvent from './CallEvent.vue';
import MailEvent from './MailEvent.vue';
import MailEditor from './MailEditor.vue';
import SmsEvent from './SmsEvent.vue';
import SmsEditor from './SmsEditor.vue';

const eventTypeComponents = {
  call: CallEvent,
  mail: MailEvent,
  mailEditor: MailEditor,
  sms: SmsEvent,
  smsEditor: SmsEditor,
};

export default {
  data() {
    return {};
  },
  computed: {
    loading() {
      return this.state.events === null;
    },
    eventList() {
      return this.state.events.map((event) => ({
        ...event,
        component: eventTypeComponents[event.type],
      }));
    },
    state() {
      return this.module.contactEventsState;
    },
    module() {
      return window.BootQuery.getModuleInstance('Campaigns');
    },
  },
};
</script>
