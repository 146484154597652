import 'BootQuery/Assets/js/i18n';

import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

import { DatasetCreate } from '../pages/DatasetCreate';
import { DatasetEdit } from '../pages/DatasetEdit';
import { DatasetList } from '../pages/DatasetList';
import { DatasetView } from '../pages/DatasetView';

export const ReactRoutes: ReactRoute[] = [
  { path: '/datasets/list', component: DatasetList },
  { path: '/datasets/dataset/:datasetID', component: DatasetView },
  { path: '/datasets/dataset/:datasetID/edit', component: DatasetEdit },
  { path: '/datasets/create/*', component: DatasetCreate },
];
