import { useMemo } from 'react';
import { Column, OBJ } from 'BootQuery/Assets/js/globalTypes';
import { customFormColumns, getFieldTypes } from '../FormEditor';
import { FieldValue } from '../FormEditor/types';

export function useCustomColumns<T extends OBJ>(
  customFields: FieldValue[] = []
): Column<T>[] {
  return useMemo(() => {
    const customColumns = customFormColumns(
      customFields,
      getFieldTypes()
    ) as unknown as Column<T>[];

    return customColumns;
  }, [customFields]);
}

export function useLoadColumns<T extends OBJ>(
  columns: (() => Column<T>[]) | Column<T>[]
): Column<T>[] {
  return useMemo(() => {
    const columnsF = typeof columns === 'function' ? columns() : columns;

    return columnsF;
  }, [columns]);
}

type FeatureName = string;

export const useSplitColumnsFeatures = <
  RowType extends OBJ,
  Features extends OBJ
>(
    columns: Column<RowType>[],
    features: { [columnKey: Column<RowType>['key']]: FeatureName }
  ) => {
  return useMemo(() => {
    return {
      /** Remove found */
      columns: columns.filter((column) => features[column.key] === undefined),
      /** Check if in list */
      features: Object.entries(features).reduce((all, current) => {
        const isInColumns = columns.some((column) => column.key === current[0]);

        return { ...all, [current[1]]: isInColumns };
      }, {} as Features),
    };
  }, [columns, features]);
};
