<template>
	<div
		class="card"
		:class="[cardClass, { 'mail-collapsed': collapsedVal }]"
		:id="`msg${ID}`"
		@click="collapsedVal = false"
	>
		<div
			class="px-3 py-2 w-100 card-block message-header"
			:class="{'message-header-stick-top': stickyHeader}"
			ref="header"
		>
			<slot name="header">
				<div>
					<div class="d-block d-md-flex w-100">
						<span class="flex-grow-1">
							<slot name="title" v-bind="$props">
								<strong class="message-title">
									<span
										v-if="isHighPriority"
										class="fas fa-exclamation text-danger"
									></span>
									{{ subject }}
								</strong>
							</slot>
						</span>
						<span class="d-inline-flex flex-shrink-1">
							<span class="text-muted text-nowrap">
								{{ date | formatDate(tr('format.datetime')) }}
							</span>
							<div class="dropdown">
								<a href="#" class="px-2 ml-3 text-dark float-right" data-toggle="dropdown">
									<span class="fa fa-ellipsis-v"></span>
								</a>
								<div class="dropdown-menu dropdown-menu-right">
									<a
										@click.prevent="print"
										target="_blank"
										:href="printURL"
										class="dropdown-item"
									>
										<span class="fas fa-print"></span> {{ tr('Mails:button.print') }}
									</a>
									<a
										v-if="downloadable"
										:href="`/api/mails/messages/${ID}/download`"
										download
										class="dropdown-item"
									>
										<span class="fas fa-download"></span> {{ tr('Mails:button.download') }}
									</a>
									<slot name="actions-menu-after"></slot>
								</div>
							</div>
						</span>
					</div>

					<strong>{{ tr('Mails:label.address_from') }}:</strong>
					<a v-if="fromMailAddress" :href="`mailto:${fromMailAddress}`">
						<strong v-if="fromMailAddressName">{{ fromMailAddressName }}</strong>
						<span v-else>{{fromMailAddress }}</span>
					</a>
					<strong v-else title="Missing From address.">
						{{fromMailAddressName || '[Missing from address]'}}
					</strong>
					<br />
					<AddressList :label="tr('Mails:label.address_to')" :addresses="to" />
					<br v-if="cc.length" />
					<AddressList
						v-if="cc.length && !collapsedVal"
						:label="tr('Mails:label.address_cc')"
						:addresses="cc"
					/>
					<br v-if="bcc.length" />
					<AddressList
						v-if="bcc.length && !collapsedVal"
						:label="tr('Mails:label.address_bcc')"
						:addresses="bcc"
					/>
				</div>
			</slot>
		</div>
		<div
			class="card-body"
			:class="htmlBody ? 'html-message-card-body' : 'text-message-card-body'"
			ref="body"
		>
			<slot name="content" v-bind="$props">
				<template v-if="!collapsedVal">
					<HTMLMessage v-if="htmlBody" :html="htmlBody" />
					<TextMessage v-else :text="textBody" />
				</template>
				<pre v-else>{{ previewTextBody }}</pre>
			</slot>
		</div>

		<MessageAttachments
			v-if="attachments.length && !collapsedVal"
			:attachments="attachments"
			:messageID="String(ID)"
		/>

		<div class="card-footer message-footer px-3 py-2" v-if="!collapsedVal">
			<slot name="footer">
				<div class="d-flex message-actions">
					<div v-if="!sentVal" class="d-flex" style="align-items: center;">
						<span class="spinner-border spinner-border-sm text-primary"></span>
						&nbsp; Sending...
					</div>

					<div class="ml-auto">
						<button
							type="button"
							class="btn btn-primary mail-action"
							@click.prevent="replySender"
							:disabled="!fromMailAddress"
						>
							<span class="fas fa-reply"></span>
							<span class="d-none d-md-inline">{{ tr('Mails:button.reply') }}</span>
						</button>

						<button
							type="button"
							class="btn btn-primary mail-action"
							@click.prevent="replyAll"
							:disabled="!fromMailAddress"
						>
							<span class="fas fa-reply-all"></span>
							<span class="d-none d-md-inline">{{ tr('Mails:button.reply_all') }}</span>
						</button>

						<button
							type="button"
							class="btn btn-secondary mail-action"
							@click.prevent="forward"
						>
							<span class="fas fa-arrow-right"></span>
							<span class="d-none d-md-inline">{{ tr('Mails:button.forward') }}</span>
						</button>
					</div>
				</div>
			</slot>
		</div>
		<div v-if="collapsedVal" class="mail-collapsed-overlay">
			<div class="mail-collapsed-overlay-content">
				<button class="btn btn-primary">
					Pokaži
					<span class="fas fa-arrow-down"></span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import * as Api from 'BootQuery/Assets/js/apiRequest';
import AddressList from './AddressList.vue';
import HTMLMessage from './HTMLMessage.vue';
import TextMessage from './TextMessage.vue';
import MessageAttachments from './MessageAttachments.vue';

export default {
  components: {
    AddressList,
    HTMLMessage,
    TextMessage,
    MessageAttachments,
  },
  props: {
    ID: Number,
    subject: String,
    date: Date,
    fromMailAddress: String,
    fromMailAddressName: String,
    to: Array,
    cc: Array,
    bcc: Array,
    htmlBody: String,
    textBody: String,
    attachments: Array,
    priority: Number,
    cardClass: String,
    sent: Boolean,
    collapsed: Boolean,
    downloadable: Boolean,
  },
  data() {
    return {
      sentListener: null,
      sentVal: this.sent,
      collapsedVal: this.collapsed,
      stickyHeader: true,
    };
  },
  mounted() {
    this.onHeaderOrBodyHeightChange();

    this.$nextTick().then(() => {
      this.mutationObserver = new MutationObserver(() => {
        this.onHeaderOrBodyHeightChange();
      });

      const observerConfig = { subtree: true, characterData: true, attributes: true };
      this.mutationObserver.observe(this.$el, observerConfig);
    });
  },
  methods: {
    replyAll() {
      this.$emit('reply', 'all');
    },
    replySender() {
      this.$emit('reply', 'sender');
    },
    forward() {
      this.$emit('forward');
    },
    print() {
      const iframe = document.createElement('iframe');
      iframe.src = this.printURL;
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframe.contentWindow.addEventListener('afterprint', () => {
        document.body.removeChild(iframe);
      });
      iframe.contentWindow.addEventListener('load', () => {
        iframe.contentWindow.print();
      });
    },
    onSent(info) {
      if (info.ID === this.ID) {
        this.sentVal = true;
      }
    },
    onHeaderOrBodyHeightChange() {
      const headerHeight = this.$refs.header.getBoundingClientRect().height;
      const bodyHeight = this.$refs.body.getBoundingClientRect().height;

      if (headerHeight >= bodyHeight) {
        if (this.stickyHeader) {
          this.stickyHeader = false;
        }
      } else if (!this.stickyHeader) {
        this.stickyHeader = true;
      }
    },
  },
  computed: {
    isHighPriority() {
      return this.priority < 3;
    },
    printURL() {
      return `/api/mails/message/${this.ID}/print`;
    },
    mailsModule() {
      return window.BootQuery.getModuleInstance('Mails');
    },
    previewTextBody() {
      return (this.textBody || '').substr(0, 128);
    },
  },
  watch: {
    sentVal: {
      immediate: true,
      handler(sent) {
        if (!sent) {
          if (!this.sentListener) {
            this.sentListener = this.onSent.bind(this);
            this.mailsModule.addListener(
              'messageSent',
              this.sentListener
            );

            // Re-check status in case we're too late to get the event
            Api.get(`/api/mails/message/${this.ID}/sent`).then((sent) => {
              if (sent === true) {
                this.sentVal = true;
              }
            });
          }
        } else if (this.sentListener) {
          this.mailsModule.removeListener(
            'messageSent',
            this.sentListener
          );
          this.sentListener = null;
        }
      },
    },
  },
  $beforeDestroy() {
    if (this.sentListener) {
      this.mailsModule.removeListener('messageSent', this.sentListener);
      this.sentListener = null;
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
      this.mutationObserver = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.message-header {
	background: var(--light);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.message-header-stick-top {
	z-index: 100;
	position: sticky;
	top: 0;
}

.message-title {
	font-size: 1.125rem;
}

.html-message-card-body {
	background: #ffffff;
	color: #212529;
}

.mail-collapsed {
	cursor: pointer;
	opacity: 0.9;
	& > * {
		pointer-events: none;
	}

	> .card-body {
		height: 90px;
		overflow: hidden;
	}
}

.mail-collapsed-overlay {
	position: absolute;
	z-index: 9999;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: linear-gradient(
		to bottom,
		rgba(100, 100, 100, 0.4) 0%,
		rgba(100, 100, 100, 0) 100%
	);

	display: flex;
	> .mail-collapsed-overlay-content {
		display: flex;
		align-self: center;
		margin: 0 auto;
	}
}
.mail-collapsed:hover .mail-collapsed-overlay {
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.66) 0%,
		rgba(0, 0, 0, 0) 100%
	);
}
</style>
