<template>
	<div class="form-group row">
		<slot name="label">
			<label
				class="col-form-label"
				:class="labelSizeClasses"
				v-if="label && label.length"
				:for="fieldId"
				>{{ label }}:</label
			>
		</slot>
		<slot name="control">
			<div :class="inputSizeClasses">
				<VueSelect
					ref="input"
					:class="{'is-invalid': hasError}"
					v-model="inputValue"
					:placeholder="placeholder"
					:disabled="disabled"
					multiple
					@search="search"
					label="email"
					:filterable="false"
					:taggable="false"
					:clearable="true"
					:inputId="fieldId"
					:options="suggestions"
					:selectOnKeyCodes="[13, 9]"
					:selectOnTab="true"
					:closeOnSelect="false"
				>
					<template
						v-for="optionSlot in ['option', 'selected-option']"
						v-slot:[optionSlot]="{email, name, icon}"
					>
						<span :key="optionSlot">
							<strong class="px-2">
								<span v-if="icon" :class="icon"></span>
								{{name || email}}
							</strong>
							<span v-if="name" :style="{opacity: 0.7}">
								&lt;{{email}}&gt;
							</span>
						</span>
					</template>
				</VueSelect>
			</div>
		</slot>
	</div>
</template>

<script>
import VueSelect from 'vue-select';
import fieldProps from 'BootQuery/Assets/js/forms/fields/mixins/fieldProps';
import { Api } from 'BootQuery/Assets/js/api';
import { isEqual } from 'lodash-es';

const REGEX_EMAIL =	'([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)';

function matchMailFromString(str) {
  str = str.trim();
  if (str.length === 0) {
    return null;
  }

  if (new RegExp(`^${REGEX_EMAIL}$`, 'i').test(str)) {
    return { email: str, name: null };
  }
  const match = str.match(new RegExp(`^([^<]*)<${REGEX_EMAIL}>$`, 'i'));
  if (match) {
    return {
      email: match[2],
      name: match[1].trim(),
    };
  }

  return null;
}

const iconMap = {
  person: 'far fa-id-card',
  company: 'far fa-building',
};

export default {
  mixins: [fieldProps],
  components: {
    VueSelect,
  },
  props: {
    value: Array,
    placeholder: String,
  },
  data() {
    return {
      lastSearch: '',
      isSearching: false,
      inputValue: this.value ? [...this.value] : [],
      suggestions: [],
    };
  },
  methods: {
    async search(inSearch) {
      const search = this.parseSearchInput(inSearch);
      this.lastSearch = search;

      if (search !== inSearch) {
        const selectSearchEl = 	this.$refs.input.searchEl;
        selectSearchEl.value = search;
        selectSearchEl.dispatchEvent(new Event('input'));
      }

      if (this.isSearching) {
        return;
      }
      if (!search || !search.length) {
        this.isSearching = false;
        this.suggestions = [];
        return;
      }

      this.isSearching = true;

      const { data: results } = await Api.get('/api/mails/autocompleteAddresses', { params: { search } });
      // thingID is here to guaarantee unique keys.
      this.suggestions = results.map(({
        name, email, thingType, thingID,
      }) => ({
        thingID,
        name,
        email,
        icon: iconMap[thingType] || null,
      }));

      const inputMail = matchMailFromString(search);
      if (inputMail) {
        this.suggestions.unshift(inputMail);
      }

      this.isSearching = false;

      // Search again if search string changed while searching
      if (this.lastSearch !== search) {
        this.search(this.lastSearch);
      }
    },
    parseSearchInput(input) {
      if (!input) {
        return input;
      }

      const parts = input.trim().split(/[,;]/);
      if (parts.length === 1) {
        return input;
      }

      const mailParts = [];
      const otherParts = [];
      parts.forEach((part) => {
        if (part.trim().length === 0) {
          return;
        }

        const mail = matchMailFromString(part);
        if (mail) {
          mailParts.push(mail);
        } else {
          otherParts.push(part);
        }
      });

      if (mailParts.length > 0) {
        this.inputValue = [...this.inputValue, ...mailParts];
        return otherParts.join(',');
      }

      return input;
    },
  },
  watch: {
    value(newVal) {
      if (!isEqual(this.inputValue, newVal)) {
        this.inputValue = newVal;
      }
    },
    inputValue(newVal, prevVal) {
      if (!isEqual(prevVal, newVal)) {
        this.$emit('input', newVal);
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .vs__no-options {
	display: none;
}
</style>
