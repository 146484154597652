module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":38,"column":12}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"card embedable\">\n		<div class=\"card-header stick-top\" id=\"campaigns-add-to-campaign-header\">\n			<span class=\"fa fa-plus\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.add_contact_to_campaign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":79}}}))
    + "\n		</div>\n\n		<div class=\"card-body\">\n			<form id=\"addContactToCampaign\" class=\"form-vertical\" action=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"form_url")), depth0))
    + "\" method=\"post\" role=\"form\" data-form=\"addContactToCampaign\">\n				<div id=\"campaigns-addContactToCampaign-form-container\">\n					<input type=\"hidden\" name=\"action\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"form_action")), depth0))
    + "\" />\n					<input type=\"hidden\" name=\"contactID\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"contactID")), depth0))
    + "\" />\n					"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),((stack1 = (depth0 != null ? lookupProperty(depth0,"forms") : depth0)) != null ? lookupProperty(stack1,"addContactToCampaign") : stack1),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "				</div>\n			</form>\n		</div>\n\n		<div class=\"card-footer stick-bottom d-flex\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"isModal")),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":3},"end":{"line":23,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"isModal")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":3},"end":{"line":28,"column":10}}})) != null ? stack1 : "")
    + "			<div class=\"save-button\">\n				<span class=\"save-status\" hidden style=\"padding: 7px 15px;\" data-form=\"addContactToCampaign\">\n				</span>\n				<button type=\"submit\" class=\"btn btn-success\" form=\"addContactToCampaign\" data-form=\"addContactToCampaign\">\n					<span class=\"fa fa-check\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":33,"column":44},"end":{"line":33,"column":65}}}))
    + "\n				</button>\n			</div>\n		</div>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<a href=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"list_url")), depth0))
    + "\" class=\"btn btn-link form-cancel mr-auto\">\n				<span class=\"fa fa-arrow-left\"></span>&nbsp;"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.back",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":21,"column":48},"end":{"line":21,"column":69}}}))
    + "\n			</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<a href=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"list_url")), depth0))
    + "\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n				<span class=\"fa fa-times\"></span>&nbsp;"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":26,"column":43},"end":{"line":26,"column":66}}}))
    + "\n			</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":39,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true})