import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { AsyncCreatableSelect, AsyncSelect } from 'chakra-react-select';
import { get } from 'lodash-es';
import {
  FieldError,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectOption } from 'BootQuery/Assets/components/Select';
import { DropdownCreateLabel } from 'BootQuery/Assets/components/Select/DropdownCreateLabel';
import { modalSelect } from 'BootQuery/Assets/components/Select/select-styles';
import { Api } from 'BootQuery/Assets/js/api';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { FieldValue } from '../../types';
import {
  CustomSelectFieldItem,
  SelectFieldSettings,
  SelectSettings,
} from './types';
import { usePrefixedSelects } from './util';

type Props = FieldValue<SelectFieldSettings>;

export const SelectField = ({ id, settings }: Props): ReactElement => {
  const prefixedSelects = usePrefixedSelects(id, settings.selects);
  const fieldSettings = useMemo(
    () => ({
      ...settings,
      selects: prefixedSelects,
    }),
    [settings, prefixedSelects]
  );

  return (
    <>
      {prefixedSelects.map((select, idx) => (
        <CustomSelectInputWrapper
          key={select.id}
          selectIdx={idx}
          fieldSettings={fieldSettings}
          select={select}
        />
      ))}
    </>
  );
};

type DropdownValue = CustomSelectFieldItem | { $new: string };

function selectItemToValue(option: SelectOption | null): DropdownValue | null {
  if (!option) {
    return null;
  }

  // eslint-disable-next-line camelcase
  // eslint-disable-next-line no-underscore-dangle
  return option.__isNew__
    ? { $new: option.label }
    : { key: option.value, name: option.label };
}

function valueToSelectItem(val: DropdownValue | null): SelectOption | null {
  if (!val) {
    return null;
  }

  if ('$new' in val) {
    return { label: val.$new, value: val.$new, __isNew__: true };
  }

  return { label: val.name, value: val.key };
}

function formatCreateLabel(label: string): ReactNode {
  return DropdownCreateLabel({ label });
}

function itemToSelectOption({
  key,
  name,
}: CustomSelectFieldItem): SelectOption {
  return {
    label: name,
    value: key,
  };
}

async function seachItems(
  listID: number,
  search: string,
  parentItem: string | null = null
): Promise<SelectOption[]> {
  const filters: Record<string, unknown> = {};
  if (search) {
    filters.$search = search;
  }
  if (parentItem) {
    filters['parentItem.key'] = parentItem;
  }

  const { data: items } = await Api.get<CustomSelectFieldItem[]>(
    `/api/forms/customLists/${listID}/items`,
    {
      params: { filters, fields: ['ID', 'name', 'key'] },
    }
  );

  return items.map(itemToSelectOption);
}

interface SelectItem {
  key: string;
  name: string;
}

interface InputProps {
  fieldSettings: SelectFieldSettings;
  select: SelectSettings;
  selectIdx: number;
  parentSelect?: SelectSettings;
  parentValue?: SelectItem;
}

interface SubSelectInputProps extends InputProps {
  parentSelect: SelectSettings;
}

export const CustomSelectInput = ({
  fieldSettings,
  select,
  parentSelect,
  parentValue,
}: InputProps): ReactElement => {
  const { t } = useTranslation('global');
  const { control } = useFormContext();

  const { required, canCreateNew = true } = fieldSettings;

  const validate = useCallback(
    (val: unknown) => {
      if (required && !val) {
        return t('global:form_editor.required');
      }

      return true;
    },
    [required, t]
  );
  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name: select.id,
    rules: { validate },
  });
  const hasParent = !!parentSelect;
  const parentKey = parentValue?.key;
  const disabled = hasParent && !parentKey;
  const ownError = get(errors, select.id) as FieldError | undefined;
  const loadOptions = useCallback(
    (search: string) => {
      if (!select.listID) {
        throw new Error(`Select ${select.id} is missing listID`);
      }

      if (disabled) {
        return Promise.resolve([]);
      }

      return seachItems(select.listID, search, parentKey);
    },
    [select.id, select.listID, disabled, parentKey]
  );

  // Clear value when disabled
  useEffect(() => {
    if (disabled) {
      field.onChange(null);
    }
  }, [disabled, field]);

  // Clear value on parent change
  useChangeEffect(
    parentKey,
    () => {
      field.onChange(null);
    },
    [field]
  );

  const SelectComponent = canCreateNew ? AsyncCreatableSelect : AsyncSelect;

  return (
    <FormControl isInvalid={!!ownError}>
      <FormLabel fontWeight="bold">{select.name}:</FormLabel>
      <SelectComponent
        defaultOptions
        loadOptions={loadOptions}
        key={`${select.listID}-${parentKey ?? ''}`}
        isClearable={!fieldSettings.required}
        isDisabled={disabled}
        value={valueToSelectItem(field.value)}
        menuPortalTarget={document.body}
        onChange={(val: SelectOption | null) => {
          field.onChange(selectItemToValue(val));
        }}
        formatCreateLabel={formatCreateLabel}
        placeholder={select.name}
        styles={modalSelect}
        selectedOptionColorScheme="brand"
        isInvalid={!!ownError}
      />
      {ownError && (
        <FormErrorMessage>{ownError.message || ownError.type}</FormErrorMessage>
      )}
    </FormControl>
  );
};

const CustomSelectSubInput = (props: SubSelectInputProps): ReactElement => {
  const { control } = useFormContext();

  const parentValue = useWatch({
    control,

    name: props.parentSelect.id,
  });

  return <CustomSelectInput {...props} parentValue={parentValue} />;
};

const CustomSelectInputWrapper = (props: InputProps): ReactElement => {
  const { fieldSettings, selectIdx } = props;

  const parentSelect =
    selectIdx > 0 ? fieldSettings.selects[selectIdx - 1] : null;

  return parentSelect ? (
    <CustomSelectSubInput {...props} parentSelect={parentSelect} />
  ) : (
    <CustomSelectInput {...props} />
  );
};
