import { AsyncSelect, Select, SingleValue } from 'chakra-react-select';
// eslint-disable-next-line import/no-unresolved
import { SizeProp, Variant } from 'chakra-react-select/dist/types/types';
import { useQuery } from '@tanstack/react-query';
import { getState } from '../../Api/state/state';
import { TicketType } from '../../types';
import { TicketOption } from './TicketOption';
import { TicketValue } from './TicketValue';
import { TicketStateSelectOption } from './types';
import { intersectTypes, loadOptions } from './utils';

interface Props {
  variant?: Variant;
  type?: TicketType[] | TicketType;
  onChange: (newValue: SingleValue<TicketStateSelectOption>) => void;
  value: TicketStateSelectOption | number | null;
  inputSize?: SizeProp;
  isDisabled?: boolean;
  isClearable?: boolean;
}
export const TicketStateSelect = ({
  onChange,
  value,
  type,
  variant = 'outline',
  inputSize,
  isDisabled,
  isClearable = false,
}: Props) => {
  const valueIsNumber = typeof value === 'number';
  const typeIsArray = Array.isArray(type);

  const { data: loadedData } = useQuery(
    ['agentSelectLoadValueFromID', value, type],
    async () => {
      if (valueIsNumber) {
        const { data } = await getState(value);

        return {
          label: data.name,
          value: data.ID,
          color: data.color,
        } as TicketStateSelectOption;
      }
      throw Error('Unkown error');
    },
    { enabled: valueIsNumber }
  );
  if (typeIsArray) {
    return (
      <Select<TicketStateSelectOption>
        isDisabled={isDisabled}
        isClearable={isClearable}
        variant={variant}
        useBasicStyles
        size={inputSize || { base: 'lg', md: 'sm', lg: 'sm' }}
        value={valueIsNumber ? loadedData : value}
        components={{ Option: TicketOption, SingleValue: TicketValue }}
        isSearchable
        onChange={(newVal) => {
          onChange(newVal);
        }}
        options={intersectTypes(type)}
        selectedOptionColorScheme="brand"
      />
    );
  }

  return (
    <AsyncSelect<TicketStateSelectOption>
      isClearable={isClearable}
      useBasicStyles
      isDisabled={isDisabled}
      variant={variant}
      size={
        inputSize !== undefined
          ? { base: 'lg', md: 'sm', lg: 'md' }
          : { base: 'lg', md: 'sm', lg: 'sm' }
      }
      value={valueIsNumber ? loadedData : value}
      components={{ Option: TicketOption, SingleValue: TicketValue }}
      cacheOptions
      defaultOptions
      onChange={(newVal) => {
        onChange(newVal);
      }}
      loadOptions={(search) => {
        return loadOptions(search, type?.ID);
      }}
      selectedOptionColorScheme="brand"
    />
  );
};
