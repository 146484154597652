module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":18,"column":12}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card\">\n    <div class=\"card-body justify-content-center\">\n        <h1 class=\"text-center\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.no_more_contacts",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":63}}}))
    + "</h1>\n        <div class=\"d-flex justify-content-center m-3\">\n            <a href=\"/campaigns/campaign/?id="
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"campaignID") : stack1), depth0))
    + "\" class=\"btn btn-lg btn-primary mx-1\">\n                <span class=\"fas fa-list\"></span>\n                "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.back_to_list",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":44}}}))
    + "\n            </a>\n            <a href=\"/campaigns/logout/?campaign="
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"campaignID") : stack1), depth0))
    + "&agent="
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"agentID") : stack1), depth0))
    + "\" class=\"btn btn-lg btn-danger mx-1\">\n                <span class=\"fa fa-sign-out-alt\"></span>\n                "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.logout",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":38}}}))
    + "\n            </a>\n        </div>\n    </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":19,"column":11}}})) != null ? stack1 : "");
},"useData":true})