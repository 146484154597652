<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="card-title">
        <span class="fas fa-sms"></span>
        {{ tr('Campaigns:label.send_sms') }}
      </div>
      <SelectInput
        valueProp="phoneNumber"
        textProp="phoneNumber"
        :options="data.availableAccounts"
        v-model="accountPhoneNumberVal"
		:disabled="isSaving"
		label="S broja"
      />
      <TextInput :required="true" :disabled="isSaving" label="Na broj" v-model="toNumberVal" />
      <hr />
      <label>
        <strong>Poruka:</strong>
      </label>
      <SmsInput :disabled="isSaving" :rows="2" style="resize: vertical" v-model="content" />
    </div>
    <div class="card-footer sms-editor-footer d-flex">
      <button
        type="button"
        class="btn btn-link text-danger ml-auto"
        @click="cancel"
        :disabled="isSaving"
      >Odustani</button>
      <button
        type="button"
        class="btn btn-success"
        @click="submit"
        :disabled="isSaving || !canSubmit"
      >
        <span class="fas fa-paper-plane"></span>
        Pošalji
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from 'BootQuery/Assets/js/forms/fields/Text.vue';
import SelectInput from 'BootQuery/Assets/js/forms/fields/Select.vue';
import SmsInput from 'app/Modules/Sms/Assets/components/SMSInput.vue';

export default {
  components: {
    TextInput,
    SelectInput,
    SmsInput,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      isSaving: false,
      to: null,
      content: '',
      accountPhoneNumber: null,
    };
  },
  methods: {
    submit() {
      this.isSaving = true;
      this.module.submitSmsEditor(this.editorID, {
        accountPhoneNumber: this.accountPhoneNumberVal,
        to: this.toNumberVal,
        content: this.content,
      });
    },
    cancel() {
      this.module.destroySmsEditor(this.editorID);
    },
  },
  computed: {
    toNumberVal: {
      get() {
        if (this.to !== null) {
          return this.to;
        }
        return this.data.to || '';
      },
      set(to) {
        this.to = to;
      },
    },
    accountPhoneNumberVal: {
      get() {
        if (this.accountPhoneNumber) {
          return this.accountPhoneNumber;
        }
        if (this.data.accountPhoneNumber) {
          return this.data.accountPhoneNumber;
        }

        const firstAccount = this.data.availableAccounts[0] || null;
        return firstAccount ? firstAccount.phoneNumber : null;
      },
      set(accountPhoneNumber) {
        this.accountPhoneNumber = accountPhoneNumber;
      },
    },
    canSubmit() {
      const fromNumberValid = !!this.accountPhoneNumberVal;
      const toNumberValid = !!this.toNumberVal;
      const contentValid = (this.content || '').trim().length > 0;

      return fromNumberValid && toNumberValid && contentValid;
    },
    editorID() {
      return this.data.editorID;
    },
    module() {
      return window.BootQuery.getModuleInstance('Campaigns');
    },
  },
};
</script>

<style lang="scss" scoped>
.sms-editor-footer {
  position: sticky;
  bottom: 0;
  background: var(--light);
  z-index: 50;
}
</style>
