import { ReactElement, useEffect, useState } from 'react';
import hr from 'date-fns/locale/hr';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

import { useColorMode } from '@chakra-ui/react';

import { ChakraDateInput } from '../ChakraDateInput/ChakraDateInput';
import {
  DateInputProps,
  DateRange,
  DateRangeInputProps,
  SingleDateInputProps,
} from './types';

registerLocale('hr', hr);
setDefaultLocale('hr');

interface InternalDateRange {
  from: Date | null;
  to: Date | null;
}

function dateFormat(time: boolean): string {
  return time ? 'dd. MM. yyyy. HH:mm' : 'dd. MM. yyyy.';
}

export const SingleDateInput = ({
  value,
  onChange,
  onBlur,
  isClearable,
  autoFocus,
  showTime = false,
}: SingleDateInputProps): ReactElement => {
  return (
    <ChakraDateInput
      showTime={showTime}
      value={value}
      onBlur={onBlur}
      isClearable={isClearable}
      autoFocus={autoFocus}
      onChange={(date) => {
        if (date instanceof Date) {
          onChange(date);
        } else if (isClearable && !date) {
          onChange(null);
        }
      }}
    />
  );
};

export const DateRangeInput = ({
  value,
  onChange,
  onBlur,
  inputClass,
  isClearable,
  autoFocus,
  showTime = false,
}: DateRangeInputProps): ReactElement => {
  const { colorMode } = useColorMode();
  const [internalValue, setInternalValue] = useState<InternalDateRange>({
    from: null,
    to: null,
  });

  useEffect(() => {
    if (!value) {
      return;
    }

    const currentlyPicking =
      (internalValue?.from === null) !== (internalValue?.to === null);
    const valuesChanged =
      internalValue.from !== value.from || internalValue.to !== value.to;
    if (!currentlyPicking && valuesChanged) {
      setInternalValue(value);
    }
  }, [value, internalValue]);

  const onValueChange = (val: InternalDateRange) => {
    setInternalValue(val);
    const { from, to } = val;
    if (from && to) {
      onChange({ from, to });
    } else if (!from && !to) {
      onChange(null);
    }
  };

  return (
    <DatePicker
      selectsRange={true}
      startDate={internalValue.from}
      endDate={internalValue.to}
      locale="hr"
      dateFormat={dateFormat(showTime)}
      className={inputClass ?? 'datepicker-input'}
      calendarClassName={colorMode}
      onBlur={onBlur}
      isClearable={isClearable}
      showTimeInput={showTime}
      autoFocus={autoFocus}
      onChange={(newVal) => {
        if (newVal instanceof Date) {
          throw new Error('Got single date in date range picker');
        }

        const [from, to] = newVal || [null, null];
        onValueChange({ from, to });
      }}
    />
  );
};

export const DateInput = ({
  value,
  onChange,
  mode = 'single',
  ...commonProps
}: DateInputProps): ReactElement => {
  if (mode === 'range') {
    // Make sure it's always date range
    const fixedVal = !(value instanceof Date) ? value : null;

    return (
      <DateRangeInput
        value={fixedVal}
        onChange={onChange as (val: DateRange | null) => void}
        {...commonProps}
      />
    );
  }

  // Make sure it's always a date
  return (
    <SingleDateInput
      value={!value || value instanceof Date ? value : null}
      onChange={onChange as (val: Date | null) => void}
      {...commonProps}
    />
  );
};
