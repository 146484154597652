import { Flex } from '@chakra-ui/react';
import { formatNumber } from 'app/assets/js/tsutil';
import { PopoverLink } from 'BootQuery/Assets/components/PopoverTrigger';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';
import { ReactElement } from 'react';
import { ApiNumber } from './types';

interface NumberListProps {
  phoneNumbers: ApiNumber[];
  small?: boolean;
}

export const NumberList = ({
  phoneNumbers,
  small = false,
}: NumberListProps): ReactElement => (
  <Flex zIndex={3}>
    <WithSeparator separator=",&nbsp;">
      {phoneNumbers.map(({ phoneNumber: { phoneNumberE164 } }) => (
        <PopoverLink
          fontSize={small ? 'xs' : 'sm'}
          fontWeight="normal"
          as="a"
          key={phoneNumberE164}
          href={`tel:${phoneNumberE164}`}
          title={phoneNumberE164}
        >
          {formatNumber(phoneNumberE164)}
        </PopoverLink>
      ))}
    </WithSeparator>
  </Flex>
);
