import { ReactElement, useMemo } from 'react';
import { Heading, HStack, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { FilterValue } from '@bq/components/FilterBar';
import { formatDuration } from 'app/assets/js/tsutil';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  Column,
  Table,
  useVisibleColumns,
} from 'BootQuery/Assets/components/Table';
import {
  ColumnMap,
  TableDensity,
  TableMenu,
} from 'BootQuery/Assets/components/TableMenu';
import { Api } from 'BootQuery/Assets/js/api';
import i18n from 'BootQuery/Assets/js/i18n';
import { useUserSetting } from 'BootQuery/Assets/js/user-settings';
import { getUserSetting } from 'BootQuery/Assets/js/userSettings';

import { ExportLink } from './ExportLink';
import { genExportLink } from './gen-export-link';
import { reportParams } from './report-params';
import { useReportContext } from './ReportContext';
import { ReportSettings, ResponseStats } from './types';

interface ReportRow {
  user: {
    username: string;
    person: {
      fullName: string;
    } | null;
  };
  received: number;
  closed: number;
  reopened: number;
  responses: ResponseStats;
  firstResponse: ResponseStats;
}

function userDisplayName(row: ReportRow): string {
  return row.user.person?.fullName ?? row.user.username;
}

function useColumnVisibility(defaultVal: ColumnMap = {}) {
  return useUserSetting('Ticketing.Reports.agents.columns', defaultVal, false);
}

function useDensity(defaultDensity?: TableDensity) {
  return useUserSetting(
    'Ticketing.Reports.agents.density',
    defaultDensity ?? 'md',
    false
  );
}

function getColumns(): Column<ReportRow>[] {
  return [
    {
      key: 'user',
      title: i18n.t('Ticketing:form.agent'),
      getValue: ({ row }) => userDisplayName(row),
      exportFormat: 'agentName',
      exportValue: 'user',
    },
    {
      key: 'received',
      title: i18n.t('Ticketing:reports.received'),
    },
    {
      key: 'closed',
      title: i18n.t('Ticketing:reports.closed'),
    },
    {
      key: 'reopened',
      title: i18n.t('Ticketing:reports.reopened'),
    },
    {
      key: 'firstResponse.count',
      title: i18n.t('Ticketing:reports.first_response_count'),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.avg',
      title: i18n.t('Ticketing:reports.first_response_avg'),
      getValue: ({ row }) => formatDuration(row.firstResponse.avg),
    },
    {
      key: 'firstResponse.min',
      title: i18n.t('Ticketing:reports.first_response_min'),
      getValue: ({ row }) => formatDuration(row.firstResponse.min),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.max',
      title: i18n.t('Ticketing:reports.first_response_max'),
      getValue: ({ row }) => formatDuration(row.firstResponse.max),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.median',
      title: i18n.t('Ticketing:reports.first_response_median'),
      getValue: ({ row }) => formatDuration(row.firstResponse.median),
      defaultVisible: true,
    },
    {
      key: 'responses.count',
      title: i18n.t('Ticketing:reports.responses_count'),
      defaultVisible: true,
    },
    {
      key: 'responses.avg',
      title: i18n.t('Ticketing:reports.responses_avg'),
      getValue: ({ row }) => formatDuration(row.responses.avg),
      defaultVisible: true,
    },
    {
      key: 'responses.min',
      title: i18n.t('Ticketing:reports.responses_min'),
      getValue: ({ row }) => formatDuration(row.responses.min),
      defaultVisible: false,
    },
    {
      key: 'responses.max',
      title: i18n.t('Ticketing:reports.responses_max'),
      getValue: ({ row }) => formatDuration(row.responses.max),
      defaultVisible: false,
    },
    {
      key: 'responses.median',
      title: i18n.t('Ticketing:reports.responses_median'),
      getValue: ({ row }) => formatDuration(row.responses.median),
      defaultVisible: true,
    },
  ];
}

type LoadResult =
  | { error: null; data: ReportRow[] }
  | { error: 'missing_params'; data: null };

async function loadData(
  settings: ReportSettings,
  filters: FilterValue[] = []
): Promise<LoadResult> {
  if (!settings.datePeriod) {
    return { error: 'missing_params', data: null };
  }

  const { data } = await Api.get<ReportRow[]>('/api/ticketing/reports/agents', {
    params: await reportParams(settings, filters),
  });

  return { data, error: null };
}

interface TableSettings {
  columns: ColumnMap;
  density: TableDensity;
  limit: number;
}

async function fetchDisplaySettings(): Promise<Partial<TableSettings>> {
  const settings = await getUserSetting<Partial<TableSettings> | null>(
    'Ticketing.Reports.agents'
  );

  return settings ?? {};
}

interface ReportContentProps {
  displaySettings: Partial<TableSettings>;
}

export const AgentReportContent = ({
  displaySettings,
}: ReportContentProps): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { settings, filterVal } = useReportContext();

  const columns = useMemo(getColumns, [getColumns]);
  const [visibleColumns, setVisibleColumns] = useColumnVisibility(
    displaySettings.columns
  );
  const [density, setDensity] = useDensity(displaySettings.density);
  const columnsToShow = useVisibleColumns(columns, visibleColumns);

  const { data, status } = useQuery(
    ['ticketingAgentsReport', settings, filterVal],
    () => loadData(settings, filterVal)
  );

  const exportLink = genExportLink(
    '/api/ticketing/reports/agents/export',
    settings,
    columnsToShow
  );

  return (
    <VStack>
      <HStack w="full" justifyContent="space-between" px={5} py={3}>
        <Heading
          as="h2"
          display="flex"
          alignItems="center"
          fontSize="lg"
          mb="0"
          textAlign="left"
        >
          {t('Ticketing:reports.agents_report_title')}
        </Heading>
        <TableMenu
          visibleColumns={{
            columns,
            value: visibleColumns,
            onChange: setVisibleColumns,
          }}
          density={{ value: density, onChange: setDensity }}
        >
          {exportLink && <ExportLink link={exportLink} />}
        </TableMenu>
      </HStack>
      {data?.error ? (
        <>{t('global:error')}</>
      ) : (
        <Table<ReportRow>
          columns={columnsToShow}
          rows={data?.data ?? []}
          isLoading={status === 'loading'}
          size={density}
        />
      )}
    </VStack>
  );
};

export const AgentReport = (): ReactElement => {
  const { data: settings } = useQuery(
    ['ticketingAgentReportDisplaySettings'],
    fetchDisplaySettings
  );

  return (
    <>
      {settings ? (
        <AgentReportContent displaySettings={settings} />
      ) : (
        <LoadingPage />
      )}
    </>
  );
};
