import { CSSProperties, memo, ReactElement, useMemo } from 'react';
import {
  TableProps,
  TableRowProps,
  Td,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { get, isEqual } from 'lodash-es';
import { Link } from 'react-router-dom';

import { selectableText, tdStyle, tdStyleStatic, wholeRowAnchor } from './styles';
import type { CellProps, Column, LinkProps, Row } from './types';

interface Props<RowType = Row> extends TableRowProps {
  columns: Column<RowType>[];
  rowData: RowType;
  rowIdx: number;
  rowLink?: string | LinkProps | null;
  rowLinkTextSelect?: boolean;
  style?: CSSProperties;
  className?: string;
  tableSize?: TableProps['size'];
  isStatic?: boolean;
}

const defaultCellVal = <RowType, >({
  row,
  column,
}: CellProps<RowType>): string | number => {
  const recRow = row as unknown as Row;
  const autoVal = get(recRow, column.key) ?? null;
  if (typeof autoVal === 'string' || typeof autoVal === 'number') {
    return autoVal;
  }

  return '-';
};

interface TableCellProps<RowType> {
  column: Column<RowType>;
  rowData: RowType;
  rowIdx: number;
  rowLink?: string | LinkProps | null;
  rowLinkClass: string;
  tableSize?: TableProps['size'];
  isStatic?: boolean;
}

interface CellValueProps<RowType> {
  column: Column<RowType>;
  rowData: RowType;
  rowIdx: number;
  tableSize?: TableProps['size'];
}

function getCellValueString<RT>({
  column,
  rowData,
  rowIdx,
  tableSize,
}: CellValueProps<RT>): string {
  const getVal = column.getValue ?? defaultCellVal;

  const val = getVal({ tableSize, row: rowData, rowIdx, column });
  const cleanVal = String(val ?? '').replace(/^[-]$/, '');

  return cleanVal;
}

export function CellValue<RT>({
  column,
  rowData,
  rowIdx,
  tableSize,
}: CellValueProps<RT>): ReactElement {
  if (column.Cell) {
    return (
      <column.Cell
        row={rowData}
        rowIdx={rowIdx}
        column={column}
        tableSize={tableSize}
      />
    );
  }

  const getVal = column.getValue ?? defaultCellVal;

  return <span>{getVal({ tableSize, row: rowData, rowIdx, column })}</span>;
}

interface RowLinkProps {
  rowLink: string | LinkProps;
  rowLinkClass: string;
}

const RowLink = ({ rowLink, rowLinkClass }: RowLinkProps): ReactElement => {
  const state = typeof rowLink === 'string' ? undefined : rowLink.state;
  const link = typeof rowLink === 'string' ? rowLink : rowLink.link;

  return (
    <Link
      to={link}
      state={state}
      className={rowLinkClass}
      tabIndex={-1}
      aria-hidden
    />
  );
};

function TableCellInner<RowType>({
  column,
  rowLink,
  rowLinkClass,
  rowData,
  rowIdx,
  tableSize,
  isStatic,
}: TableCellProps<RowType>): ReactElement {
  return (
    <Td
      transition="padding 0.3s"
      className={isStatic ? tdStyleStatic : tdStyle}
      title={getCellValueString({ column, rowData, rowIdx, tableSize })}
      {...(column.tdProps ?? {})}
    >
      {rowLink && (
        // These are hidden from screen readers, we should fallback to normal link on each row
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <RowLink rowLink={rowLink} rowLinkClass={rowLinkClass} />
      )}
      <CellValue
        column={column}
        rowData={rowData}
        rowIdx={rowIdx}
        tableSize={tableSize}
      />
    </Td>
  );
}

// eslint-disable-next-line react/display-name
const TableCell = memo(
  TableCellInner,
  (prevProps, props) => isEqual(prevProps, props)
) as typeof TableCellInner;

const TableRow = <RowType, >({
  columns,
  rowIdx,
  rowData,
  rowLink,
  style,
  className,
  rowLinkTextSelect = false,
  tableSize,
  isStatic,
  ...trProps
}: Props<RowType>): JSX.Element => {
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  const rowLinkClass = rowLinkTextSelect
    ? `${wholeRowAnchor} ${selectableText}`
    : wholeRowAnchor;

  const hoverStyle = useMemo(
    () => (rowLink ? { bg: hoverBg } : undefined),
    [hoverBg, rowLink]
  );

  return (
    <Tr _hover={hoverStyle} {...trProps} className={className} style={style}>
      {columns.map((column) => (
        <TableCell
          isStatic={isStatic}
          key={column.key}
          column={column}
          rowData={rowData}
          rowIdx={rowIdx}
          rowLink={rowLink}
          rowLinkClass={rowLinkClass}
          tableSize={tableSize}
        />
      ))}
    </Tr>
  );
};

// eslint-disable-next-line react/display-name
const TableRowMemo = memo(
  TableRow,
  (prevProps, props) => isEqual(prevProps, props)
) as typeof TableRow;

export { TableRowMemo as TableRow };
