import { z } from 'zod';

export const BasicFieldSchema = z.object({
  ID: z.number(),
  name: z.string(),
});

export const LoadedOffererSchema = BasicFieldSchema.extend({
  type: z.enum(['person', 'company']),
});

export const LoadedResponsiblePersonSchema = BasicFieldSchema.extend({
  type: z.literal('user'),
});

export const LoadedBuyerSchema = BasicFieldSchema.extend({
  type: z.enum(['person', 'company']),
});

export const LoadedOfferSchema = z.object({
  ID: z.number(),
  name: z.string(),
  createdAt: z.string(),
  firstVersionCreatedAt: z.string(),
  offerer: LoadedOffererSchema,
  responsibleUser: LoadedResponsiblePersonSchema.nullable(),
  createdByUser: LoadedResponsiblePersonSchema.nullable(),
  buyer: LoadedBuyerSchema,
  offerDate: z.string(),
  validUntil: z.string(),
  deliveryDate: z.string(),
  billingStartDate: z.string(),
});

export const PartialLoadedOfferSchema = LoadedOfferSchema.partial().extend({
  ID: z.number(),
});

export const offerModificationsWithDefaultsSchema = z.object({
  add: z.array(PartialLoadedOfferSchema),
  upd: z.record(
    PartialLoadedOfferSchema.shape.ID,
    PartialLoadedOfferSchema.partial()
  ),
  del: z.array(PartialLoadedOfferSchema.shape.ID),
  defaults: z.array(PartialLoadedOfferSchema),
});

export type OfferModificationsWithDefaults = z.infer<
  typeof offerModificationsWithDefaultsSchema
>;
