import { render, staticRenderFns } from "./MessageAttachments.vue?vue&type=template&id=5d8cc305&scoped=true&"
import script from "./MessageAttachments.vue?vue&type=script&lang=js&"
export * from "./MessageAttachments.vue?vue&type=script&lang=js&"
import style0 from "./MessageAttachments.vue?vue&type=style&index=0&id=5d8cc305&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-7e763bbc94/0/cache/vue-loader-npm-15.10.1-3a0b6c19d2-828dd30ec4.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8cc305",
  null
  
)

export default component.exports