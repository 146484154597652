module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":45,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"card\" id=\"wizard-container\">\n		<div class=\"card-header\">\n			<span class=\"fa fa-plus\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.import_dataset",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":65}}}))
    + "\n		</div>\n		<div class=\"card-body\">\n            <div class=\"col-12 mx-auto\" style=\"max-width: 1000px;\">\n                <form action=\"/campaigns/processData\" method=\"POST\" id=\"campaign-form\" enctype=\"multipart/form-data\">\n                    <input type=\"hidden\" name=\"step\" value=\"1\">\n                    <div class=\"form-group row\">\n                        <label class=\"col-12 col-form-label col-md-4 col-form-label control-label text-left text-md-right\" for=\"dataset_name\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.dataset_name",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":12,"column":142},"end":{"line":12,"column":168}}}))
    + ":</label>\n                        <div class=\"col-12 col-md-8\">\n                            <input type=\"text\" class=\"form-control\" id=\"dataset_name\" name=\"dataset_name\" placeholder=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.dataset_name",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":14,"column":119},"end":{"line":14,"column":145}}}))
    + "\" autocomplete=\"off\">\n                        </div>\n                    </div>\n\n                    <div class=\"form-group row upload\">\n                        <label class=\"col-12 col-form-label col-md-4 col-form-label control-label text-left text-md-right\" for=\"file\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.file",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":19,"column":134},"end":{"line":19,"column":152}}}))
    + ":</label>\n\n                        <div class=\"col-12 col-md-8\">\n                            <div class=\"btn btn-outline-secondary upload-button col-10\" type=\"button\" style=\"position: relative; -webkit-appearance: none;\">\n                                <span class=\"upload-label\">\n                                    <span class=\"fa fa-cloud-upload-alt\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.upload",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":24,"column":81},"end":{"line":24,"column":103}}}))
    + "\n                                </span>\n\n                                <div class=\"progress\" style=\"position: absolute; bottom: 0px; left: 0px; height: 3px; width: 100%; background: rgba(0, 0, 0, 0); border-radius: 0;\">\n                                    <div class=\"progress-bar bg-success\" role=\"progressbar\" aria-valuenow=\"50\" aria-valuemin=\"0\" aria-valuemax=\"100\"></div>\n                                </div>\n                                <input type=\"file\" name=\"file\" style=\"position: absolute; top: 0; left: 0; height: 100%; width: 100%; opacity: 0;\">\n                            </div>\n\n                            <button class=\"btn btn-danger upload-delete ml-auto\" type=\"button\" disabled><span class=\"fa fa-trash\"></span></button>\n                        </div>\n                    </div>\n                </form>\n            </div>\n		</div>\n		<div class=\"card-footer d-flex\">\n			<button class=\"btn btn-primary ml-auto\" type=\"submit\" form=\"campaign-form\">\n				"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.next",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":41,"column":4},"end":{"line":41,"column":24}}}))
    + "&nbsp;<span class=\"fa fa-arrow-right\"></span>\n			</button>\n		</div>\n	</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":46,"column":11}}})) != null ? stack1 : "");
},"useData":true})