import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { getContactInfo } from 'app/Modules/Phonebook/Assets/js/contact-info';
import { FixedPopoverContent } from 'BootQuery/Assets/components/FixedPopoverContent';
import { LoaderScale } from 'BootQuery/Assets/components/Loader';
import { PopoverButton } from 'BootQuery/Assets/components/PopoverTrigger';
import { Portal } from 'BootQuery/Assets/components/Portal';
import { ReactElement } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Contact } from '../../types';

interface ContactDisplayProps {
  contact: Contact;
  phoneNumber?: string | null;
  fontSize?: string;
  allowWrap?: boolean;
}

function useContactInfo(contact: Contact) {
  return useQuery(['contactInfo', contact.type, contact.ID], () => getContactInfo(contact.type, contact.ID));
}

function loadContactDetails() {
  return import('./ContactDetails');
}

const ContactPopoverContent = ({
  contact,
  phoneNumber,
}: ContactDisplayProps): ReactElement => {
  const { data: contactInfo } = useContactInfo(contact);
  const { data: ContactDetailsImp } = useQuery(
    ['componentContactDetails'],
    () => loadContactDetails()
  );

  if (!contactInfo || !ContactDetailsImp) {
    return <LoaderScale />;
  }

  return (
    <ContactDetailsImp.ContactDetails
      contact={contactInfo}
      phoneNumber={phoneNumber}
    />
  );
};

export const ContactDisplay = ({
  contact,
  phoneNumber,
  fontSize,
  allowWrap,
}: ContactDisplayProps): ReactElement => (
  <Popover isLazy>
    <PopoverTrigger>
      <PopoverButton
        whiteSpace={allowWrap ? 'normal' : 'nowrap'}
        userSelect="text"
        fontSize={fontSize ?? 'sm'}
      >
        <Text as="span" noOfLines={2}>
          {contactName(contact)}
        </Text>
      </PopoverButton>
    </PopoverTrigger>
    <Portal>
      <FixedPopoverContent w="auto">
        <PopoverArrow />
        <PopoverBody>
          <ContactPopoverContent contact={contact} phoneNumber={phoneNumber} />
        </PopoverBody>
      </FixedPopoverContent>
    </Portal>
  </Popover>
);

const contactName = (contact: Contact): string => {
  if (contact.type === 'user') {
    return contact.person?.fullName ? contact.person.fullName : contact.name;
  }

  return contact.name;
};
