module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":189,"column":12}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"campaign-create-form\" class=\"form-horizontal\" action=\"/campaigns/editCampaignForm\" method=\"post\" role=\"form\">\n	<div class=\"card embedable\">\n\n		<div class=\"card-header stick-top\" id=\"campaign-create-container-header\">\n			<span class=\"fa fa-magic\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.create_campaign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":73}}}))
    + "\n		</div>\n\n		<div class=\"card-body\" id=\"campaign-create-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"campaign_parameters")),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":3},"end":{"line":13,"column":12}}})) != null ? stack1 : "")
    + "			<div class=\"col-12 mx-auto\" style=\"max-width: 1000px;\">\n				<div class=\"form-group row\">\n					<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"name\">\n						<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.campaign_name",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":17,"column":31},"end":{"line":17,"column":58}}}))
    + "</span><span class=\"label-after\">: </span>\n					</label>\n					<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n						<input type=\"text\" name=\"name\" value=\"\" class=\"form-control\" autocomplete=\"off\" autofocus placeholder=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.campaign_name",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":20,"column":109},"end":{"line":20,"column":136}}}))
    + "\">\n					</div>\n				</div>\n				<div class=\"form-group row\">\n					<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"dialtype\">\n						<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.dial_type",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":25,"column":31},"end":{"line":25,"column":54}}}))
    + "</span><span class=\"label-after\">: </span>\n					</label>\n					<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n						<select class=\"form-control pickle\" name=\"dialtype\">\n							<option value=\"auto\" selected>\n								"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.auto_dial",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":30,"column":31}}}))
    + "\n							</option>\n							<option value=\"manual\">\n								"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.manual_dial",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":33}}}))
    + "\n							</option>\n						</select>\n					</div>\n				</div>\n				<div id=\"auto-dial-extra\">\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"wrapup\">\n							<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.wrapup_time",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":41,"column":32},"end":{"line":41,"column":57}}}))
    + "</span><span class=\"label-after\">: </span>\n						</label>\n						<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n							<input type=\"number\" name=\"wrapup\" min=\"0\" step=\"1\" value=\"60\" class=\"form-control\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.wrapup_explanation",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":44,"column":123},"end":{"line":44,"column":155}}}))
    + "\">\n						</div>\n					</div>\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"skip\">\n							<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.skip_time",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":49,"column":32},"end":{"line":49,"column":55}}}))
    + "</span><span class=\"label-after\">: </span>\n						</label>\n						<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n							<input type=\"number\" name=\"skip\" min=\"0\" step=\"1\" value=\"15\" class=\"form-control\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.skip_explanation",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":52,"column":121},"end":{"line":52,"column":151}}}))
    + "\">\n						</div>\n					</div>\n				</div>\n				<div class=\"form-group row\" id=\"manual-dial-extra\">\n					<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"dialonload\" disabled>\n						<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.dial_helpers",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":58,"column":31},"end":{"line":58,"column":57}}}))
    + "</span><span class=\"label-after\">: </span>\n					</label>\n					<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n						<div class=\"custom-control custom-checkbox\">\n							<input type=\"checkbox\" class=\"custom-control-input\" id=\"dialonload\" value=\"true\" name=\"dialonload\" disabled>\n							<label class=\"custom-control-label\" for=\"dialonload\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.dial_on_load",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":63,"column":60},"end":{"line":63,"column":86}}}))
    + "</label>\n						</div>\n						<div class=\"custom-control custom-checkbox\">\n							<input type=\"checkbox\" class=\"custom-control-input\" id=\"dial-next\" value=\"true\" name=\"dialnext\" disabled>\n							<label class=\"custom-control-label\" for=\"dial-next\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.dialnext",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":67,"column":59},"end":{"line":67,"column":81}}}))
    + "</label>\n						</div>\n					</div>\n				</div>\n				<div class=\"form-group row\" id=\"manual-dial-extra\">\n					<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"selectRandomContacts\">\n						"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.select_random_contacts",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":73,"column":6},"end":{"line":73,"column":42}}}))
    + ":\n					</label>\n					<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n						<div class=\"custom-control custom-checkbox\">\n							<input type=\"checkbox\" class=\"custom-control-input\" id=\"selectRandomContacts\" value=\"true\" name=\"selectRandomContacts\">\n							<label class=\"custom-control-label\" for=\"selectRandomContacts\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.select_random_contacts",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":78,"column":70},"end":{"line":78,"column":106}}}))
    + "</label>\n						</div>\n					</div>\n				</div>\n				<div class=\"form-group row\">\n					<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"assigntype\">\n						"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assign_method",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":84,"column":6},"end":{"line":84,"column":33}}}))
    + ":\n					</label>\n					<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n						<select class=\"form-control pickle\" name=\"assigntype\" autocomplete=\"off\">\n							<option value=\"dynamic\" selected>\n								"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.dynamic_assign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":89,"column":8},"end":{"line":89,"column":36}}}))
    + "\n							</option>\n							<option value=\"static\">\n								"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.static_assign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":92,"column":8},"end":{"line":92,"column":35}}}))
    + "\n							</option>\n						</select>\n					</div>\n				</div>\n				<div class=\"form-group row\" id=\"assignment-mode-dynamic\">\n					<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"assignment-mode\">\n						<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":99,"column":31},"end":{"line":99,"column":60}}}))
    + "</span><span class=\"label-after\">: </span>\n					</label>\n					<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n						<div class=\"custom-control custom-radio\">\n							<input type=\"radio\" id=\"assignment-mode-dynamic-auto\" name=\"assignment_mode_dynamic\" class=\"custom-control-input\" value=\"auto\" checked>\n							<label class=\"custom-control-label\" for=\"assignment-mode-dynamic-auto\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode_auto",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":104,"column":78},"end":{"line":104,"column":112}}}))
    + "</label>\n						</div>\n						<div class=\"custom-control custom-radio\">\n							<input type=\"radio\" id=\"assignment-mode-dynamic-limited\" name=\"assignment_mode_dynamic\" class=\"custom-control-input\" value=\"limited\">\n							<label class=\"custom-control-label\" for=\"assignment-mode-dynamic-limited\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode_limited",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":108,"column":81},"end":{"line":108,"column":118}}}))
    + "</label>\n							<input type=\"number\" name=\"assign_dynamic_amount\" min=\"1\" max=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" class=\"form-control col-4\" autocomplete=\"off\" placeholder=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\">\n							<label class=\"form-label\" for=\"assign_dynamic_amount\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.from_total",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":110,"column":61},"end":{"line":110,"column":85}}}))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "</label>\n						</div>\n					</div>\n				</div>\n\n				<div class=\"form-group row\" id=\"assignment-mode-static\" hidden>\n					<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"assignment-mode\">\n						<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":117,"column":31},"end":{"line":117,"column":60}}}))
    + "</span><span class=\"label-after\">: </span>\n					</label>\n					<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n						<div class=\"custom-control custom-radio\">\n							<input type=\"radio\" id=\"assignment-mode-static-spread\" name=\"assignment_mode_static\" class=\"custom-control-input\" value=\"spread\" checked>\n							<label class=\"custom-control-label\" for=\"assignment-mode-static-spread\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode_static_spread",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":122,"column":79},"end":{"line":122,"column":122}}}))
    + ": </label>\n							<input type=\"number\" name=\"assign_static_amount\" min=\"1\" max=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" class=\"form-control col-4\" autocomplete=\"off\" placeholder=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\">\n							<label class=\"form-label\" for=\"assign_static_amount\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.from_total",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":124,"column":60},"end":{"line":124,"column":84}}}))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "</label>\n						</div>\n						<div class=\"custom-control custom-radio\">\n							<input type=\"radio\" id=\"assignment-mode-static-per-agent\" name=\"assignment_mode_static\" class=\"custom-control-input\" value=\"per_agent\">\n							<label class=\"custom-control-label\" for=\"assignment-mode-static-per-agent\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode_static_per_agent",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":128,"column":82},"end":{"line":128,"column":128}}}))
    + ": </label>\n							<input type=\"number\" name=\"assign_static_per_agent_amount\" min=\"0\" max=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" value=\"100\" class=\"form-control col-4\" autocomplete=\"off\" disabled>\n						</div>\n					</div>\n				</div>\n\n				<div class=\"form-group row\">\n					<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"agents\">\n						<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assign_to_agents",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":136,"column":31},"end":{"line":136,"column":61}}}))
    + "</span><span class=\"label-after\">: </span>\n					</label>\n					<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n						<div class=\"card subform-card\">\n							<div class=\"card-body\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"agents") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":8},"end":{"line":146,"column":17}}})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n				</div>\n\n				<div class=\"form-group row\">\n					<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"autoScheduleCallAgain\" disabled>\n						<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.call_again",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":154,"column":31},"end":{"line":154,"column":55}}}))
    + "</span><span class=\"label-after\">: </span>\n					</label>\n					<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n						<div class=\"row form-group\">\n							<div class=\"col-12\">\n								<div class=\"custom-control custom-checkbox\">\n									<input type=\"checkbox\" class=\"custom-control-input\" id=\"autoScheduleCallAgain\" value=\"true\" name=\"autoScheduleCallAgain\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"campaign") : stack1)) != null ? lookupProperty(stack1,"autoScheduleCallAgain") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":160,"column":130},"end":{"line":160,"column":189}}})) != null ? stack1 : "")
    + ">\n									<label class=\"custom-control-label\" for=\"autoScheduleCallAgain\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.schedule_call_again_unanswered",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":161,"column":73},"end":{"line":161,"column":117}}}))
    + "</label>\n								</div>\n							</div>\n							<div class=\"col-12\">\n								<label for=\"callAgainAfter\">\n									"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.call_again_interval",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":166,"column":9},"end":{"line":166,"column":42}}}))
    + ":\n								</label>\n								<input type=\"text\" name=\"callAgainAfter\" id=\"callAgainAfter\" class=\"form-control durationpicker\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"campaign") : stack1)) != null ? lookupProperty(stack1,"callAgainAfter") : stack1), depth0))
    + "\">\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n\n		<div class=\"card-footer stick-bottom d-flex\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":3},"end":{"line":181,"column":10}}})) != null ? stack1 : "")
    + "\n			<button type=\"submit\" class=\"btn btn-primary ml-auto\" form=\"campaign-create-form\">\n				<span class=\"fa fa-arrow-right\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.next",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":184,"column":49},"end":{"line":184,"column":70}}}))
    + "\n			</button>\n		</div>\n	</div>\n</form>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\""
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<div class=\"custom-control custom-checkbox\">\n									<input type=\"checkbox\" class=\"custom-control-input\" id=\"agents-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\" value=\"true\" name=\"agents["
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "]\">\n									<label class=\"custom-control-label\" for=\"agents-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "</label>\n								</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "checked";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<a href=\"/campaigns/index\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n					<span class=\"fa fa-times\"></span>&nbsp;"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":179,"column":44},"end":{"line":179,"column":67}}}))
    + "\n				</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":190,"column":11}}})) != null ? stack1 : "")
    + "\n\n";
},"useData":true})