<template>
	<div class="form-group row">
		<slot name="label">
			<label
				class="col-form-label"
				:class="labelSizeClasses"
				v-if="label && label.length && !autoLabel"
				:for="fieldId"
			>
				{{ label }}:
			</label>
		</slot>
		<slot name="control">
			<div :class="inputSizeClasses" class="d-flex align-items-center">
				<div class="custom-control" :class="isSwitch ? 'custom-switch' : 'custom-checkbox'">
					<input
						class="custom-control-input"
						:class="{ 'is-invalid': hasError }"
						type="checkbox"
						:id="fieldId"
						:name="fieldName"
						v-model="inputValue"
						:disabled="disabled"
					/>
					<label :for="fieldId" class="custom-control-label" v-if="autoLabel">{{label}}</label>
					<label :for="fieldId" class="custom-control-label" v-else>&nbsp;</label>
				</div>
			</div>
		</slot>
	</div>
</template>

<script>
import inputField from './mixins/inputField';

export default {
  mixins: [inputField],
  props: {
    isSwitch: Boolean,
    autoLabel: Boolean,
  },
};
</script>
