module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"Campaigns.saleFormListItem"),depth0,{"name":"Campaigns.saleFormListItem","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container-fluid py-3\">\n	<ul class=\"nav nav-tabs\">\n		<li class=\"nav-item\">\n			<a href=\"#campaign-sale-forms\" data-toggle=\"tab\" class=\"nav-link active\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"tab.sale_forms",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":4,"column":76},"end":{"line":4,"column":99}}}))
    + "</a>\n		</li>\n	</ul>\n	<div class=\"tab-content\">\n		<div id=\"campaign-sale-forms\" class=\"tab-pane pt-3 show active\">\n			<ul class=\"list-group sales-form-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"saleForms") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":12,"column":13}}})) != null ? stack1 : "")
    + "			</ul>\n			<button type=\"button\" class=\"btn btn-success float-right my-3\" data-add-campaign-sale-form>\n				<span class=\"fa fa-plus\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.add_sale_form",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":66}}}))
    + "\n			</button>\n		</div>\n	</div>\n</div>";
},"usePartial":true,"useData":true})