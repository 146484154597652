import {
  mapValues, merge, sortBy, forEach, clone, pickBy,
} from 'lodash-es';
import { getTemplate } from 'BootQuery/Assets/js/BootQuery';
import { handlebarsRender } from 'app/assets/js/util';
import Sortable from 'sortablejs';
import Field from './field';

export default class List extends Field {
  constructor(optionsOrType, fieldFactory) {
    super(optionsOrType);

    this.fieldFactory = fieldFactory;
    if (!this.options.fields) {
      this.options.fields = {};
    }

    this.options.fields = mapValues(this.options.fields, (field, id) => {
      field.id = id;
      return this.fieldFactory.constructField(field);
    });
  }

  async render() {
    const renderOptions = merge({}, this.options, { readonly: true });
    this.field = $(
      handlebarsRender(await getTemplate('editableFormFieldWrapper'), this.options),
    );

    const listField = $(handlebarsRender(await getTemplate('formEditorList'), renderOptions));

    const order = this.options.order || [];
    let ordered = Object.entries(this.options.fields)
      .map((pair) => merge(pair[1], { key: pair[0] }));
    if (order.length) {
      ordered = sortBy(ordered, (field) => order.indexOf(field.key));
    }
    const itemsEl = listField.find('.formeditor-list-items');
    forEach(ordered, (field) => {
      field.render().then((rendered) => itemsEl.append(rendered));
    });

    this.field.find('.editable-field-wrapper').append(listField);
    this.field
      .find('label > .label-text')
      .prop('contenteditable', true)
      .prop('spellcheck', false);
    this.bindEvents();
    return this.field;
  }

  async bindEvents() {
    super.bindEvents();
    this.sortable = Sortable.create(this.field.find('.formeditor-list-items').get(0), {
      group: {
        name: 'formbuilder',
        put: true,
        pull: false,
      },
      handle: '.editable-field-drag-handle',
      dataIdAttr: 'data-field-id',
      onAdd: async (evt) => {
        const type = evt.item.dataset.formType;
        const field = this.fieldFactory.constructField({
          type,
          new: true,
          nested: true,
        });
        this.options.fields[field.id] = field;
        $(evt.item).replaceWith(await field.render());
      },
      animation: 150,
    });
  }

  getDefinition() {
    const newOpts = clone(this.options);
    let fields = mapValues(newOpts.fields, (field) => field.getDefinition());
    fields = pickBy(fields, (item) => {
      if (item.deleted && item.new) {
        return false;
      }
      return true;
    });
    newOpts.order = this.sortable.toArray();
    newOpts.fields = fields;
    return newOpts;
  }
}
