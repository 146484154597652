import { makeBooleanFilter } from 'BootQuery/Assets/components/FilterBar/filters/Boolean';
import i18n from 'BootQuery/Assets/js/i18n';

import { CheckboxIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { makeCheckboxCell } from './CheckboxCell';
import { CheckboxField } from './CheckboxField';
import { StaticCheckboxField } from './StaticCheckboxField';

export const checkboxField = (): FieldType => ({
  type: 'checkbox',
  name: i18n.t('global:form_editor.field_checkbox'),
  icon: CheckboxIcon,
  components: {
    FormField: CheckboxField,
    StaticFormField: StaticCheckboxField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
  }),
  getColumns(field) {
    return [
      {
        key: `$custom-${field.id}`,
        title: field.settings.name,
        Cell: makeCheckboxCell(field),
      },
    ];
  },
  getFilters(field) {
    return {
      [field.id]: makeBooleanFilter({
        name: field.settings.name,
        toFilter({ value }) {
          return value
            ? { [`data.${field.id}`]: true }
            : {
                $or: {
                  [`data.${field.id}`]: false,
                  [`data.${field.id}:null`]: true,
                },
              };
        },
      }),
    };
  },
});
