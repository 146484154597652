import { ReactElement, useMemo } from 'react';

import { ListingGrid, ListingGridItem } from '@bq/components/ListingGrid';
import { IOverviewEditorItem } from '@bq/components/Overviews';
import { PageWrapper } from '@bq/components/PageWrapper';
import { Sidebar } from '@bq/components/Sidebar';
import { TreeOverviews } from '@bq/components/TreeOverviews';
import { MobileOverviewWrapper } from '@bq/components/TreeOverviews/MobileOverviewsWrapper';
import { useGetQueryParams } from '@bq/components/use-get-query-params';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

import { DatasetTable } from '../components/DatasetList';

export const DatasetList = (): ReactElement => {
  useAppTitle('Datasets');

  return (
    <PageWrapper p={0} maxH="fullNoNavbar" className="pageWrapper">
      <ListingGrid className="fullPageListingGrid">
        <ListingGridItem minWidth={250}>
          <Sidebar>
            <MobileOverviewWrapper>
              <TreeOverviews
                path="datasets/list"
                getQueryParams={useGetQueryParams()}
                overviews={useOverviews()}
                selectedOverview=""
              />
            </MobileOverviewWrapper>
          </Sidebar>
        </ListingGridItem>
        <ListingGridItem
          initialWidth="6fr"
          className="ticketSplitViewContainer"
          display="flex"
          flexDirection="column"
        >
          <DatasetTable />
        </ListingGridItem>
      </ListingGrid>
    </PageWrapper>
  );
};

// We're not going to be using overviews here for now - not much to filter on
// Sidebar is only here for consistency
function useOverviews(): IOverviewEditorItem[] {
  return useMemo(
    () => [
      {
        title: 'Svi',
        slug: '',
        type: 'item',
        filters: [],
        visible: true,
        global: true,
      },
    ],
    []
  );
}
