import { FilterValue } from 'BootQuery/Assets/components/FilterBar';
import { useDOMInputJSON } from 'BootQuery/Assets/components/use-dom-input';
import { Api } from 'BootQuery/Assets/js/api';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const getOperatorCallFilters = async (): Promise<FilterValue[]> => {
  const { data } = await Api.get<FilterValue[]>(
    '/api/telephony/operator/callFilters'
  );

  return data;
};

export interface UseCallFiltersResult {
  filters: FilterValue[] | undefined;
  setFilters: (newFilters: FilterValue[]) => void;
}

const callFiltersQueryKey = ['operatorCallFilters'];

export function useOperatorCallFilters(): UseCallFiltersResult {
  const queryClient = useQueryClient();
  const [, setFiltersInput] = useDOMInputJSON<FilterValue[]>(
    '#settings-form',
    'telephonyOperatorCallFilters',
    []
  );
  const { data: filters } = useQuery(
    callFiltersQueryKey,
    getOperatorCallFilters
  );

  const setFilters = useCallback(
    (newFilters: FilterValue[]) => {
      setFiltersInput(newFilters);
      queryClient.setQueryData(callFiltersQueryKey, newFilters);
    },
    [setFiltersInput, queryClient]
  );

  return { filters, setFilters };
}
