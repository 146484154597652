<template>
	<div class="col">
		<SelectField
			v-if="!fixedAccount"
			v-model="accountIDInput"
			:label="tr('Mails:label.address_from')"
			:options="formattedAccounts"
			textProp="email"
			valueProp="ID"
			inline
			:disabled="disabled"
			:required="true"
		>
			<template slot="option" slot-scope="option">
				<strong v-if="option.formattedName">
					{{option.formattedName}}&nbsp;
				</strong>
				&lt;{{option.email}}&gt;
			</template>
			<template slot="selected-option" slot-scope="option">
				<strong v-if="option.formattedName">
					{{option.formattedName}}&nbsp;
				</strong>
				&lt;{{option.email}}&gt;
			</template>
		</SelectField>
		<MailAddress
			v-model="toProp"
			:label="tr('Mails:label.address_to')"
			:hasError="!toValid"
			inline
			:disabled="disabled"
		/>
		<MailAddress
			v-model="ccProp"
			:label="tr('Mails:label.address_cc')"
			inline
			:disabled="disabled"
		/>
		<MailAddress
			v-model="bccProp"
			:label="tr('Mails:label.address_bcc')"
			inline
			:disabled="disabled"
		/>
		<SelectField
			v-if="!fixedAccount"
			v-model="priorityProp"
			:label="tr('Mails:label.priority')"
			:options="availablePriorities"
			textProp="name"
			valueProp="value"
			inline
			:disabled="disabled"
		/>
		<TextField
			v-model="subjectProp"
			:label="tr('Mails:label.subject')"
			:hasError="!subjectValid"
			inline
			:disabled="disabled"
		/>
		<Checkbox
			v-model="sendSeperateProp"
			:label="tr('Mails:form.send_seperate_mails')"
			name="mailEditorSendSeperate"
			inline
			autoLabel
			:disabled="disabled"
		/>
		<Checkbox
			v-if="offerTicketCreateOnSend && availableTicketingGroups.length"
			v-model="createTicketProp"
			:label="tr('Mails:form.create_ticket')"
			name="mailEditorCreateTicket"
			inline
			autoLabel
			:disabled="disabled"
		/>
		<div class="card subform-card mb-3" v-if="createTicketProp">
			<div class="card-body">
				<SelectField
					v-model="createTicketGroupProp"
					:label="tr('Mails:form.create_ticket_group')"
					:options="availableTicketingGroups"
					textProp="name"
					valueProp="ID"
					inline
					:disabled="disabled"
				/>
				<SelectField
					v-model="createTicketTypeProp"
					:label="tr('Mails:form.create_ticket_type')"
					:options="availableTicketTypes"
					textProp="name"
					valueProp="ID"
					inline
					required
					:disabled="disabled"
				/>
				<SelectField
					v-model="createTicketStateProp"
					:label="tr('Mails:form.create_ticket_initial_state')"
					:options="availableTicketStates || []"
					textProp="name"
					valueProp="stateID"
					inline
					:disabled="disabled || !availableTicketStates.length"
				/>
				<SelectField
					name="customer"
					v-model="createTicketCustomerProp"
					:label="tr('Mails:form.create_ticket_customer')"
					:options="searchedCustomers || []"
					textProp="displayName"
					valueProp="typedID"
					:filterable="false"
					:onSearch="searchCustomers"
					inline
					:disabled="disabled"
				/>
				<Checkbox
					v-model="seperateTicketsProp"
					:label="tr('Mails:form.create_seperate_tickets')"
					name="mailEditorSeperateTickets"
					inline
					autoLabel
					:disabled="disabled || !sendSeperateProp"
				/>
			</div>
		</div>
		<div class="form-group">
      <Quill
        :content.sync="contentProp"
        :html.sync="htmlContentProp"
        :text.sync="textContentProp"
        :disabled="disabled"
        :options="quillOptions"
      />
		</div>
		<div class="form-group">
			<EditorAttachmentList :attachments.sync="attachmentsProp" />
		</div>
		<button
			v-if="showCancelSaveButtons"
			type="button"
			class="btn btn-success float-right"
			:disabled="!canSubmit || disabled"
			@click.prevent="submit"
		>
			<span class="fa fa-paper-plane"></span>
			{{ tr('button.send') }}
		</button>
		<button
			v-if="showCancelSaveButtons"
			type="button"
			class="btn btn-link text-danger float-right"
			:disabled="disabled"
			@click.prevent="$emit('cancel')"
		>
			{{ tr('button.cancel') }}
		</button>
	</div>
</template>

<script>
import SelectField from 'BootQuery/Assets/js/forms/fields/Select.vue';
import TextField from 'BootQuery/Assets/js/forms/fields/Text.vue';
import Checkbox from 'BootQuery/Assets/js/forms/fields/Checkbox.vue';
import Quill from 'BootQuery/Assets/js/quill/quill.vue';
import { quillOptions } from 'BootQuery/Assets/js/quill/quill-options';
import { cloneDeep } from 'lodash-es';
import { parseHTML, microTemplate } from 'app/assets/js/util';
import { Api } from 'BootQuery/Assets/js/api';
import MailAddress from './MailAddressInput.vue';
import EditorAttachmentList from './EditorAttachmentList.vue';

function addressToString(address) {
  if (typeof address === 'object') {
    return address.email;
  }

  return address;
}

export default {
  components: {
    TextField,
    Checkbox,
    SelectField,
    MailAddress,
    Quill,
    EditorAttachmentList,
  },
  props: {
    fixedAccount: Boolean,
    availableTicketingGroups: Array,
    availableTicketTypes: Array,
    accountID: Number,
    availableAccounts: Array,
    signatureTemplateData: Object,
    templates: Array,
    to: Array,
    cc: Array,
    bcc: Array,
    content: Object,
    htmlContent: String,
    textContent: String,
    subject: String,
    disabled: Boolean,
    attachments: Array,
    priority: Number,
    offerTicketCreateOnSend: Boolean,
    sendSeperate: Boolean,
    seperateTickets: {
      type: Boolean,
      default: true,
    },
    createTicket: Boolean,
    createTicketGroup: Number,
    createTicketType: Number,
    createTicketState: Number,
    quotedHtml: String,
    quotedText: String,
    showCancelSaveButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let { accountID } = this;
    let account = null;
    if (accountID) {
      account = this.availableAccounts.find((available) => available.ID === accountID);
    }
    if (!account && this.availableAccounts.length) {
      [account] = this.availableAccounts;
      accountID = account.ID;
      this.$emit('update:accountID', accountID);
    }

    let createTicketGroup = this.createTicketGroup || null;
    let createTicketType = this.createTicketType || null;
    if (this.offerTicketCreateOnSend) {
      if (!createTicketGroup && this.availableTicketingGroups.length) {
        createTicketGroup = this.availableTicketingGroups[0].ID;
      }
      if (!createTicketType && this.availableTicketTypes.length) {
        createTicketType = this.availableTicketTypes[0].ID;
      }
    }

    return {
      accountIDInput: accountID,
      toValue: this.to || [],
      ccValue: this.cc || [],
      bccValue: this.bcc || [],
      subjectValue: this.subject || '',
      contentValue: this.content,
      htmlContentValue: this.htmlContent,
      textContentValue: this.textContent,
      attachmentsValue: this.attachments || [],
      priorityValue: this.priority || 3,
      createTicketValue: this.createTicket || false,
      sendSeperateValue: this.sendSeperate || false,
      seperateTicketsValue: this.seperateTickets || false,
      createTicketGroupValue: createTicketGroup,
      createTicketTypeValue: createTicketType,
      createTicketStateValue: null,
      createTicketCustomerValue: null,
      availablePriorities: [
        { value: 1, name: 'High' },
        { value: 3, name: 'Normal' },
        { value: 5, name: 'Low' },
      ],
      availableTicketStates: null,
      searchedCustomers: null,
    };
  },
  methods: {
    getData() {
      // Remove quote preview thingies
      let html = this.htmlContentProp;
      let hasQuote = false;
      if (html) {
        const dom = parseHTML(html);

        html = '';
        dom.forEach((node) => {
          const quotes = node.querySelectorAll('.clickvox-quote-editor-outer');
          if (quotes.length) {
            hasQuote = true;
          }
          quotes.forEach((quote) => {
            quote.parentNode.removeChild(quote);
          });
          html += node.outerHTML;
        });
      }

      const accountInfo = this.formattedAccounts.find(
        (account) => this.accountIDInput === account.ID
      );

      return {
        accountID: this.accountIDInput,
        accountInfo,
        to: this.toProp.map(addressToString),
        cc: this.ccProp.map(addressToString),
        bcc: this.bccProp.map(addressToString),
        content: this.contentProp,
        htmlContent: html,
        textContent: this.textContentProp,
        subject: this.subjectProp,
        attachments: this.attachmentsProp.map((attachment) => ({
          uploaded: attachment.uploadData.uploadId,
          original: attachment.uploadData.name,
        })),
        priority: this.priorityProp,
        sendSeperate: this.sendSeperateProp,
        meta: {
          createTicket: this.createTicketProp,
          createTicketGroupID: this.createTicketGroupProp,
          createTicketTypeID: this.createTicketTypeProp,
          createTicketStateID: this.createTicketStateProp,
          createTicketCustomerID: this.createTicketCustomerProp,
          seperateTickets: this.seperateTicketsProp,
        },
        quotePrevious: hasQuote,
      };
    },
    submit() {
      if (!this.canSubmit) {
        return;
      }
      this.$emit('submit', this.getData());
    },
    applyTemplateData(originalTemplate, variables) {
      const template = cloneDeep(originalTemplate);
      if (!template.ops) {
        return template;
      }
      template.ops = template.ops.map((op) => {
        if (op.insert && op.insert.template) {
          op.insert = variables[op.insert.template.name] || '';
        } else if (op.insert && op.insert['raw-html']) {
          op.insert['raw-html'] = microTemplate(op.insert['raw-html'], variables);
        }
        return op;
      });
      return template;
    },
    async searchCustomers(search, loading) {
      if (loading) {
        loading(true);
      }

      const { data } = await Api.get('/api/phonebook/contacts', {
        params: { $search: search, types: ['person', 'company'] },
      });
      this.searchedCustomers = data.map((row) => ({
        icon: row.type === 'person' ? 'fas fa-id-card' : 'fas fa-building',
        displayName: row.name,
        typedID: `${row.type}_${row.ID}`,
      }));

      if (loading) {
        loading(false);
      }
    },
  },
  mounted() {
    let quotedContent = null;
    if (this.quotedHtml) {
      quotedContent = {
        type: 'html',
        content: this.quotedHtml,
      };
    } else if (this.quotedText) {
      quotedContent = {
        type: 'text',
        content: this.quotedText,
      };
    }

    if (quotedContent) {
      const content = cloneDeep(this.contentProp);
      if (!content.ops) {
        content.ops = [];
      }
      content.ops.push({
        insert: '\n\n',
      }, {
        insert: {
          'quoted-content': quotedContent,
        },
      },);
      this.contentProp = content;
    }
  },
  computed: {
    toProp: {
      get() {
        return this.toValue;
      },
      set(val) {
        this.toValue = val;
        this.$emit('update:to', val);
      },
    },
    ccProp: {
      get() {
        return this.ccValue;
      },
      set(val) {
        this.ccValue = val;
        this.$emit('update:cc', val);
      },
    },
    bccProp: {
      get() {
        return this.bccValue;
      },
      set(val) {
        this.bccValue = val;
        this.$emit('update:bcc', val);
      },
    },
    subjectProp: {
      get() {
        return this.subjectValue || '';
      },
      set(val) {
        this.subjectValue = val;
        this.$emit('update:subject', val);
      },
    },
    contentProp: {
      get() {
        return this.contentValue || {};
      },
      set(val) {
        this.contentValue = val;
        this.$emit('update:content', val);
      },
    },
    htmlContentProp: {
      get() {
        return this.htmlContentValue || '';
      },
      set(val) {
        this.htmlContentValue = val;
        this.$emit('update:htmlContent', val);
      },
    },
    textContentProp: {
      get() {
        return this.textContentValue || '';
      },
      set(val) {
        this.textContentValue = val;
        this.$emit('update:textContent', val);
      },
    },
    attachmentsProp: {
      get() {
        return this.attachmentsValue;
      },
      set(val) {
        this.attachmentsValue = val;
        this.$emit('update:attachments', val);
      },
    },
    priorityProp: {
      get() {
        return this.priorityValue;
      },
      set(val) {
        this.priorityValue = val;
        this.$emit('update:priority', val);
      },
    },
    createTicketProp: {
      get() {
        return this.createTicketValue;
      },
      set(val) {
        this.createTicketValue = val;
        this.$emit('update:createTicket', val);
      },
    },
    sendSeperateProp: {
      get() {
        return this.sendSeperateValue;
      },
      set(val) {
        this.sendSeperateValue = val;
        this.$emit('update:sendSeperate', val);
      },
    },
    seperateTicketsProp: {
      get() {
        return this.seperateTicketsValue;
      },
      set(val) {
        this.seperateTicketsValue = val;
        this.$emit('update:seperateTickets', val);
      },
    },
    createTicketGroupProp: {
      get() {
        return this.createTicketGroupValue;
      },
      set(val) {
        this.createTicketGroupValue = val;
        this.$emit('update:createTicketGroup', val);
      },
    },
    createTicketTypeProp: {
      get() {
        return this.createTicketTypeValue;
      },
      set(val) {
        this.createTicketTypeValue = val;
        this.$emit('update:createTicketType', val);
      },
    },
    createTicketStateProp: {
      get() {
        return this.createTicketStateValue;
      },
      set(val) {
        this.createTicketStateValue = val;
        this.$emit('update:createTicketState', val);
      },
    },
    createTicketCustomerProp: {
      get() {
        return this.createTicketCustomerValue;
      },
      set(val) {
        this.createTicketCustomerValue = val;
        this.$emit('update:createTicketCustomer', val);
      },
    },
    subjectValid() {
      if (!this.subjectProp) {
        return false;
      }
      return this.subjectProp.trim().length !== 0;
    },
    toValid() {
      return this.toProp.length !== 0;
    },
    canSubmit() {
      return this.toValid && this.subjectValid;
    },
    currentAccountSignature() {
      const account = this
        .availableAccounts
        .find((account) => account.ID === this.accountIDInput);

      if (account.useRawSignature) {
        if (account.signatureRawHtml) {
          const ops = [
            { insert: { 'raw-html': account.signatureRawHtml } },
          ];
          return { ops };
        }
        return null;
      }
      return account.signature;
    },
    formattedAccounts() {
      return this.availableAccounts.map((account) => {
        const acc = { ...account };
        acc.formattedName = acc.displayName || null;
        if (acc.showUserNameInFrom && this.signatureTemplateData) {
          const {
            'agent.firstName': firstName,
            'agent.lastName': lastName,
          } = this.signatureTemplateData;
          const fullName = [firstName, lastName].join(' ').trim();
          if (fullName) {
            if (acc.displayName) {
              acc.formattedName = `${fullName} (${acc.displayName})`;
            } else {
              acc.formattedName = fullName;
            }
          }
        }
        return acc;
      });
    },
    quillOptions() {
      const templateNames = this.templates.map((t) => t.name);
      const templateToolbar = templateNames.length > 0
        ? [{ contentTemplate: templateNames }]
        : [];

      const getTemplates = () => {
        return this.templates;
      };

      return {
        ...quillOptions,
        modules: {
          ...quillOptions.modules,
          toolbar: {
            container: [
              ...quillOptions.modules.toolbar,
              templateToolbar
            ],
            handlers: {
              contentTemplate: function(value) {
                const template = getTemplates().find((t) => t.name === value);
                const quill = this.quill;

                if (!template) {
                  throw new Error(`Content template ${value} not found`);
                }

                const cursorPosition = quill.getSelection().index;
                quill.clipboard.dangerouslyPasteHTML(cursorPosition, template.contentHTML);
              },
            },
          },
        },
      };
    }
  },
  watch: {
    to(val) {
      this.toValue = val;
    },
    cc(val) {
      this.ccValue = val;
    },
    bcc(val) {
      this.bccValue = val;
    },
    subject(val) {
      this.subjectValue = val;
    },
    content(val) {
      this.contentValue = val;
    },
    htmlContent(val) {
      this.htmlContentValue = val;
    },
    textContent(val) {
      this.textContentValue = val;
    },
    attachments(val) {
      this.attachmentsValue = val;
    },
    priority(val) {
      this.priorityValue = val;
    },
    canSubmit: {
      immediate: true,
      handler() {
        this.$emit('update:canSubmit', this.canSubmit);
      },
    },
    createTicketTypeProp: {
      immediate: true,
      handler(ticketTypeID) {
        if (!ticketTypeID) {
          return;
        }

        Api.get(`/api/ticketing/types/${ticketTypeID}/states`).then(({ data: states }) => {
          this.availableTicketStates = states;
        });
      },
    },
    currentAccountSignature: {
      immediate: true,
      handler(sig) {
        const content = cloneDeep(this.contentProp);
        if (!content.ops) {
          content.ops = [];
        }

        const startIdx = content.ops.findIndex(
          (op) => op.insert && op.insert['signature-marker'] === 'begin',
        );
        const endIdx = content.ops.findIndex(
          (op) => op.insert && op.insert['signature-marker'] === 'end',
        );
        if (startIdx !== -1 && endIdx !== -1) {
          const sigOps = endIdx - startIdx;
          content.ops.splice(startIdx, sigOps + 1);
        }

        if (sig && sig.ops) {
          sig = this.applyTemplateData(sig, this.signatureTemplateData || {});
          sig.ops = [
            { insert: '\n' },
            {
              insert: {
                'signature-marker': 'begin',
              },
            },
            ...sig.ops,
            {
              insert: {
                'signature-marker': 'end',
              },
            },
          ];
          const quotedContentIdx = content.ops.findIndex(
            (op) => op.insert && op.insert['quoted-content']
          );
          if (quotedContentIdx === -1) {
            content.ops = content.ops.concat(sig.ops);
          } else {
            content.ops.splice(quotedContentIdx - 1, 0, ...sig.ops);
          }
        }
        this.contentProp = content;
      },
    },
  },
};
</script>
