import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { Translation, TranslationCollection } from './types';
import { Api } from '../api';

i18n.use(resourcesToBackend(async (language, namespace, callback) => {
  if (language === 'raw') {
    callback(null, {});

    return;
  }

  try {
    const { data } = await Api.get<Translation>(`/api/translations/weblate/${namespace}/${language}`);
    callback(null, data);
  } catch (err) {
    callback(err as Error, null);
  }
}));

// Nothing to export here, will be handled by our backend
export const translations: TranslationCollection[] = [];
export const rawLocale = true;
