import { Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  title?: string;
  value: string | null;
  icon?: ReactElement;
  linkTo?: string;
}

export const GenericStaticFormField = ({
  title,
  value,
  icon,

  linkTo,
}: Props) => {
  const linkColor = useColorModeValue('brand.800', 'brand.200');

  return (
    <Flex gap={1} wrap="wrap" alignItems={icon ? 'center' : 'flex-start'}>
      <>
        {!icon ? (
          <>
            <Text whiteSpace="normal" as="span" fontWeight="bold">
              {title}:
            </Text>
          </>
        ) : (
          icon
        )}

        {linkTo ? (
          <Link
            whiteSpace="normal"
            as={RouterLink}
            fontWeight="bold"
            to={linkTo}
            color={linkColor}
          >
            {value}
          </Link>
        ) : (
          <Text whiteSpace="normal" as="span">
            {value}
          </Text>
        )}
      </>
    </Flex>
  );
};
