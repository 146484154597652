import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';

import { getTicketTypes } from '../../js/ticket-types';
import { getTicketGroups, getTicketPriorities, getTicketStates } from '../Api';
import { TicketGroupSettings } from './TicketGroups/types';
import { TicketStateExtended } from './TicketStates/types';
import { BasicSettings, InitialSettings } from './types';

export async function getSettings(): Promise<InitialSettings> {
  const [basicSettings, groups, types, states, priorities] = await Promise.all([
    getBasicSettings(),
    getTicketGroups<TicketGroupSettings[]>({
      fields: [
        'ID',
        'name',
        'defaultEmailAccountID',
        'autoMailToTicket',
        'mailSubjectFormat',
        'stateAfterAgentReply',
        'stateAfterCustomerReply',
        'assignOnUpdate',
        'autoAssignMode',
        'autoMailToTicketOnReply',
        ['defaultTicketType', ['ID', 'name']],
      ],
    }),
    getTicketTypes({
      fields: [
        'ID',
        'name',
        'slug',
        'defaultTab',
        'defaultTicketGroupID',
        'states.state.ID',
        'canHaveDependencies',
        'canHaveWorks',
      ],
    }),
    getTicketStates<TicketStateExtended>(),
    getTicketPriorities(),
  ]);

  return {
    ...basicSettings,
    groups,
    types,
    states,
    priorities,
  };
}

export function useSettings(): UseQueryResult<InitialSettings> {
  return useQuery(['ticketingSettings'], getSettings);
}

async function getBasicSettings(): Promise<BasicSettings> {
  const { data } = await Api.get<BasicSettings>('/api/ticketing/settings');

  return data;
}
