import { Box, Input, ResponsiveValue } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../DateInput/datepicker.css';

import {
  ChangeEvent,
  MutableRefObject,
  ReactElement,
  useCallback,
} from 'react';
import ReactSimpleTimefield from 'react-simple-timefield';

import {
  parseDateishValueToInputValue,
  parseNullishDate,
} from '../../js/dateUtils';
import { useIsMobile } from '../use-is-mobile';

interface Props {
  value: Date | string | null | undefined;
  onChange: (val: Date | null) => void;
  onBlur?: () => void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  inputClass?: string;
  isClearable?: boolean;
  showTime?: boolean;
  autoFocus?: boolean;
  size?: ResponsiveValue<'lg' | 'md' | 'sm' | 'xs'>;
  showIcon?: boolean;
  isDisabled?: boolean;
  withPortal?: boolean;
  minDate?: Date;
}

interface TimeInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

const TimeInput = ({ value, onChange }: TimeInputProps): ReactElement => {
  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(ev.target.value);
      }
    },
    [onChange]
  );

  return (
    <ReactSimpleTimefield
      value={value}
      onChange={handleChange}
      input={
        <Input className="datepicker-time-input" size="sm" value={value} />
      }
    />
  );
};

export const ChakraDateInput = ({
  value,
  onChange,
  onBlur,
  inputClass,
  inputRef,
  isClearable,
  autoFocus,
  showTime = false,
  size = 'md',
  showIcon = false,
  isDisabled = false,
  withPortal = false,
  minDate,
}: Props) => {
  const isMobile = useIsMobile();

  return (
    <Box>
      {!isMobile ? (
        <DatePicker
          popperModifiers={popperModifiers}
          showIcon={showIcon}
          disabled={isDisabled}
          readOnly={isDisabled}
          ref={datepickerRef(inputRef)}
          selected={value ? parseNullishDate(value) : null}
          locale="hr"
          dateFormat={dateFormat(showTime)}
          className={inputClass ?? 'datepicker-input'}
          onBlur={onBlur}
          isClearable={isClearable}
          showTimeInput={showTime}
          autoFocus={autoFocus}
          onChange={(date) => {
            if (date instanceof Date) {
              onChange(date);
            } else if (isClearable && !date) {
              onChange(null);
            }
          }}
          customInput={<Input size={size} />}
          customTimeInput={<TimeInput />}
          withPortal={withPortal}
          minDate={minDate}
        />
      ) : (
        <Input
          value={value ? parseDateishValueToInputValue(value) : undefined}
          onBlur={onBlur}
          type={showTime ? 'datetime-local' : 'date'}
          size={size}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange(parseNullishDate(event.target.value));
          }}
        />
      )}
    </Box>
  );
};

interface DPWithInput {
  input: HTMLInputElement;
}

function datepickerRef(ref?: MutableRefObject<HTMLInputElement | null>) {
  if (!ref) {
    return undefined;
  }

  return (dp: DatePicker | null) => {
    if (ref) {
      ref.current = (dp as unknown as DPWithInput)?.input ?? null;
    }
  };
}

function dateFormat(time: boolean): string {
  return time ? 'dd. MM. yyyy. HH:mm' : 'dd. MM. yyyy.';
}

const popperModifiers = [
  {
    name: 'arrow',
    options: {
      padding: 136,
    },
  },
];
