import { z } from 'zod';

export const personSchema = z.object({
  ID: z.number(),
  fullName: z.string(),
});
export const userSchema = z.object({
  ID: z.number(),
  username: z.string(),
  person: personSchema.nullable(),
});

export type Person = z.infer<typeof personSchema>;
export type User = z.infer<typeof userSchema>;
