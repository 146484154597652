import { render, staticRenderFns } from "./Message.vue?vue&type=template&id=728a63d4&scoped=true&"
import script from "./Message.vue?vue&type=script&lang=js&"
export * from "./Message.vue?vue&type=script&lang=js&"
import style0 from "./Message.vue?vue&type=style&index=0&id=728a63d4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-7e763bbc94/0/cache/vue-loader-npm-15.10.1-3a0b6c19d2-828dd30ec4.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728a63d4",
  null
  
)

export default component.exports