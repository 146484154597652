import { z } from 'zod';

import { ModificationsWithDefaults } from '@bq/components/SettingsCRUD/types';
import { PartialExceptFor } from '@bq/components/type-util';
import { Ticket } from 'app/Modules/Ticketing/Assets';

import { EventData } from '../Cases/Form/Events/types';
import { campaignSchema } from './Campaign';
import { offerModificationsWithDefaultsSchema } from './CaseOffers';
import { caseSubjectSchema } from './CaseSubject';
import { caseTypeSchema } from './CaseType';
import { caseInterestLevelSchema } from './InterestLevel';
import { casePrioritySchema } from './Priority';
import {
  reminderFormSchema,
  reminderSchema,
  remindersCrud,
  remindersCrudWithDefaults,
} from './Reminders';
import { caseStateSchema } from './State';
import { userSchema } from './User';

const salesCustomFields = z.record(z.any());
export type SalesCaseCustomFieldsData = z.infer<typeof salesCustomFields>;

export const salesCaseSchema = z.object({
  ID: z.number(),
  name: z.string(),
  subject: caseSubjectSchema,
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  createdByUser: userSchema,
  assignedToUser: userSchema.nullable(),
  caseType: caseTypeSchema,
  tags: z.array(z.string()),
  campaign: campaignSchema.pick({ ID: true, name: true }),
  state: caseStateSchema,
  priority: casePrioritySchema,
  interestLevel: caseInterestLevelSchema,
  data: salesCustomFields.optional(),
  reminders: z.array(reminderSchema),
});
export type SalesCase = z.infer<typeof salesCaseSchema>;

export const postSalesCaseSchema = z.object({
  name: z.string(),
  subject: caseSubjectSchema.optional(),
  assignedToUserID: z.number().optional().nullable(),
  tags: z.array(z.string()),
  stateID: z.number(),
  priorityID: z.number(),
  interestLevelID: z.number(),
  data: salesCustomFields.optional(),
  offerIds: z.array(z.number()),
  ticketIds: z.array(z.number()),
  reminders: z.array(reminderFormSchema.omit({ ID: true, done: true })),
});

export type PostSalesCase = z.infer<typeof postSalesCaseSchema>;

export const simplePostSalesCaseSchema = postSalesCaseSchema.pick({
  name: true,
  subject: true,
  assignedToUserID: true,
  interestLevelID: true,
  priorityID: true,
  stateID: true,
  tags: true,
});

export type SimplePostSalesCase = z.infer<typeof simplePostSalesCaseSchema>;

export const patchSalesCaseSchema = z
  .object({
    name: z.string(),
    assignedToUserID: z.number(),
    tags: z.array(z.string()),
    stateID: z.number(),
    priorityID: z.number(),
    interestLevelID: z.number(),
    data: salesCustomFields,
    reminders: remindersCrud,
  })
  .partial();

export type PatchSalesCase = z.infer<typeof patchSalesCaseSchema>;

export type PartialSalesCase = Partial<SalesCase>;

export const formSalesCaseSchema = salesCaseSchema
  .pick({
    name: true,
    assignedToUser: true,
    tags: true,
    state: true,
    priority: true,
    interestLevel: true,
    data: true,
  })
  .extend({
    subject: caseSubjectSchema,
    caseOffers: offerModificationsWithDefaultsSchema,
    caseTickets: z.custom<ModificationsWithDefaults<Ticket>>(),
    events: z.custom<EventData[]>().optional(),
    reminders: remindersCrudWithDefaults,
  });

type FormSalesCaseData = z.infer<typeof formSalesCaseSchema>;

export type FormSalesCase = PartialExceptFor<
  FormSalesCaseData,
  | 'name'
  | 'interestLevel'
  | 'state'
  | 'priority'
  | 'caseOffers'
  | 'reminders'
  | 'caseTickets'
>;

export const simpleFormSalesCaseSchema = salesCaseSchema
  .pick({
    name: true,
    assignedToUser: true,
    tags: true,
    state: true,
    priority: true,
    interestLevel: true,
  })
  .extend({ campaign: campaignSchema });

export type SimpleFormSalesCase = z.infer<typeof simpleFormSalesCaseSchema>;

export type CaseBaseInfo = Pick<SalesCase, 'ID' | 'name'>;
