import {
  FieldValue,
  useCustomFormColumns,
} from 'BootQuery/Assets/components/FormEditor';
import { Column, makeSelectionCell } from 'BootQuery/Assets/components/Table';
import { useMemo } from 'react';
import i18n from 'BootQuery/Assets/js/i18n';
import { EmailsCell } from '../EmailsCell';
import { PhoneNumbersCell } from '../PhoneNumbersCell';
import { CompanyLocationApiData } from '../types';

type LocationColumn = Column<CompanyLocationApiData>;

export function columns(): LocationColumn[] {
  return [
    makeSelectionCell(),
    {
      key: 'name',
      title: i18n.t('Phonebook:name'),
    },
    {
      key: 'company',
      title: i18n.t('Phonebook:company'),
      getValue: ({ row }) => row.company.name ?? null,
    },
    {
      key: 'emails',
      title: i18n.t('Phonebook:email_address'),
      Cell: EmailsCell,
    },
    {
      key: 'phoneNumbers',
      title: i18n.t('Phonebook:phone_number'),
      Cell: PhoneNumbersCell,
    },
    {
      key: 'locationMark',
      title: i18n.t('Phonebook:location_mark'),
      getValue: ({ row }) => row.data.locationMark ?? null,
    },
  ];
}

export const companyLocationsCardFeatures = {
  $selected: 'select',
  name: 'name',
  emails: 'emails',
  phoneNumbers: 'phoneNumbers',
};

export function useColumns(customFields: FieldValue[] = []): LocationColumn[] {
  const customColumns = useCustomFormColumns(
    customFields
  ) as unknown as LocationColumn[];

  return useMemo(() => {
    const normalColumns = columns();

    return [...normalColumns, ...customColumns];
  }, [customColumns]);
}
