import { FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CampaignSelect } from '../../Campaigns/Select/CampaignSelect';
import {
  AssignedToUserFormField,
  InterestLevelField,
  PriorityField,
  StateField,
  TagsField,
} from '../Form/FormFields';
import { useCasesBulkFormProvider } from './CasesBulkFormWrapper';
import { useCaseBulkFormController } from './use-bulk-case-form-controller';

export const CaseBulkFormFields = () => {
  const {
    methods: { formState, register },
    ctx: { subjectType },
  } = useCasesBulkFormProvider();
  const { t } = useTranslation('Sales');

  const {
    field: { value: campaignValue, onChange: campaignOnChange },
    fieldState: { error: campaignError },
  } = useCaseBulkFormController('campaign', {
    rules: { required: 'required' },
  });

  const {
    field: { value: interestLevelValue, onChange: interestLevelOnChange },
    fieldState: { error: interestLevelError },
  } = useCaseBulkFormController('interestLevel', {
    rules: { required: 'required' },
  });

  const {
    field: { value: stateValue, onChange: stateOnChange },
    fieldState: { error: stateError },
  } = useCaseBulkFormController('state', {
    rules: { required: 'required' },
  });

  const {
    field: { value: priorityValue, onChange: priorityOnChange },
    fieldState: { error: priorityError },
  } = useCaseBulkFormController('priority', {
    rules: { required: 'required' },
  });

  const {
    field: { value: assignedToUserValue, onChange: assignedToUserOnChange },
    fieldState: { error: assignedToUserError },
  } = useCaseBulkFormController('assignedToUser', {
    rules: { required: 'required' },
  });

  const {
    field: { value: tagsValue, onChange: tagsOnChange },
    fieldState: { error: tagsError },
  } = useCaseBulkFormController('tags', {
    rules: { required: 'required' },
  });

  return (
    <VStack>
      <FormControl mb="2" isInvalid={!!formState.errors.name?.message}>
        <FormLabel fontWeight="bold">
          {t('Sales:case.form.name_begins_with')}:
        </FormLabel>
        <Input
          size={['lg', 'lg', 'sm']}
          {...register('name', { required: 'required' })}
        />
      </FormControl>
      <FormControl mb="2" isInvalid={!!formState.errors.name?.message}>
        <FormLabel fontWeight="bold">{t('Sales:campaign.campaign')}:</FormLabel>
        <CampaignSelect
          value={campaignValue}
          onChange={campaignOnChange}
          isInvalid={!!campaignError?.message}
          subjectType={subjectType}
        />
      </FormControl>
      <FormControl isInvalid={!!formState.errors.state?.message}>
        <FormLabel fontWeight="bold">{t('Sales:case.form.state')}:</FormLabel>
        <StateField
          value={stateValue}
          onChange={stateOnChange}
          error={stateError}
        />
      </FormControl>
      <FormControl isInvalid={!!formState.errors.assignedToUser?.message}>
        <FormLabel fontWeight="bold">
          {t('Sales:case.form.assigned_to')}:
        </FormLabel>
        <AssignedToUserFormField
          value={assignedToUserValue}
          onChange={assignedToUserOnChange}
          error={assignedToUserError}
        />
      </FormControl>
      <FormControl isInvalid={!!formState.errors.interestLevel?.message}>
        <FormLabel fontWeight="bold">
          {t('Sales:case.form.interest_level')}:
        </FormLabel>
        <InterestLevelField
          onChange={interestLevelOnChange}
          value={interestLevelValue}
          error={interestLevelError}
        />
      </FormControl>
      <FormControl isInvalid={!!formState.errors.priority?.message}>
        <FormLabel fontWeight="bold">
          {t('Sales:case.form.priority')}:
        </FormLabel>
        <PriorityField
          onChange={priorityOnChange}
          value={priorityValue}
          error={priorityError}
        />
      </FormControl>

      <FormControl isInvalid={!!formState.errors.tags?.message}>
        <FormLabel fontWeight="bold">{t('Sales:case.form.tags')}:</FormLabel>
        <TagsField
          onChange={tagsOnChange}
          value={tagsValue}
          error={tagsError}
        />
      </FormControl>
    </VStack>
  );
};
