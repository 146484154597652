module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":16,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"card\" id=\"wizard-container\">\n		<div class=\"card-header\">\n			<span class=\"fa fa-download\"></span> Import\n		</div>\n		<div class=\"card-body\">\n			"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.import_complete",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":8,"column":3},"end":{"line":8,"column":33}}}))
    + "\n		</div>\n		<div class=\"card-footer d-flex\">\n			<a class=\"btn btn-primary ml-auto\" href=\"/campaigns/dataset/?dataset="
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"dataset") : stack1), depth0))
    + "\">\n				OK&nbsp;<span class=\"fa fa-arrow-right\"></span>\n			</a>\n		</div>\n	</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":11}}})) != null ? stack1 : "");
},"useData":true})