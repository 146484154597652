module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":45,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"card\">\n            <div class=\"card-header\">\n		<span class=\"fa fa-magic\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.choose_fields",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":70}}}))
    + "\n            </div>\n            <form action=\"/\" method=\"POST\" id=\"campaign-form\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"parameters") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":21}}})) != null ? stack1 : "")
    + "                <div id=\"campaigns-form-editor\">\n                </div>\n            </form>\n            <div class=\"col-6 offset-1 pt-3\">\n                <div class=\"form-group row\">\n                    <label class=\"col-12 col-form-label col-md-6 col-form-label control-label text-left text-md-right\" for=\"add_from_template\">\n                        <span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.add_from_template",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":17,"column":49},"end":{"line":17,"column":80}}}))
    + "</span><span class=\"label-after\">: </span>\n                    </label>\n                    <div class=\"col-12 col-md-6 form-group row\">\n                        <div class=\"col-6\">\n                            <select name=\"add_from_template\" value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" class=\"form-control\" id=\"template-selector\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"templates") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":24,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                        </div>\n                        <div class=\"col-6\">\n                            <button class=\"btn btn-primary\" id=\"add-from-template\">\n                                <span class=\"fa fa-plus\"></span>\n                                "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.add",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":30,"column":32},"end":{"line":30,"column":50}}}))
    + "\n                            </button>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        <div class=\"card-footer text-right\">\n            <button class=\"btn btn-primary btn\" id=\"save-template\" type=\"button\">\n                <span class=\"fa fa-industry\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.create_template",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":38,"column":53},"end":{"line":38,"column":84}}}))
    + "\n            </button>\n            <button class=\"btn btn-success\" id=\"store-campaign\" type=\"button\">\n                <span class=\"fa fa-save\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":41,"column":49},"end":{"line":41,"column":69}}}))
    + "\n            </button>\n        </div>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"hidden\" name=\""
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":46,"column":11}}})) != null ? stack1 : "");
},"useData":true})