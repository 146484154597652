<template>
	<span>
		<template v-if="queue">
			<span class="fas fa-sitemap"></span>
			{{queue.name}} (
		</template>
		{{ phoneNumberE164 | formatNumber }}
		<template v-if="queue">)</template>
	</span>
</template>

<script>
export default {
  props: {
    phoneNumberE164: String,
    contacts: Array,
    queue: Object,
  },
};
</script>
