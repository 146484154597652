module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog modal-lg\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\"modal-title\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.add_reminder",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":67}}}))
    + "</h5>\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n                    <span aria-hidden=\"true\">&times;</span>\n                </button>\n            </div>\n            <div class=\"modal-body\">\n                <div class=\"form-group row\">\n                    <label class=\"col-12 col-form-label col-md-4 col-form-label control-label text-left text-md-right\" for=\"campaigns-reminder-timestamp\">\n                        <span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.reminder_timestamp",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":13,"column":49},"end":{"line":13,"column":81}}}))
    + "</span><span class=\"label-after\">: </span>\n                    </label>\n                    <div class=\"col-12 col-md-8\">\n                        <input type=\"text\" name=\"reminder_name\" id=\"campaigns-reminder-timestamp\" class=\"form-control datetimepicker\" required>\n                    </div>\n                </div>\n\n                <div class=\"form-group row\">\n                    <label class=\"col-12 col-form-label col-md-4 col-form-label control-label text-left text-md-right\" for=\"campaigns-reminder-body\">\n                        <span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.reminder_body",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":22,"column":49},"end":{"line":22,"column":76}}}))
    + "</span><span class=\"label-after\">: </span>\n                    </label>\n                    <div class=\"col-12 col-md-8\">\n                        <textarea name=\"reminder_body\" id=\"campaigns-reminder-body\" class=\"form-control\" required></textarea>\n                    </div>\n                </div>\n            </div>\n            <div class=\"modal-footer\">\n                <button class=\"btn btn-primary float-right\" id=\"campaigns-add-reminder\">\n                    <span class=\"fa fa-save\"></span>\n                    "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":32,"column":40}}}))
    + "\n                </button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true})