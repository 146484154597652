module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"buttonsAfter",{"name":"content","hash":{"mode":"append"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":20,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"is_dataset")),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"btn-group\">\n            <a href=\"/campaigns/editDataset?datasetID="
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset")), depth0))
    + "\" class=\"btn btn-secondary\">\n                <span class=\"fas fa-cog\"></span>\n            </a>\n        </div>\n        <div class=\"btn-group\">\n            <a href=\"/campaigns/addToCampaign/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters")),{"name":"encode_query_string","hash":{},"data":data,"loc":{"start":{"line":10,"column":47},"end":{"line":10,"column":97}}}))
    + "&dataset_count="
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" class=\"btn btn-secondary\">\n                <span class=\"fas fa-link\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias3,"button.add_to_campaign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":11,"column":50},"end":{"line":11,"column":81}}}))
    + "\n            </a>\n        </div>\n        <div class=\"btn-group\">\n            <a href=\"/campaigns/create/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters")),{"name":"encode_query_string","hash":{},"data":data,"loc":{"start":{"line":15,"column":40},"end":{"line":15,"column":90}}}))
    + "&dataset_count="
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" class=\"btn btn-primary\">\n                <span class=\"fas fa-plus\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias3,"button.create_campaign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":16,"column":50},"end":{"line":16,"column":81}}}))
    + "\n            </a>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"global.headerButtons",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":11}}})) != null ? stack1 : "");
},"useData":true})