import { AsyncSelect, SingleValue } from 'chakra-react-select';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { loadOptions, loadValueFromID } from './utils';

interface Props {
  onChange: (newValue: SingleValue<FilterOption>) => void;
  value: FilterOption | number | null;
  isDisabled?: boolean;
}
export interface FilterOption {
  label: string;
  value: number;
}
export const GroupSelect = ({ onChange, value, isDisabled }: Props) => {
  const valueIsNumber = typeof value === 'number';
  const { data: loadedData } = useQuery(
    ['groupSelect-load-ID', value],
    async () => {
      if (valueIsNumber) {
        const data = await loadValueFromID(value);

        return { label: data.name, value: data.ID };
      }
      throw Error('Unkown error');
    },
    { enabled: valueIsNumber }
  );

  const parsedValue = useMemo(() => {
    return valueIsNumber ? loadedData ?? null : value;
  }, [loadedData, value, valueIsNumber]);

  return (
    <AsyncSelect<FilterOption>
      isDisabled={isDisabled}
      useBasicStyles
      size={{ base: 'lg', md: 'sm', lg: 'sm' }}
      cacheOptions
      defaultOptions
      onChange={(newVal) => {
        if (newVal?.value !== parsedValue?.value) {
          onChange(newVal);
        }
      }}
      value={parsedValue}
      loadOptions={loadOptions}
      selectedOptionColorScheme="brand"
    />
  );
};
