import { useMemo } from 'react';

import { ContactType } from 'app/Modules/Phonebook/Assets/js/types';

import { CaseType } from '../../../../schemas';

export const useSubjectFieldAllowedTypes = (
  type?: CaseType
): Record<ContactType, boolean> => {
  const allowedTypes = useMemo((): Record<ContactType, boolean> => {
    switch (type?.subjectType) {
      case 'person':
        return {
          company: false,
          companyDepartment: false,
          companyLocation: false,
          person: true,
          user: false,
        };
      case 'company':
        return {
          company: true,
          companyDepartment: false,
          companyLocation: false,
          person: false,
          user: false,
        };
      // TODO: Dataset case 'dataset':
      default:
        return {
          company: false,
          companyDepartment: false,
          companyLocation: false,
          person: false,
          user: false,
        };
    }
  }, [type?.subjectType]);

  return allowedTypes;
};
