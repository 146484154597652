import { BrandColors } from 'BootQuery/Assets/js/brand-colors';
import {
  drawerTheme,
  modalTheme,
  popoverTheme,
  menuTheme,
  tooltipTheme,
} from './Overlay';
import { inputTheme } from './FormElements/InputConfig';

export const componentsConfig = (brandColors: BrandColors) => ({
  Drawer: drawerTheme(brandColors),
  Modal: modalTheme(brandColors),
  Popover: popoverTheme(brandColors),
  Menu: menuTheme(brandColors),
  Tooltip: tooltipTheme(brandColors),
  Input: inputTheme(brandColors),
});
