import { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';

import { PopoverLink } from 'BootQuery/Assets/components/PopoverTrigger';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

import { ApiEmail } from './types';

interface EmailListProps {
  emails: ApiEmail[];
}

export const EmailList = ({ emails }: EmailListProps): ReactElement => (
  <Flex zIndex={3}>
    <WithSeparator separator=",&nbsp;">
      {emails.map(({ email: { email } }) => (
        <PopoverLink
          fontWeight="normal"
          key={email}
          as="a"
          href={`mailto:${email}`}
          title={email}
        >
          {email}
        </PopoverLink>
      ))}
      &nbsp;
    </WithSeparator>
  </Flex>
);
