import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, TableRowProps } from '@chakra-ui/react';
import { difference, isEqual, uniq } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { ExportModalProvider } from '@bq/components/Export';
import { NoDataMessage } from '@bq/components/NoDataMessage';
import { defaultDisplayModeOptions } from '@bq/components/TableMenu/default-display-mode';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';
import { Table } from 'BootQuery/Assets/components/Table';
import { ColumnMap } from 'BootQuery/Assets/components/Table/types';
import { useVisibleColumns } from 'BootQuery/Assets/components/Table/util';
import {
  RefreshMethod,
  TableDensity,
} from 'BootQuery/Assets/components/TableMenu';
import { useFilters } from 'BootQuery/Assets/js/use-filters';
import { usePage } from 'BootQuery/Assets/js/use-page';
import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

import { generateExport } from '../api';
import { CallListPermissions } from '../call-list-permissions';
import { CallListContactModalProvider } from '../CallListContactModalProvider';
import { CallListHeading } from '../CallListHeading';
import { useTableColumns } from '../columns';
import { useFilterTypes } from '../filter-types';
import { formatCall } from '../format-call';
import { PageWithPlayer } from '../RecordingPlayer';
import { CallListCall } from '../types';
import { useCalls } from '../use-calls';
import { useDisplayMode } from '../use-display-mode';
import { useSelectedOverview } from '../use-selected-overview';
import { CallsTableFooter } from './CallsTableFooter';

interface Props {
  overviews: IOverviewEditorItem[];
  permissions: CallListPermissions;
  customFields: FieldValue[];
}

export const CallsTable = ({
  overviews,
  permissions,
  customFields,
}: Props): ReactElement => {
  const [page, setPage] = usePage('query');
  const filterTypes = useFilterTypes(customFields);
  const [filters, setFilters] = useFilters(filterTypes, 'query');
  const overview = useSelectedOverview(overviews);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [visibleColumns, setVisibleColumns] = useColumnVisibility();
  const [density, setDensity] = useDensity();
  const [limit, setLimit] = useLimit();
  const [refreshMethod, setRefreshMethod] = useRefreshMethod();
  const prevRowIds = useRef<string[] | null>(null);
  const [highlightedRows, setHighlightedRows] = useState<string[]>([]);
  const [count, setCount] = useState<number>(0);

  // Never remove the useTranslation to force Telephony translations to load
  const { t } = useTranslation('Telephony');

  const columns = useTableColumns(customFields);
  const columnsToShow = useVisibleColumns(columns, visibleColumns);

  const overviewFilters = useMemo(() => overview?.filters ?? [], [overview]);
  const allFilters = useMemo(
    () => [...overviewFilters, ...filters],
    [filters, overviewFilters]
  );

  const { calls, status, refetch, shownCallIds, newRowIds, filterExpr } =
    useCalls({
      page,
      limit,
      filters: allFilters,
      filterTypes,
      refreshMethod,
    });

  useEffect(() => {
    const rowDiff = prevRowIds.current
      ? difference(shownCallIds, prevRowIds.current)
      : [];
    const newHighlighted = uniq([...highlightedRows, ...rowDiff]);
    prevRowIds.current = shownCallIds;
    if (!isEqual(newHighlighted, highlightedRows)) {
      setHighlightedRows(newHighlighted);

      // Clear highlight after 1s
      setTimeout(() => {
        setHighlightedRows(
          highlightedRows.filter((hl) => !newHighlighted.includes(hl))
        );
      }, 1000);
    }

    return undefined;
  }, [shownCallIds, highlightedRows, setHighlightedRows]);
  const trProps = useCallback(
    (row: CallListCall): TableRowProps => ({
      bg: highlightedRows.includes(row.callId) ? 'green.100' : undefined,
      transition: 'background-color 0.5s ease-out',
    }),
    [highlightedRows]
  );

  const rowCount = calls?.meta.count ?? null;
  useEffect(() => {
    if (rowCount) {
      setCount(rowCount);
    }
  }, [rowCount]);
  let rows: CallListCall[] = [];
  if (status === 'success' && calls) {
    rows = calls.data.map(formatCall);
  }

  const footer = useMemo(
    () => <CallsTableFooter count={rowCount} />,
    [rowCount]
  );
  if (!rows) {
    return <LoadingPage />;
  }

  return (
    <PageWithPlayer>
      <CallListContactModalProvider>
        <ExportModalProvider
          onExport={generateExport}
          rowCount={rowCount ?? 0}
          filterExpression={filterExpr ?? []}
        >
          <Box mt={9} mb={10} px={5}>
            <CallListHeading
              count={count}
              limit={limit}
              filterProps={{
                filterTypes,
                filters,
                setFilters,
              }}
              menuProps={{
                visibleColumns: {
                  columns,
                  value: visibleColumns,
                  onChange: setVisibleColumns,
                },
                density: { value: density, onChange: setDensity },
                limit: { value: limit, onChange: setLimit },
                displayMode: {
                  value: displayMode,
                  onChange: setDisplayMode,
                  options: defaultDisplayModeOptions,
                },
                refreshMethod: {
                  value: refreshMethod,
                  onChange: setRefreshMethod,
                },
              }}
              page={page}
              setPage={setPage}
              permissions={permissions}
              newRows={newRowIds.length}
              isLoading={status === 'loading'}
              refetch={refetch}
              withExport
            />
          </Box>
          {status !== 'loading' && !rows.length ? (
            <NoDataMessage>{t('global:no_results')}</NoDataMessage>
          ) : (
            <Table<CallListCall>
              rowKey="ID"
              columns={columnsToShow}
              rows={rows}
              isLoading={status === 'loading'}
              placeholderRows={limit}
              footer={footer}
              size={density}
              rowProps={trProps}
            />
          )}
        </ExportModalProvider>
      </CallListContactModalProvider>
    </PageWithPlayer>
  );
};

function useColumnVisibility(defaultVal: ColumnMap = {}) {
  return useUserSetting('Telephony.CallList.table.columns', defaultVal, false);
}
const useDensity = (defaultDensity: TableDensity = 'md') => useUserSetting('Telephony.CallList.table.density', defaultDensity, false);
const useLimit = (defaultLimit = 10) => useUserSetting('Telephony.CallList.table.limit', defaultLimit, false);
const useRefreshMethod = (defaultMethod: RefreshMethod = 'auto') => useUserSetting(
  'Telephony.CallList.table.refreshMethod',
  defaultMethod,
  false
);
