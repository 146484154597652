<template>
    <span v-if="phoneNumber">
        {{ phoneNumber.phoneNumberE164 | formatNumber }}
    </span>
    <span v-else>{{ displayName }}</span>
</template>

<script>
export default {
  props: {
    phoneNumber: Object,
    displayName: String,
  },
};
</script>
