<template>
	<div
		class="mail-event-wrapper"
		:data-mail-event-mail-id="message.ID"
		:data-mail-event-root-id="message.rootID"
		:class="{
			'mail-event-collapsed': collapsible && collapsed,
		}"
	>
		<Message
			ref="message"
			v-bind="message"
			:downloadable="message.rawMessagePath !== null"
			@reply="onReply"
			@forward="onForward"
			cardClass="my-3"
		>
			<template slot="title" slot-scope="{ subject, isHighPriority }">
				<div class="card-title">
					<span class="fas fa-envelope"></span>
					{{ subject }}
					<span v-if="isHighPriority" class="fas fa-exclamation text-danger"></span>
				</div>
			</template>

			<template slot="content" slot-scope="{ htmlBody, textBody }">
				<div class="mail-event-content-outer">
					<div class="mail-event-content">
						<HTMLMessage v-if="htmlBody" :html="htmlBody" />
						<TextMessage v-else :text="textBody" />
					</div>
					<a
						v-if="collapsible"
						href="#"
						:class="collapserClass"
						@click.prevent="toggleCollapse"
					>
						{{ collapserText }}
						<span :class="collapserIcon"></span>
					</a>
				</div>
			</template>
		</Message>
	</div>
</template>

<script>
import Message from 'app/Modules/Mails/Assets/components/Message.vue';
import HTMLMessage from 'app/Modules/Mails/Assets/components/HTMLMessage.vue';
import TextMessage from 'app/Modules/Mails/Assets/components/TextMessage.vue';

export default {
  components: {
    Message,
    HTMLMessage,
    TextMessage,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      collapsed: false,
      collapsible: false,
    };
  },
  computed: {
    message() {
      return {
        ...this.data,
        date: new Date(this.data.date),
      };
    },
    collapserText() {
      if (this.collapsed) {
        return this.tr('Campaigns:button.show');
      }
      return this.tr('Campaigns:button.hide');
    },
    collapserIcon() {
      const dir = this.collapsed ? 'down' : 'up';
      return `fas fa-arrow-${dir}`;
    },
    collapserClass() {
      if (this.collapsed) {
        return 'mail-content-show';
      }
      return 'mail-content-hide';
    },
    module() {
      return window.BootQuery.getModuleInstance('Campaigns');
    },
  },
  methods: {
    onReply(type) {
      this.module.replyMail(type, this.message);
    },
    onForward() {
      this.module.forwardMail(this.message);
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
  },
  mounted() {
    // Just pretend you didn't see this...
    const contentEl = this.$refs.message.$el.querySelector('.card-body');
    const eventsEl = document.querySelector('#campaign-contact-events');

    eventsEl.style.display = 'block';
    const contentHeight = contentEl.scrollHeight;
    eventsEl.style.display = '';

    if (contentHeight > 100) {
      this.collapsible = true;
      this.collapsed = true;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .mail-event-content-outer {
	position: relative;
}

::v-deep .mail-event-content {
	transition: height 0.5s;
	text-overflow: ellipsis;
	overflow-y: hidden;
}

.mail-event-collapsed ::v-deep .mail-event-content {
	max-height: 100px;
	transition: height 0.5s;
}

.mail-content-hide,
.mail-content-show {
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	text-align: center;
}
</style>
