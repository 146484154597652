import { z } from 'zod';

import { caseTypeSchema } from './CaseType';
import { userSchema } from './User';

export type CaseCampaign = Pick<Campaign, 'ID' | 'name'>;

export const campaignSchema = z.object({
  ID: z.number(),
  name: z.string(),
  createdAt: z.string().datetime(),
  createdByUser: userSchema,
  caseType: caseTypeSchema,
});

export type Campaign = z.infer<typeof campaignSchema>;

export const formCampaignSchema = campaignSchema.pick({
  name: true,
  caseType: true,
});

export type FormCampaign = z.infer<typeof formCampaignSchema>;

export const postCampaignSchema = campaignSchema
  .pick({ name: true })
  .extend({ caseTypeID: z.number() });

export type PostCampaign = z.infer<typeof postCampaignSchema>;

export const patchCampaignSchema = campaignSchema.pick({ name: true });
export type PatchCampaign = z.infer<typeof patchCampaignSchema>;
