/* eslint-disable max-classes-per-file */
import Module from 'BootQuery/Assets/js/module';
import FormEditor from 'BootQuery/Assets/js/form-editor';
import Field from 'BootQuery/Assets/js/form-editor/fields/field';
import SelectField from 'BootQuery/Assets/js/form-editor/fields/select';
import ListField from 'BootQuery/Assets/js/form-editor/fields/list';
import FieldFactory from 'BootQuery/Assets/js/form-editor/field-factory';

import Vue from 'BootQuery/Assets/js/vue';

import {
  merge, get, forEach, cloneDeep,
} from 'lodash-es';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import {
  activateElements,
  getJSON,
  renderController,
  renderTemplate,
  getTemplate,
  getFormData,
  renderControllerFromData,
} from 'BootQuery/Assets/js/BootQuery';
import tr from 'BootQuery/Assets/js/translate';
import createModal from 'app/assets/js/modals';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import ContactEvents from '../components/ContactEvents.vue';

function calculatePrice(saleForm, isInitial = false) {
  const productSelect = saleForm.find('select[name="sale[productID]"]');
  const amountInput = saleForm.find('input[name="sale[amount]"]');
  const priceInput = saleForm.find('input[name="sale[price]"]');
  const price = priceInput.val();

  // Don't recalculate price on initial open for editing form
  if (isInitial && price) {
    return;
  }

  const selectedProduct = productSelect.pickle('option', productSelect.val());
  const amount = parseFloat(amountInput.val());
  if (selectedProduct && amount) {
    const productPrice = parseFloat(selectedProduct.rowData.price);
    const priceVal = (productPrice * amount).toFixed(2);
    priceInput.val(priceVal);
  }
}

function getTableSelection(tableName) {
  const selection = [];
  const tableEl = $(`#${tableName}-table`);
  const rowMatch = new RegExp(`^${tableName}-rowselect-(\\d+)`);
  Object.entries(getFormData(tableEl)).forEach(([name, val]) => {
    const match = name.match(rowMatch);
    if (match && val === 'true') {
      selection.push(parseInt(match[1], 10));
    }
  });
  return selection;
}

function findCampaignsTab(data) {
  return (data.result.tabs || [])
    .find(({ key }) => key === 'campaigns');
}

class CampaignFormEditor extends FormEditor {
  async render(target) {
    await super.render(target);

    setTimeout(() => {
      target.findElement('input[type="checkbox"]').ev('change.campaigns', (e) => {
        const row = $(e.currentTarget).closest('.row');
        row.find('input,button')
          .not(e.currentTarget)
          .each((_, el) => {
            $(el).prop('disabled', !$(e.currentTarget).prop('checked'));
          });

        row.find('span[contenteditable]').each((_, el) => {
          $(el).attr('contenteditable', $(e.currentTarget).prop('checked'));
        });

        row.find('label').removeClass('text-muted');
        if (!$(e.currentTarget).prop('checked')) {
          row.find('label').addClass('text-muted');
        }
      });
    }, 0);
  }

  constructField(optionsOrType) {
    let options = optionsOrType;
    if (typeof optionsOrType === 'string') {
      options = {
        type: optionsOrType,
        use_in_campaign: true,
        readonly: false,
        visible_to_agents: true,
        is_basic: false,
        is_scenario: true,
        new: true,
      };
    }

    return this.fieldFactory.constructField(options);
  }
}

class CampaignField extends Field {
  processSettings(settings) {
    return processCampaignSettings(settings);
  }

  async bindEvents() {
    await super.bindEvents();

    this.field.find('input[name="use_in_campaign"]').ev('change', (e) => {
      const row = $(e.currentTarget).closest('.row');
      const settings = merge({}, this.options, getFormData(row));
      this.emit('settingsChange', settings);
    });
  }
}

class CampaignSelect extends SelectField {
  processSettings(settings) {
    return processCampaignSettings(settings);
  }
}

class CampaignList extends ListField {
  processSettings(settings) {
    console.log('Settigns: ', settings);
    return processCampaignSettings(settings);
  }
}

function processCampaignSettings(settings) {
  if (typeof settings.mandatory !== 'boolean') {
    settings.mandatory = settings.mandatory === 'true';
  }
  if (typeof settings.readonly !== 'boolean') {
    settings.readonly = settings.readonly === 'true';
  }
  if (typeof settings.visible_to_agents !== 'boolean') {
    settings.visible_to_agents = settings.visible_to_agents === 'true';
  }
  if (typeof settings.use_in_campaign !== 'boolean') {
    settings.use_in_campaign = settings.use_in_campaign === 'true';
  }
  if (typeof settings.use_in_campaign !== 'boolean') {
    settings.use_in_campaign = settings.use_in_campaign === 'true';
  }

  settings[`is_${settings.data_type}`] = true;

  return settings;
}

function updateContactAssignmentFields(target) {
  const assignType = $('select[name=assigntype]').val();
  const staticContainer = $('#assignment-mode-static');
  const dynamicContainer = $('#assignment-mode-dynamic');

  if (assignType === 'dynamic') {
    staticContainer.prop('hidden', true);
    staticContainer.find('input').prop('disabled', true);

    dynamicContainer.prop('hidden', false);
    dynamicContainer.find('input').prop('disabled', false);

    const dynMode = dynamicContainer
      .find('[name=assignment_mode_dynamic]:checked')
      .val();
    dynamicContainer
      .find('[name=assign_dynamic_amount]')
      .prop('disabled', dynMode !== 'limited');
  } else {
    staticContainer.prop('hidden', false);
    staticContainer.find('input').prop('disabled', false);

    dynamicContainer.prop('hidden', true);
    dynamicContainer.find('input').prop('disabled', true);

    const staticMode = staticContainer
      .find('[name=assignment_mode_static]:checked')
      .val();
    staticContainer
      .find('[name=assign_static_amount]')
      .prop('disabled', staticMode !== 'spread');
    staticContainer
      .find('[name=assign_static_per_agent_amount]')
      .prop('disabled', staticMode !== 'per_agent');
  }
}

function updateContactEventCount(newCount, waitIfMissing = true) {
  const countBadge = document.querySelector('.campaign-contact-events-count');
  if (countBadge) {
    countBadge.innerHTML = newCount;
  } else if (waitIfMissing) {
    // Maybe it hasn't been rendered to DOM yet, so wait 1 event loop cycle and try again
    setTimeout(() => updateContactEventCount(newCount, false), 0);
  }
}

export default class Campaigns extends Module {
  constructor() {
    super();
    this.changeTimer = 0;
    this.extension = window.Bootstrap.bootquery.session.extension;
    this.extensionState = {};
    this.campaign = null;
    this.userID = window.Bootstrap.bootquery.session.userID;
    this.contactByNum = {};
    this.contacts = {};

    this.contactEventsState = new Vue({
      data: {
        events: null,
        countVal: null,
        campaignID: null,
        contactID: null,
      },
      computed: {
        count: {
          get() {
            return this.countVal;
          },
          set(newCount) {
            this.countVal = newCount;
            updateContactEventCount(newCount);
          },
        },
      },
    });
    this.contactEventsVue = null;
  }

  init(data) {
    super.init();

    this.socketEvents.subscribeWebSocket(
      `Campaigns/nextContact/${this.extension}`,
      this.onNextContact.bind(this),
    );
    this.socketEvents.subscribeWebSocket(
      `Campaigns/contactChange/${this.extension}`,
      this.onContactChange.bind(this),
    );
    this.socketEvents.subscribeWebSocket('Campaigns/contactCalled', this.onContactCalled.bind(this));
    this.socketEvents.subscribeWebSocket('PeerStatusChanged', this.onPeerStatusChanged.bind(this));
    this.socketEvents.subscribeWebSocket('CallEnd', this.onCallEnd.bind(this));
    this.socketEvents.subscribeWebSocket('Campaigns/importProgress', this.onImportProgress.bind(this));

    const { userID } = window.Bootstrap.bootquery.session;
    Api.get(`/api/campaigns/agents/${userID}/campaigns`).then((data) => {
      this.campaign = data;
    });
  }

  get provides() {
    return {
      numberContactInfo: {
        getContactInfo: this.getContactInfoProvider.bind(this),
        priority: 20,
      },
    };
  }

  async getContactInfoProvider(phoneNumber) {
    const contact = await this.getContactByNumber(phoneNumber);

    if (contact) {
      return {
        link: `/campaigns/contact/?id=${contact.ID}`,
        icon: 'fas fa-bullhorn',
        displayName: contact.$displayName || null,
      };
    }

    return null;
  }

  async getContactByNumber(number) {
    if (!this.contactByNum[number]) {
      this.contactByNum[number] = await Api.get(
        `/api/campaigns/contactsByNumber/${number}`
      );
    }
    return this.contactByNum[number];
  }

  upload(button) {
    const form = $(button).closest('form')[0];
    const formData = new FormData(form);
    $(form)
      .find('.upload-label')
      .html(
        `<span class="fa fa-spinner fa-spin"></span>&nbsp;Uploading ${
          formData.get('file').name
        }`,
      );

    $.ajax({
      type: 'POST',
      url: '/api/upload',
      data: formData,
      xhr: () => {
        const myXhr = $.ajaxSettings.xhr();
        if (myXhr.upload) {
          myXhr.upload.addEventListener(
            'progress',
            (e) => {
              if (e.lengthComputable) {
                const max = e.total;
                const current = e.loaded;

                const percentage = (current * 100) / max;
                $(form)
                  .find('.progress-bar')
                  .css({ width: `${percentage}%` });

                if (percentage >= 100) {
                  // console.log('Done!');
                }
              }
            },
            false,
          );
        }
        return myXhr;
      },
      cache: false,
      contentType: false,
      processData: false,

      success: (data) => {
        $(form)
          .find('.upload-label')
          .html(`<span class="fa fa-check"></span>&nbsp;${data[0].name}`);
        $(form)
          .find('.upload-delete')
          .removeAttr('disabled');
        $(form)
          .find('input[type="file"]')
          .data('name', data[0].name);
        $(form)
          .find('input[type="file"]')
          .data('tmpName', data[0].tmpName);
      },

      error: (data) => {
        console.error('Error uploading file:', data);
      },
    });
  }

  handleDataUpload(target) {
    // Handle uploads
    target.findElement('input[type="file"]').ev('change', (e) => {
      e.preventDefault();
      this.upload(e.currentTarget);
    });

    target
      .findElement('.upload-delete')
      .ev('click', (e) => {
        e.preventDefault();

        const tmpName = $(e.currentTarget)
          .closest('.upload')
          .find('input[type="file"]')
          .data('tmpName');

        $.ajax({
          type: 'POST',
          url: '/api/upload',
          data: { action: 'delete', tmpName },

          success: (data) => {
            if (data.status == 'success') {
              $(e.currentTarget)
                .closest('.upload')
                .find('.upload-label')
                .html(
                  `<span class="fa fa-cloud-upload"></span> ${tr(
                    'button.upload',
                  )}`,
                );
              $(e.currentTarget)
                .closest('.upload')
                .find('.upload-delete')
                .attr('disabled', true);
              $(e.currentTarget)
                .closest('.upload')
                .find('.progress-bar')
                .css({ width: 0 });
            }
          },

          error: (data) => {
            console.error('Error deleting file:', data);
          },
        });
      });
  }

  renderImportWizard(target, data, targetColumn) {
    let targetColumnIndex = -1;

    if ($(targetColumn).closest('th').length) {
      targetColumnIndex = parseInt(
        $(targetColumn)
          .closest('th')
          .data('columnIndex'),
        10,
      );
    }

    if (target.findElement('[id^=settings]').length > 0) {
      const submitData = {
        settings: {
          delimiter: target.findElement('#settings\\[delimiter\\]').val(),
          enclosure: target.findElement('#settings\\[enclosure\\]').val(),
          encoding: target.findElement('#settings\\[encoding\\]').val(),
          header: target.findElement('#settings\\[header\\]')[0].checked,
          columns: [],
        },
        file: data.file,
        dataset_name: data.dataset_name,
      };

      $('.type-selector').each((idx, el) => {
        if (submitData.settings.columns[idx] == undefined) {
          submitData.settings.columns[idx] = {};
        }

        submitData.settings.columns[idx].type = $(el).val();
      });

      $('.label-input').each((idx, el) => {
        if (submitData.settings.columns[idx] == undefined) {
          submitData.settings.columns[idx] = {};
        }

        submitData.settings.columns[idx].label = $(el).val();
      });

      getJSON('post', 'campaigns', 'processData', submitData, true, async (importData) => {
        const newContent = $.render(
          await getTemplate('processData', 'Campaigns'),
          importData,
        );

        if (targetColumnIndex >= 0) {
          const columnContents = newContent
            .findElement('#preview-table')
            .findElement(`td[data-column-index=${targetColumnIndex}]`);
          const replaceCells = $('#preview-table').findElement(
            `td[data-column-index=${targetColumnIndex}]`,
          );

          forEach(replaceCells, (cell, index) => {
            $(cell).html(columnContents[index].innerHTML);
          });
        } else {
          // jQuery, y I need to do dis, I don't even
          $('#wizard-container').html(
            newContent.findElement('#wizard-container')[0].innerHTML,
          );
          // this.activateElements('#wizard-container');
          activateElements('#wizard-container');
        }
      });
    }
  }

  async nextContact(campaignID = null) {
    if (!campaignID) {
      ({ campaignID } = window.Bootstrap.result);
    }

    const nextContactID = await Api.get(`/api/campaigns/${campaignID}/nextContact`);

    const controller = 'campaigns';
    const parameters = nextContactID ? { id: nextContactID } : { campaignID };
    const method = nextContactID ? 'contact' : 'done';
    const query = nextContactID ? `?id=${nextContactID}` : `?campaignID=${campaignID}`;

    window.history.pushState(
      { parameters, controller, method },
      null,
      `/${controller}/${method}/${query}`,
    );
    renderController('get', controller, method, parameters, 'Campaigns');
  }

  activateElements(target, data) {
    this.activateFormEditor(target, data);
    this.handleDataUpload(target);

    const campaignForm = target.findElement('#campaigns-contact-form');
    if (campaignForm.length) {
      this.campaign = campaignForm.find('input[name=campaignID]').val();
    }

    target
      .findElement(
        '#settings\\[header\\], #settings\\[delimiter\\], #settings\\[encoding\\], .type-selector',
      )
      .ev('change', (e) => {
        this.renderImportWizard(target, data.result, e.currentTarget);
      });

    const campaignSelect = target.findElement('#campaign-select');
    campaignSelect.pickle({
      results(searchString, results, callback) {
        const filters = {
          name_like_ci: searchString,
        };
        const datasetId = campaignSelect.data('datasetId');
        if (datasetId) {
          filters.usedDatasetID_eq = datasetId;
        }
        Api.get('/api/campaigns/', { filters }).then((res) => {
          const options = res.map((r) => ({ id: r.ID, text: r.name }));

          callback(options, searchString.length);
        });
      },
    });

    const campaignSaleFormSelect = target.findElement('#campaign-select-sale-form');
    campaignSaleFormSelect.pickle({
      results(searchString, results, callback) {
        const filters = {
          name_like_ci: searchString,
        };
        Api.get('/api/campaigns/saleForms', { filters }).then((res) => {
          const options = res.map((r) => ({ id: r.ID, text: r.name }));
          options.unshift({ id: 'null', text: '' });

          callback(options, searchString.length);
        });
      },
    });

    target.findElement('#campaign-make-call').ev('click', (e) => {
      e.preventDefault();

      $('#campaign-pause').prop('disabled', false);
      $('#pause-notice').prop('hidden', true);
      Api.post('/api/campaigns/makeCall/', $(e.currentTarget).data()).then(() => {
        getJSON(
          'post',
          'campaigns',
          'contact',
          { id: $(e.currentTarget).data('contact') },
          true,
          (contactData) => {
            renderControllerFromData(contactData, 'Campaigns');
          },
        );
      });
    });

    target.findElement('#campaign-next-contact').ev('click.campaigns', (e) => {
      e.preventDefault();

      const $btn = $(e.currentTarget);
      $btn.prop('disabled', true);

      let campaignID = parseInt($btn.data('campaign'), 10);
      if (!campaignID) {
        campaignID = null;
      }
      this.nextContact(campaignID);
    });

    target.findElement('#campaign-pause').ev('click.campaigns', async (e) => {
      e.preventDefault();

      const { campaignID } = window.Bootstrap.result;
      const agentID = window.Bootstrap.bootquery.session.userID;
      await Api.post(`/api/campaigns/${campaignID}/agents/${agentID}/pause`);

      $(e.currentTarget).prop('disabled', true);
      $('#pause-notice').prop('hidden', false);
    });

    target.findElement('#campaign-skip-contact').ev('click.campaigns', async (e) => {
      e.preventDefault();

      const $btn = $(e.currentTarget);
      $btn.prop('disabled', true);

      const { contactID, campaignID } = window.Bootstrap.result;
      await Api.put(`/api/campaigns/${campaignID}/contacts/${contactID}/skipped`);
      this.nextContact();
    });

    target.findElement('#store-campaign').ev('click', (e) => {
      e.preventDefault();

      const formDefinition = this.formEditor.getDefinition();
      const submitData = {
        form: formDefinition,
        parameters: data.bootquery.parameters,
      };

      getJSON('post', 'campaigns', 'saveCampaign', submitData, true, (importData) => {
        renderControllerFromData(importData, 'Campaigns');
      });
    });

    target.findElement('#save-template').ev('click', async (e) => {
      const template = await getTemplate('Campaigns.templateSaveForm');
      const modal = $.render(template, {});

      modal.findElement('#store-template').ev('click', (e) => {
        e.preventDefault();

        const name = $('#campaigns-template-name').val();

        if (name) {
          const { definition } = this.formEditor.getDefinition();
          console.log('Def prev: ', definition);
          const fields = Object.entries(definition.fields)
            .map(([_fieldKey, field]) => field)
            .filter((field) => field.is_scenario)
            .sort((fieldA, fieldB) => {
              let aIndex = definition.order.indexOf(fieldA.id);
              let bIndex = definition.order.indexOf(fieldB.id);
              if (aIndex === -1) {
                aIndex = 9999;
              }
              if (bIndex === -1) {
                bIndex = 9999;
              }

              if (aIndex === bIndex) {
                return 0;
              }

              return (aIndex > bIndex) ? 1 : -1;
            })
            .map((field) => {
              delete field.id;
              return field;
            });

          if (fields.length > 0) {
            Api.post('/api/campaigns/templates', { name, fields })
              .then(() => {
                modal.modal('hide');
              });

            modal.on('hidden.bs.modal', (e) => {
              modal.remove();
            });
          }
        }
      });

      modal.modal({ focus: false });
    });

    target.findElement('#add-from-template').ev('click', (e) => {
      e.preventDefault();
      const templateID = $('#template-selector').val();

      if (templateID) {
        $.get(`/api/campaigns/templates/${templateID}`, {}, (template) => {
          template.template.forEach((field) => {
            field.new = true;
            const addedField = this.formEditor.constructField(field);

            this.formEditor.options.fields[addedField.id] = addedField;
            this.formEditor.options.order.push(addedField.id);
          });
          if (template.template.length > 0) {
            this.formEditor.render($('#campaigns-form-editor'));
          }
        });
      }
    });

    target.findElement('#template-selector').pickle({
      results(searchString, results, callback) {
        Api.get('/api/campaigns/templates', { search: searchString }).then((res) => {
          const options = res.map((r) => ({ id: r.ID, text: r.name }));

          callback(options, searchString.length);
        });
      },
    });

    target.findElement('select[name="dialtype"]').ev('change.campaigns', (e) => {
      const type = $(e.currentTarget).val();

      $(`#${type}-dial-extra`)
        .find('input')
        .each((_index, el) => {
          $(el).prop('disabled', false);
        });

      $(`div[id$='dial-extra']:not('#${type}-dial-extra')`)
        .find('input')
        .each((_index, el) => {
          $(el).prop('disabled', true);
        });
    });

    updateContactAssignmentFields(target);
    const assignmentSelectors = [
      'select[name=assigntype]',
      'input[name=assignment_mode_static]',
      'input[name=assignment_mode_dynamic]',
    ];
    target
      .findElement(assignmentSelectors.join(', '))
      .ev('change.campaigns', () => updateContactAssignmentFields(target));

    target.findElement('.phonenumber-button').ev('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const phoneNumber = $(e.currentTarget).data('number');
      this.manualCall(phoneNumber);
    });

    const saleForm = target.findElement('#campaigns-sale-form-container');
    if (saleForm.length) {
      calculatePrice(saleForm, true);
      saleForm
        .find('select[name="sale[productID]"],input[name="sale[amount]"]')
        .ev(
          'change.campaigns, input.campaigns',
          () => { calculatePrice(saleForm); }
        );
    }

    target
      .findElement('#sales-table [data-table-action="add"],#sales-table [data-row-action="edit"]')
      .ev('click.campaigns', async (e) => {
        e.preventDefault();
        createModal({
          modalLink: $(e.currentTarget).attr('href'),
          modalHeading: '.embedable .card-header',
          modalBody: '.embedable .card-body',
          modalFooter: '.embedable .card-footer',
          modalTarget: '#sales',
        });
      });

    target
      .findElement('#sales #sales-table [data-table-action="delete"]')
      .ev('click.campaigns', async (e) => {
        e.preventDefault();

        const selection = getTableSelection('sales');
        if (selection.length) {
          const deletes = selection.map(
            (saleID) => Api.delete(`/api/campaigns/sales/${saleID}`)
          );
          await Promise.all(deletes);
          const route = { ...window.Bootstrap.bootquery };
          window.targetElement = '#sales-table';
          renderController(
            'get',
            route.controller,
            route.method,
            route.parameters,
            route.moduleName,
          );
        }
      });

    target
      .findElement('#appointments-table [data-table-action="add"],#appointments-table [data-row-action="edit"]')
      .ev('click.campaigns', async (e) => {
        e.preventDefault();
        createModal({
          modalLink: $(e.currentTarget).attr('href'),
          modalHeading: '.embedable .card-header',
          modalBody: '.embedable .card-body',
          modalFooter: '.embedable .card-footer',
          modalTarget: '#appointments',
        });
      });

    target
      .findElement('#contactCampaigns-table [data-table-action="add"]')
      .ev('click.campaigns', async (e) => {
        e.preventDefault();
        createModal({
          modalLink: $(e.currentTarget).attr('href'),
          modalHeading: '.embedable .card-header',
          modalBody: '.embedable .card-body',
          modalFooter: '.embedable .card-footer',
          modalTarget: '#contactCampaigns',
        });
      });

    target.findElement('#reminders-table [data-table-action="add"]').ev('click', async (e) => {
      const campaignID = $(e.currentTarget).data('campaignid');
      const contactID = $(e.currentTarget).data('contactid');

      if (campaignID && contactID) {
        e.preventDefault();

        const template = await getTemplate('Campaigns.reminderAddForm');
        const modal = $.render(template, {});

        modal.findElement('#campaigns-add-reminder').ev('click', (e) => {
          e.preventDefault();

          const timestamp = $('#campaigns-reminder-timestamp').val();
          const body = $('#campaigns-reminder-body').val();

          if (!timestamp) {
            $('#campaigns-reminder-timestamp').addClass('is-invalid');
          }

          if (!body || body.length == 0) {
            $('#campaigns-reminder-body').addClass('is-invalid');
          }

          if (timestamp && body && body.length > 0) {
            getJSON(
              'post',
              'campaigns',
              'addReminder',
              {
                contactID,
                campaignID,
                timestamp,
                body,
              },
              true,
              (data) => {
                getTemplate('Campaigns.contactView').then((template) => {
                  const rendered = renderTemplate(template, data);
                  target.findElement('#reminders').html(
                    $(rendered)
                      .findElement('#reminders')
                      .html(),
                  );
                  activateElements('#reminders');
                });
                modal.modal('hide');
              },
            );

            modal.on('hidden.bs.modal', (e) => {
              modal.remove();
            });
          }
        });

        modal.modal({ focus: false });
        modal.on('shown.bs.modal', (e) => {
          activateElements('.modal');
        });
      }
    });

    const datasetEditorEl = target.findElement('.dataset-form-editor-container');
    if (datasetEditorEl.length) {
      const formDef = get(data, 'result.dataset.metadata.formDefinition', {});
      const datasetFormEditor = new FormEditor(formDef);
      datasetFormEditor.render(datasetEditorEl);

      target
        .findElement('form[name="dataset-edit"]')
        .ev('beforeSubmit.callcenter', (e) => {
          let input = $(e.currentTarget).find('[name="datasetFormDefinition"]');
          if (!input.length) {
            input = $('<input/>', {
              type: 'hidden',
              name: 'datasetFormDefinition',
            });
          }
          input.val(JSON.stringify(datasetFormEditor.getDefinition()));
          datasetEditorEl.append(input);
        });
    }

    target.findElement('.edit-basic-data-btn').ev('click.callcenter', (ev) => {
      ev.preventDefault();
      target.findElement('.campaign-basic-readonly').prop('hidden', true);
      target.findElement('.campaign-basic-editable').prop('hidden', false);
      target.findElement('.campaign-basic-editable').find('input, select').prop('readonly', false);
    });
    target.findElement('.cancel-edit-basic-data-btn').ev('click.callcenter', (ev) => {
      ev.preventDefault();
      target.findElement('.campaign-basic-readonly').prop('hidden', false);
      target.findElement('.campaign-basic-editable').prop('hidden', true);
      target.findElement('.campaign-basic-editable').find('input, select').prop('readonly', true);
    });

    target.findElement('.campaign-basic-form-editable .campaign-basic-readonly').prop('hidden', true);
    target.findElement('.campaign-basic-form-editable .campaign-basic-editable').prop('hidden', false);
    target.findElement('.campaign-basic-form-editable .campaign-basic-editable').find('input, select').prop('readonly', false);
    target
      .findElement('.campaigns-change-contact-agent-button')
      .ev('click.campaigns', (e) => {
        e.preventDefault();
        createModal({
          modalLink: $(e.currentTarget).attr('href'),
          modalHeading: '.embedable .card-header',
          modalBody: '.embedable .card-body',
          modalFooter: '.embedable .card-footer',
          modalTarget: '.current-agent-switch',
        });
      });

    target
      .findElement('input[type=checkbox][name=callAgain]')
      .ev('change.campaigns', (e) => {
        const checked = $(e.currentTarget).prop('checked');
        const $callAgainAt = $('input[name=callAgainAt]');
        if ($callAgainAt.length && !$callAgainAt.val()) {
          this.autoSetCallAgainAt();
        }
        $('#callAgainAtContainer').prop('hidden', !checked);
      });

    target.findElement('#import-form').ev('beforeSubmit.campaigns', async (ev) => {
      const importUUID = uuid();
      const $form = $(ev.currentTarget);
      $form.append($('<input/>', {
        type: 'hidden',
        name: 'importUUID',
        value: importUUID,
      }));

      const $container = $('#wizard-container');
      $container.addClass('clickvox-loading-target');

      const $progress = $.render(
        await getTemplate('Campaigns.datasetImportProgressOverlay'),
        { importUUID, rows: 0 }
      );
      $container.append($progress);
    });

    target.findElement('#campaigns-appointment-form-container input[type=checkbox][name="callBack"]')
      .ev('change.campaigns', (ev) => {
        const $checkbox = $(ev.currentTarget);
        const callBack = $checkbox.prop('checked');

        const $form = $('#campaigns-appointment-form-container');
        const $reminderCheckbox = $form.find('input[type=checkbox][name="appointmentReminder"]');
        const $remindBefore = $form.find('input[name="appointmentRemindBefore"]');

        if (callBack) {
          $reminderCheckbox.prop('checked', true);
          $reminderCheckbox.prop('disabled', true);
          $remindBefore.val('0');
          $remindBefore.prop('disabled', true);
        } else {
          $reminderCheckbox.prop('disabled', false);
          $remindBefore.prop('disabled', false);
        }
      });

    const $eventsTarget = target.findElement('#campaign-contact-events > .events-container');
    if ($eventsTarget.length) {
      const { contactID, campaignID } = data.result;
      this.renderContactEvents($eventsTarget[0], campaignID, contactID);
    }

    // Scroll to bottom of events tab when selected
    // Create a short fade-in animation so the user doesn't see the ugly content jump
    const $eventsTab = target.findElement('.nav-link[href="#campaign-contact-events"]');
    $eventsTab
      .ev('show.bs.tab', () => {
        const eventsEl = document.querySelector('#campaign-contact-events');
        eventsEl.style.opacity = 0.0;
        eventsEl.style.transition = 'opacity 0.3s';
      })
      .ev('shown.bs.tab', () => {
        const eventsEl = document.querySelector('#campaign-contact-events');
        this.scrollToBottomOfEvents(eventsEl);
        eventsEl.style.opacity = 1.0;
      });

    target.findElement('.contact-email-button').ev('click.campaigns', (ev) => {
      ev.preventDefault();

      const $btn = $(ev.currentTarget);
      const href = $btn.attr('href');
      const [, mail] = href.match(/mailto:(.+)/);
      this.newMail({ to: [mail] });
    });
    target.findElement('.phonenumber-sms-button').ev('click.campaigns', (ev) => {
      ev.preventDefault();

      const $btn = $(ev.currentTarget);
      const href = $btn.attr('href');
      const [, number] = href.match(/sms:(.+)/);
      this.newSms({ to: number });
    });

    target.findElement('[data-add-campaign-sale-form]').ev('click.campaigns', (e) => {
      e.preventDefault();
      this.editSaleForm({
        isNew: true,
      }, async (saved) => {
        const saleFormID = `new${uuid()}`;
        saved.ID = saleFormID;
        saved.isNew = true;

        const saleFormDataName = `campaigns[saleForms][${saleFormID}]`;
        const itemTemplate = await getTemplate('Campaigns.saleFormListItem');
        const item = $.render(itemTemplate, saved);
        const dataInput = $('<input/>', {
          type: 'hidden',
          name: saleFormDataName,
          value: JSON.stringify(saved),
        });
        item.append(dataInput);
        target.findElement('.sales-form-list').append(item);
        this.activateElements(target);
      });
    });

    target.findElement('[data-edit-campaign-sale-form]').ev('click.campaigns', (e) => {
      e.preventDefault();
      const btn = $(e.currentTarget);
      const saleFormListItem = btn.closest('.list-group-item');
      const saleFormID = btn.data('saleFormId');
      const saleFormDataName = `campaigns[saleForms][${saleFormID}]`;
      let saleFormData = null;
      let saleFormInfo = saleFormListItem.findElement(
        `input[type="hidden"][name="${saleFormDataName}"]`,
      );
      if (saleFormInfo.length) {
        saleFormData = JSON.parse(saleFormInfo.val());
      } else {
        const saleFormIDInt = parseInt(saleFormID, 10);
        const { saleForms } = findCampaignsTab(window.Bootstrap).data;
        const saleForm = saleForms.find(({ ID }) => ID === saleFormIDInt);
        saleFormData = cloneDeep(saleForm);
      }

      this.editSaleForm(saleFormData, (saved) => {
        if (!saleFormInfo.length) {
          saleFormInfo = $('<input/>', {
            type: 'hidden',
            name: saleFormDataName,
          });
          saleFormInfo.appendTo(saleFormListItem);
        }
        saleFormInfo.val(JSON.stringify(saved));
      });
    });
  }

  smoothScrollToLastEvent() {
    setTimeout(() => {
      const el = document.querySelector('#campaign-contact-events > .event-list > .card:last-child');
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }, 0);
  }

  scrollToBottomOfEvents(eventsEl) {
    const scrollyPart = eventsEl.closest('.tab-content');
    setTimeout(() => {
      scrollyPart.scrollTop = scrollyPart.scrollHeight;
    }, 0);
  }

  renderContactEvents(target, campaignID, contactID) {
    this.contactEventsVue = null;
    this.contactEventsState.campaignID = campaignID;
    this.contactEventsState.contactID = contactID;
    this.fetchContactEvents(campaignID, contactID);
    setTimeout(() => {
      this.contactEventsVue = new Vue({
        render: (h) => h(ContactEvents),
      });
      this.contactEventsVue.$mount(target);
    }, 0);
  }

  async fetchContactEvents(campaignID, contactID) {
    const { data: events, count } = await Api.get(`/api/campaigns/${campaignID}/contacts/${contactID}/events`);
    this.contactEventsState.events = events;
    this.contactEventsState.count = count;
  }

  manualCall(phoneNumber) {
    const campaignID = this.campaign;
    const agentID = window.Bootstrap.bootquery.session.userID;
    const { extension } = window.Bootstrap.bootquery.session;
    const campaignDataID = window.Bootstrap.result.contactID;

    $.post(
      '/campaigns/manualCall',
      {
        campaignID,
        agentID,
        phoneNumber,
        extension,
        campaignDataID,
      },
      (data) => {
        // console.log('Did a thing!', data);
      },
    );
  }

  async newMail(params = {}) {
    const $eventsTab = $('.nav-link[href="#campaign-contact-events"]');
    $eventsTab.tab('show');

    const editorID = uuid();
    const mails = window.BootQuery.getModuleInstance('Mails');
    const staticParams = {
      availableAccounts: [],
      signatureTemplateData: {},
    };
    await Promise.all([
      mails.getAvailableAccounts().then((accounts) => {
        staticParams.availableAccounts = accounts;
      }),
      mails.getSignatureTemplateData().then((sigData) => {
        staticParams.signatureTemplateData = sigData;
      }),
    ]);

    const editorData = {
      ...staticParams,
      ...params,
    };
    this.contactEventsState.events.push({
      type: 'mailEditor',
      id: editorID,
      data: {
        editorID,
        editorData,
      },
    });

    this.smoothScrollToLastEvent();
  }

  async replyMail(type, prevMessage) {
    const mails = window.BootQuery.getModuleInstance('Mails');
    const accounts = await mails.getAvailableAccounts();

    let account = accounts.find((acc) => acc.ID === prevMessage.mailbox.accountID);
    if (!account && accounts.length) {
      [account] = accounts;
    }
    const addresses = {
      to: [],
      cc: [],
      bcc: [],
    };

    const addressIsAccount = (address) => address === account.email
		|| account.aliases.includes(address);

    const replyingToSelf = addressIsAccount(prevMessage.fromMailAddress);
    const replyTo = get(
      prevMessage.headers,
      ['reply-to', 'value', 0, 'address']
    );
    if (type === 'all') {
      if (!replyingToSelf) {
        if (replyTo) {
          addresses.to.push(replyTo);
        } else {
          addresses.to.push(prevMessage.fromMailAddress);
        }
      }
      Object.keys(addresses).forEach((addressType) => {
        prevMessage[addressType].forEach((address) => {
          if (!replyingToSelf && addressIsAccount(address.address)) {
            return;
          }
          addresses[addressType].push(address.address);
        });
      });
    } else if (replyingToSelf) {
      prevMessage.to.forEach((address) => addresses.to.push(address.address));
    } else if (replyTo) {
      addresses.to.push(replyTo);
    } else {
      addresses.to.push(prevMessage.fromMailAddress);
    }

    let { subject } = prevMessage;

    if (!subject.toUpperCase().startsWith('RE:')) {
      subject = `Re: ${subject}`;
    }

    this.newMail({
      subject,
      ...addresses,
      accountID: account.ID,
      fixedAccount: false,
      inReplyToMessageID: prevMessage.split ? null : prevMessage.ID,
      quotedHtml: prevMessage.htmlBody,
      quotedText: prevMessage.textBody,
    });
  }

  forwardMail(prevMessage) {
    let {
      subject,
    } = prevMessage;
    if (!subject.toUpperCase().startsWith('FWD:')) {
      subject = `Fwd: ${subject}`;
    }

    this.newMail({
      subject,
      forwardMessageID: prevMessage.ID,
      quotedHtml: prevMessage.htmlBody,
      quotedText: prevMessage.textBody,
    });
  }

  async submitMailEditor(editorID, submitted) {
    const { campaignID, contactID } = window.Bootstrap.result;

    const editorIdx = this.contactEventsState.events.findIndex(
      ({ type, data }) => type === 'mailEditor' && data.editorID === editorID
    );
    if (editorIdx === -1) {
      throw new Error(`Editor with ID ${editorID} not found`);
    }
    const editor = this.contactEventsState.events[editorIdx];
    const { editorData: extraData } = editor.data;
    const { sentID } = await Api.post('/api/mails/sendMessage', {
      accountID: submitted.accountID,
      fromDisplayName: submitted.accountInfo.formattedName,
      subject: submitted.subject,
      to: submitted.to,
      cc: submitted.cc,
      bcc: submitted.bcc,
      content: submitted.content,
      text: submitted.textContent,
      html: submitted.htmlContent,
      attachments: submitted.attachments,
      priority: submitted.priority,
      inReplyToMessageID: extraData.inReplyToMessageID,
      forwardMessageID: extraData.forwardMessageID,
      quotePrevious: submitted.quotePrevious,
    });

    if (!extraData.inReplyToMessageID) {
      await Api.post(`/api/campaigns/${campaignID}/contacts/${contactID}/events/`, {
        type: 'email',
        mailID: sentID,
      });
    }
    const event = await Api.get(`/api/campaigns/${campaignID}/contacts/${contactID}/events/mail_${sentID}`);
    this.contactEventsState.$set(this.contactEventsState.events, editorIdx, event);
    this.contactEventsState.count++;
  }

  destroyMailEditor(editorID) {
    const editorIdx = this.contactEventsState.events.findIndex(
      ({ type, data }) => type === 'mailEditor' && data.editorID === editorID
    );
    if (editorIdx === -1) {
      console.warn(`Tried to destroy editor ${editorID} but it ran away`);
      return;
    }

    this.contactEventsState.events.splice(editorIdx, 1);
  }

  async newSms(params = {}) {
    const $eventsTab = $('.nav-link[href="#campaign-contact-events"]');
    $eventsTab.tab('show');

    const editorID = uuid();
    const sms = window.BootQuery.getModuleInstance('Sms');
    const availableAccounts = await sms.getAccounts();

    this.contactEventsState.events.push({
      type: 'smsEditor',
      id: editorID,
      data: {
        editorID,
        availableAccounts,
        ...params,
      },
    });

    this.smoothScrollToLastEvent();
  }

  async replySms(prevSms) {
    const to = prevSms.remoteParty.phoneNumber.phoneNumberE164;
    const accountPhoneNumber = prevSms.account.phoneNumber.phoneNumberE164;

    this.newSms({ to, accountPhoneNumber });
  }

  async submitSmsEditor(editorID, submitted) {
    const { campaignID, contactID } = window.Bootstrap.result;

    const editorIdx = this.contactEventsState.events.findIndex(
      ({ type, data }) => type === 'smsEditor' && data.editorID === editorID
    );
    if (editorIdx === -1) {
      throw new Error(`Editor with ID ${editorID} not found`);
    }

    const { accountPhoneNumber } = submitted;
    delete submitted.accountPhoneNumber;
    const { ID: sentID } = await Api.post(`/api/sms/accounts/${accountPhoneNumber}/messages/send`, submitted);

    const event = await Api.get(`/api/campaigns/${campaignID}/contacts/${contactID}/events/sms_${sentID}`);
    this.contactEventsState.$set(this.contactEventsState.events, editorIdx, event);
    this.contactEventsState.count++;
  }

  destroySmsEditor(editorID) {
    const editorIdx = this.contactEventsState.events.findIndex(
      ({ type, data }) => type === 'smsEditor' && data.editorID === editorID
    );
    if (editorIdx === -1) {
      console.warn(`Tried to destroy editor ${editorID} but it ran away`);
      return;
    }

    this.contactEventsState.events.splice(editorIdx, 1);
  }

  activateFormEditor(target, data) {
    const editorEl = target.findElement('#campaigns-form-editor');
    if (!editorEl.length) {
      return;
    }

    const formDef = get(data, 'bootquery.form');
    const fieldFactory = new FieldFactory({
      field: CampaignField,
      list: CampaignList,
      select: CampaignSelect,
    });
    this.formEditor = new CampaignFormEditor(formDef, fieldFactory);
    this.formEditor.render(editorEl);
  }

  async editSaleForm(data, onSave) {
    const campaignSettings = findCampaignsTab(window.Bootstrap).data;

    data.saleForms = cloneDeep(campaignSettings.saleForms);
    const template = await getTemplate('Campaigns.saleFormEditModal');
    const settingsModal = $.render(template, data);
    const formEditor = new FormEditor(data.formDefinition);
    formEditor.render(settingsModal.find('#sale-form-editor'));

    settingsModal.findElement('.save-btn').ev('click', (e) => {
      e.preventDefault();
      const formData = getFormData(settingsModal);

      const saved = {
        name: formData.name,
        formDefinition: formEditor.getDefinition().definition,
      };
      settingsModal.modal('hide');
      onSave(saved);
    });
    settingsModal.on('hidden.bs.modal', () => {
      settingsModal.modal('dispose');
      settingsModal.remove();
    });
    settingsModal.modal('show');
    activateElements(settingsModal);
  }

  handleCampaignCreation(target, data) {
    const formDefinition = this.formEditor.getDefinition();
    const submitData = {
      form: formDefinition,
      dataset: data.dataset,
      name: data.name,
    };

    // Submit to the wizard backend
    getJSON('post', 'campaigns', 'createCampaign', submitData, true, () => {});
  }

  async onNextContact(data) {
    console.log(`Switching to next contact in ${data.timeout}`);
  }

  async onContactChange(data) {
    if (!data.contactID) {
      return;
    }

    const $form = $('#campaigns-contact-form');
    const formData = getFormData($form);

    getJSON('post', 'campaigns', 'contact', formData, true, () => {
      renderController('get', 'campaigns', 'contact', { id: data.contactID }, 'Campaigns');
    });
  }

  autoSetCallAgainAt() {
    const callDate = moment();
    const { callAgainAfter } = window.Bootstrap.result;
    if (callAgainAfter) {
      const duration = moment.duration(callAgainAfter);
      callDate.add(duration);
    }

    const $callAgainAt = $('input[name=callAgainAt]');
    if ($callAgainAt.length) {
      // eslint-disable-next-line no-underscore-dangle
      $callAgainAt[0]._flatpickr.setDate(callDate.toISOString());
    }
  }

  onContactCalled(data) {
    const { contactID } = data;
    const currentContactID = get(window, 'Bootstrap.result.contactID');
    if (!contactID || contactID !== currentContactID) {
      return;
    }
    $('#campaign-make-call').prop('hidden', true);
    $('#campaign-skip-contact').prop('hidden', true);
    $('#campaign-next-contact').prop('hidden', false);
    $('#callAgain').prop('checked', false);
  }

  onPeerStatusChanged(data) {
    if (data.Extension !== this.extension) {
      return;
    }

    if (data.Status !== 'Idle') {
      $('#campaign-next-call').prop('disabled', true);
      $('#campaign-make-call').prop('disabled', true);
      $('#campaign-next-contact').prop('disabled', true);
    } else {
      $('#campaign-next-call').prop('disabled', false);
      $('#campaign-make-call').prop('disabled', false);
      $('#campaign-next-contact').prop('disabled', false);
    }
    this.extensionState = data;
  }

  onCallEnd(call) {
    const connectingCall = call.ConnectingCalls ? call.ConnectingCalls[0] : null;
    if (!connectingCall) {
      return;
    }
    if (!window.Bootstrap.result || !window.Bootstrap.result.autoScheduleCallAgain) {
      return;
    }

    const isOurCall = call.CallerIDNum !== this.extension;
    const { phoneNumberID } = call.ContactInfo;
    const isCurrentContact = window.Bootstrap.result.contactNumberIDs.includes(phoneNumberID);
    if (isOurCall && isCurrentContact && !call.Answered) {
      this.autoSetCallAgainAt();
      $('[name=callAgain]').prop('checked', true);
      $('[name=callAgain]').trigger('change');
    }
  }

  onImportProgress(data) {
    const { importUUID, importedRows } = data;
    const uuidSelector = `data-import-uuid="${importUUID}"`;
    const $progressEl = $(`.dataset-import-progress-overlay[${uuidSelector}] .import-progress-counter`);
    $progressEl.text(importedRows);
  }
}
