import { ReactElement } from 'react';
import { FormControl, FormLabel, Checkbox } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FieldValue } from '../../types';

export const CheckboxField = ({ id, settings }: FieldValue): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const ourError = errors[id]?.type;

  return (
    <FormControl isInvalid={!!ourError}>
      <FormLabel fontWeight="bold">{settings.name}:</FormLabel>
      <Checkbox {...register(id, { required: settings.required })} />
    </FormControl>
  );
};
