module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span title=\""
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.contact_called",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":46}}}))
    + "\" data-toggle=\"tooltip\" class=\"fas fa-phone text-primary\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"skipped") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":8,"column":0}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span title=\""
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.contact_skipped",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":17},"end":{"line":5,"column":47}}}))
    + "\" data-toggle=\"tooltip\" class=\"fas fa-fast-forward text-warning\"></span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span title=\""
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.contact_unprocessed",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":17},"end":{"line":7,"column":51}}}))
    + "\" data-toggle=\"tooltip\" class=\"fas fa-circle text-muted\"></span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"fas fa-clock "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"callAgainTimePassed") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":10,"column":30},"end":{"line":10,"column":94}}})) != null ? stack1 : "")
    + "\" data-toggle=\"tooltip\" title=\""
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.call_again",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":10,"column":125},"end":{"line":10,"column":150}}}))
    + " - "
    + alias2(lookupProperty(helpers,"format_date").call(alias1,(depth0 != null ? lookupProperty(depth0,"callAgainAt") : depth0),lookupProperty(helpers,"tr").call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":10,"column":179},"end":{"line":10,"column":201}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":10,"column":153},"end":{"line":10,"column":203}}}))
    + "\"></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "text-success";
},"11":function(container,depth0,helpers,partials,data) {
    return "text-muted";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&nbsp;\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"called") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"callAgainAt") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "");
},"useData":true})