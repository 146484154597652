module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"card mb-3 border-danger\">\n        <div class=\"card-body\">\n            <div class=\"row\">\n                <div class=\"col\">\n                    <h4>\n                        <span class=\"text-danger fas fa-clock\"></span>\n                        Registar Ne zovi nije ažuriran u zadnja 24 sata.\n                    </h4>\n                    <small class=\"text-danger\">\n                        Odlazni pozivi onemogućeni. Molim pokušajte ručno sinkronizirati registar, ili kontaktirajte podršku.\n                    </small>\n                </div>\n                <div class=\"col-auto d-flex align-items-center\">\n                    <a href=\"/doNotCall/list/\">\n                        Status registra\n                        <span class=\"fas fa-arrow-right\"></span>\n                    </a>\n                </div>\n            </div>\n        </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"doNotCallSynced")),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":23,"column":11}}})) != null ? stack1 : "");
},"useData":true})