module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":106,"column":12}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"add-to-campaign-form\" class=\"form-horizontal\" action=\"/campaigns/addToCampaign\" method=\"post\" role=\"form\">\n	<div class=\"card embedable\">\n\n		<div class=\"card-header stick-top\">\n			<span class=\"fa fa-magic\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.add_to_campaign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":73}}}))
    + "\n		</div>\n\n		<div class=\"card-body\">\n			<input type=\"hidden\" name=\"action\" value=\"add\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"campaign_parameters")),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":3},"end":{"line":14,"column":12}}})) != null ? stack1 : "")
    + "			<div class=\"form-group row\">\n                <div class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\">\n                    <label for=\"campaignID\">Kampanja:</label>\n                </div>\n                <div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n                    <select name=\"campaignID\" id=\"campaign-select\" data-dataset-id=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"campaign_parameters"))) && lookupProperty(stack1,"dataset")), depth0))
    + "\" required></select>\n                </div>\n            </div>\n			<div class=\"form-group row\">\n				<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"assigntype\">\n					"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assign_method",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":25,"column":5},"end":{"line":25,"column":32}}}))
    + ":\n				</label>\n				<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n					<select class=\"form-control pickle\" name=\"assigntype\" autocomplete=\"off\">\n						<option value=\"dynamic\" selected>\n							"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.dynamic_assign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":30,"column":7},"end":{"line":30,"column":35}}}))
    + "\n						</option>\n						<option value=\"static\">\n							"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.static_assign",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":33,"column":7},"end":{"line":33,"column":34}}}))
    + "\n						</option>\n					</select>\n				</div>\n			</div>\n			<div class=\"form-group row\" id=\"assignment-mode-dynamic\">\n				<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"assignment-mode\">\n					<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":40,"column":30},"end":{"line":40,"column":59}}}))
    + "</span><span class=\"label-after\">: </span>\n				</label>\n				<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n					<div class=\"custom-control custom-radio\">\n						<input type=\"radio\" id=\"assignment-mode-dynamic-auto\" name=\"assignment_mode_dynamic\" class=\"custom-control-input\" value=\"auto\" checked>\n						<label class=\"custom-control-label\" for=\"assignment-mode-dynamic-auto\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode_auto",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":45,"column":77},"end":{"line":45,"column":111}}}))
    + "</label>\n					</div>\n					<div class=\"custom-control custom-radio\">\n						<input type=\"radio\" id=\"assignment-mode-dynamic-limited\" name=\"assignment_mode_dynamic\" class=\"custom-control-input\" value=\"limited\">\n						<label class=\"custom-control-label\" for=\"assignment-mode-dynamic-limited\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode_limited",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":49,"column":80},"end":{"line":49,"column":117}}}))
    + "</label>\n						<input type=\"number\" name=\"assign_dynamic_amount\" min=\"1\" max=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" class=\"form-control col-4\" autocomplete=\"off\" placeholder=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\">\n						<label class=\"form-label\" for=\"assign_dynamic_amount\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.from_total",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":51,"column":60},"end":{"line":51,"column":84}}}))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "</label>\n					</div>\n				</div>\n			</div>\n\n			<div class=\"form-group row\" id=\"assignment-mode-static\" hidden>\n				<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"assignment-mode\">\n					<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":58,"column":30},"end":{"line":58,"column":59}}}))
    + "</span><span class=\"label-after\">: </span>\n				</label>\n				<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n					<div class=\"custom-control custom-radio\">\n						<input type=\"radio\" id=\"assignment-mode-static-spread\" name=\"assignment_mode_static\" class=\"custom-control-input\" value=\"spread\" checked>\n						<label class=\"custom-control-label\" for=\"assignment-mode-static-spread\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode_static_spread",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":63,"column":78},"end":{"line":63,"column":121}}}))
    + ": </label>\n						<input type=\"number\" name=\"assign_static_amount\" min=\"1\" max=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" class=\"form-control col-4\" autocomplete=\"off\" placeholder=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\">\n						<label class=\"form-label\" for=\"assign_static_amount\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.from_total",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":65,"column":59},"end":{"line":65,"column":83}}}))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "</label>\n					</div>\n					<div class=\"custom-control custom-radio\">\n						<input type=\"radio\" id=\"assignment-mode-static-per-agent\" name=\"assignment_mode_static\" class=\"custom-control-input\" value=\"per_agent\">\n						<label class=\"custom-control-label\" for=\"assignment-mode-static-per-agent\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assignment_mode_static_per_agent",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":69,"column":81},"end":{"line":69,"column":127}}}))
    + ": </label>\n						<input type=\"number\" name=\"assign_static_per_agent_amount\" min=\"0\" max=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"parameters"))) && lookupProperty(stack1,"dataset_count")), depth0))
    + "\" value=\"100\" class=\"form-control col-4\" autocomplete=\"off\" disabled>\n					</div>\n				</div>\n			</div>\n			<div class=\"form-group row\">\n				<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"agents\">\n					<span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.assign_to_agents",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":76,"column":30},"end":{"line":76,"column":60}}}))
    + "</span><span class=\"label-after\">: </span>\n				</label>\n				<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n					<div class=\"card subform-card\">\n						<div class=\"card-body\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"agents") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":7},"end":{"line":86,"column":16}}})) != null ? stack1 : "")
    + "						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n\n		<div class=\"card-footer stick-bottom d-flex\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":3},"end":{"line":98,"column":10}}})) != null ? stack1 : "")
    + "\n			<button type=\"submit\" class=\"btn btn-success ml-auto\" form=\"add-to-campaign-form\">\n				<span class=\"fas fa-check\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":101,"column":44},"end":{"line":101,"column":65}}}))
    + "\n			</button>\n		</div>\n	</div>\n</form>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<input type=\"hidden\" name=\""
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<div class=\"custom-control custom-checkbox\">\n								<input type=\"checkbox\" class=\"custom-control-input\" id=\"agents-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\" value=\"true\" name=\"agents["
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "]\">\n								<label class=\"custom-control-label\" for=\"agents-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "</label>\n							</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<a href=\"/campaigns/index\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n					<span class=\"fa fa-times\"></span>&nbsp;"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":96,"column":44},"end":{"line":96,"column":67}}}))
    + "\n				</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":107,"column":11}}})) != null ? stack1 : "");
},"useData":true})