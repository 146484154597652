module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <thead>\n        <tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"settings") : depth0)) != null ? lookupProperty(stack1,"columns") : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":30,"column":17}}})) != null ? stack1 : "")
    + "        </tr>\n    </thead>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <th style=\"min-width: 300px;\" data-column-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n                <div class=\"form-group row mb-0\">\n                    <label for=\"settings[columns]["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][label]\" class=\"col-sm-4 col-form-label\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.name",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":8,"column":102},"end":{"line":8,"column":120}}}))
    + "</label>\n                    <div class=\"col-sm-8\">\n                        <input type=\"text\" class=\"form-control form-control-sm label-input\" name=\"settings[columns]["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][label]\" id=\"settings[columns]["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][label]\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":10,"column":185},"end":{"line":10,"column":230}}})) != null ? stack1 : "")
    + "\">\n                    </div>\n                </div>\n\n                <div class=\"form-group row mb-0\">\n                    <label for=\"settings[columns]["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][type]\" class=\"col-sm-4 col-form-label\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.type",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":15,"column":101},"end":{"line":15,"column":119}}}))
    + "</label>\n                    <div class=\"col-sm-8\">\n                        <select class=\"form-control form-control-sm mt-2 type-selector custom-select\" name=\"settings[columns]["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][type]\" id=\"settings[columns]["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][type]\" data-width=\"100%\" data-ismandatory=\"true\">\n                            <option value=\"text\"     "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":53},"end":{"line":18,"column":91}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.text",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":18,"column":92},"end":{"line":18,"column":110}}}))
    + "</option>\n                            <option value=\"number\"   "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"number") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":53},"end":{"line":19,"column":93}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.number",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":19,"column":94},"end":{"line":19,"column":114}}}))
    + "</option>\n                            <option value=\"phone\"    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"phone") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":53},"end":{"line":20,"column":92}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.phone_number",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":20,"column":93},"end":{"line":20,"column":119}}}))
    + "</option>\n                            <option value=\"email\"    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":53},"end":{"line":21,"column":92}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.email",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":21,"column":93},"end":{"line":21,"column":112}}}))
    + "</option>\n                            <option value=\"date\"     "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"date") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":53},"end":{"line":22,"column":91}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.date",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":22,"column":92},"end":{"line":22,"column":110}}}))
    + "</option>\n                            <option value=\"time\"     "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"time") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":53},"end":{"line":23,"column":91}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.time",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":23,"column":92},"end":{"line":23,"column":110}}}))
    + "</option>\n                            <option value=\"datetime\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"datetime") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":53},"end":{"line":24,"column":95}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.datetime",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":24,"column":96},"end":{"line":24,"column":118}}}))
    + "</option>\n                            <option value=\"bool\"     "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"bool") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":53},"end":{"line":25,"column":91}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.bool",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":25,"column":92},"end":{"line":25,"column":110}}}))
    + "</option>\n                        </select>\n                    </div>\n                </div>\n            </th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"settings"))) && lookupProperty(stack1,"header")),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":85,"column":15}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"first")),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":61,"column":23}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":59,"column":25}}})) != null ? stack1 : "")
    + "                </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <td data-column-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"values") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":56,"column":31}}})) != null ? stack1 : "")
    + "                        <span class=\"fa fa-eye float-right text-muted\" data-toggle=\"tooltip\" data-placement=\"left\" title=\""
    + alias2(lookupProperty(helpers,"tr").call(alias3,"import.original_data",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":57,"column":122},"end":{"line":57,"column":151}}}))
    + ": "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formatted_value") : depth0), depth0))
    + "\"></span>\n                    </td>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"values") : depth0)) != null ? lookupProperty(stack1,"error") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":42,"column":28},"end":{"line":53,"column":35}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"text-red\">"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"import.invalid_data",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":43,"column":55},"end":{"line":43,"column":83}}}))
    + "</span><br>\n                                <span>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"values") : depth0)) != null ? lookupProperty(stack1,"error") : stack1), depth0))
    + "</span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(lookupProperty(helpers,"log").call(alias1,depth0,{"name":"log","hash":{},"data":data,"loc":{"start":{"line":46,"column":32},"end":{"line":46,"column":44}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"values") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":52,"column":41}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":36},"end":{"line":51,"column":47}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "                                        <br>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"renderValue"),depth0,{"name":"renderValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":83,"column":21}}})) != null ? stack1 : "")
    + "            </tr>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td data-column-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"values") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":66,"column":20},"end":{"line":80,"column":27}}})) != null ? stack1 : "")
    + "                    <span class=\"fa fa-eye float-right text-muted\" data-toggle=\"tooltip\" data-placement=\"left\" title=\""
    + alias2(lookupProperty(helpers,"tr").call(alias3,"import.original_data",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":81,"column":118},"end":{"line":81,"column":147}}}))
    + ": "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formatted_value") : depth0), depth0))
    + "\"></span>\n                </td>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"values") : depth0)) != null ? lookupProperty(stack1,"error") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":77,"column":31}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"text-red\">"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"import.invalid_data",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":68,"column":51},"end":{"line":68,"column":79}}}))
    + "</span><br>\n                            <span>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"values") : depth0)) != null ? lookupProperty(stack1,"error") : stack1), depth0))
    + "</span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"values") : depth0),{"name":"each","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":28},"end":{"line":76,"column":37}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":32},"end":{"line":75,"column":43}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    return "                                    <br>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"renderValue"),depth0,{"name":"renderValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-striped table-bordered\" id=\"preview-table\">\n"
    + ((stack1 = lookupProperty(helpers,"block").call(alias1,"previewTableHeader",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":33,"column":10}}})) != null ? stack1 : "")
    + "    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"preview") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":86,"column":13}}})) != null ? stack1 : "")
    + "    </tbody>\n</table>";
},"usePartial":true,"useData":true})