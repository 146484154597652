import { ReactElement } from 'react';
import { useEventTypes } from '../event-types';
import { Event } from '../types';

type Props = Event;

export const EventCard = (event: Props): ReactElement => {
  const eventType = useEventTypes()[event.type];
  if (!eventType) {
    throw new Error(`Unknown event type ${event.type}`);
  }

  return <eventType.components.Card {...event} />;
};
